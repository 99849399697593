import React, {useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import {MdOutlineInfo} from "react-icons/md";

const LegendDropdownIcon = () => {

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

    function handleDropDownState () {
        setDropdownIsOpen(!dropdownIsOpen)
        const elem = document.activeElement;
        if (elem) {
            elem?.blur();
        }
    }

    return (
        <div className={`z-40 dropdown dropdown-end ${ dropdownIsOpen ? 'dropdown-open' : null}`}>
            <div className={"flex flex-row items-center space-x-1"}>
                <div
                    tabIndex={0}
                    role={"button"}
                    className="btn btn-circle btn-xs btn-ghost text-blue border-none"
                    onClick={(event) => handleDropDownState(event)}
                >
                    <MdOutlineInfo className={"w-5 h-5"}/>
                </div>
            </div>
            <div
                tabIndex={0}
                className="card compact dropdown-content z-[1] shadow bg-base-100 rounded-box p-3 min-w-48"
            >
                <div className={"flex flex-col text-primary text-sm"}>
                    <div className={"flex flex-row items-center space-x-2"}>
                        <div className="h-2 w-2 rounded-full bg-orange pr-2"></div>
                        <span>All answers</span>
                    </div>
                    <div className={"flex flex-row items-center space-x-2"}>
                        <div className="h-2 w-2 rounded-full bg-success pr-2"></div>
                        <span>Correct answers</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

LegendDropdownIcon.propTypes = {
    title: PropTypes.string,
    reasons: PropTypes.object,
    canPaid: PropTypes.bool
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LegendDropdownIcon)