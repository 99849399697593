import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {IoMdClose} from "react-icons/io";
import {IconContext} from "react-icons/lib";
import {Parser} from "html-to-react";
// eslint-disable-next-line no-unused-vars
import {HTMLToJSON} from "html-to-json-parser";
// eslint-disable-next-line no-unused-vars
import Handlebars from "handlebars";
// eslint-disable-next-line no-unused-vars
import UAParser from "ua-parser-js";

// eslint-disable-next-line no-unused-vars
import gif from "../../../assets/test.gif"
// eslint-disable-next-line no-unused-vars
import install from "../../../assets/install.gif"
// eslint-disable-next-line no-unused-vars
import enable from "../../../assets/enable.gif"

import {
    HIDE_STORIES,
} from "../../../redux/actions/stories-actions";

const Story = ({
                   content,
                   // eslint-disable-next-line no-unused-vars
                   userInfo,
                   hideStories,
                   settings,
                   // eslint-disable-next-line no-unused-vars
                   currentSprint,
                   // eslint-disable-next-line no-unused-vars
                   nearestEnrollmentSprintDate,
                   height,
}) => {

    // eslint-disable-next-line no-unused-vars
    const htmlParser = new Parser();
    const [storyClass, setStoryClass] = useState('bg-mint')
    const [storyStyle, setStoryStyle] = useState({})
    // eslint-disable-next-line no-unused-vars
    const contentRef = useRef()
    const headerRef = useRef()
    const [contentHeight, setContentHeight] = useState(0)

    const handleHideStories = () => {
        hideStories()
    }

    useEffect(()=>{

        if(settings) {
            const keys = Object.keys(settings)
            keys.forEach(key=> {
                if(key==='class') {
                    setStoryClass(settings[key])
                } else if (key==='style') {
                    setStoryStyle(settings[key])
                }
            })
        }
    },[settings])

    useEffect( () => {
        async function convertToJson(element){
            return await HTMLToJSON(element, true);
        }

        if (contentRef.current && process.env.NODE_ENV === 'development') {
            const element = contentRef.current.innerHTML
            if(element){
                let result = convertToJson(element)
                result.then(result => console.log(result))
            }
        }
    }, [contentRef])

    useEffect(()=> {

        if(content && userInfo){
            const template = Handlebars.compile(`${content}`)

            const parser = new UAParser()

            const templateVariables = {
                nearestEnrollmentSprintDate: nearestEnrollmentSprintDate,
                formattedNearestEnrollmentDate: nearestEnrollmentSprintDate?.format('MMMM, D Y'),
                currentSprint: currentSprint,
                userInfo: userInfo,
                ua: {
                    browser: parser.getBrowser(),
                    device: parser.getDevice(),
                    os: parser.getOS()
                }
            }

            contentRef.current.innerHTML = template(templateVariables)
        }
    }, [content])

    useEffect(()=>{

        if(headerRef.current){
            setContentHeight(height - headerRef?.current?.getBoundingClientRect().height)
        }
    }, [height, headerRef])

    return (
        // Не менять. Нужно для работы элемента handleHideStories, чтобы не перекрывать слои
        <div className={`flex flex-col w-full h-screen max-h-screen justify-star pt-5 ${storyClass}`} style={storyStyle}>
            {/*HEADER*/}
            {/* Не менять. Нужно для работы элемента handleHideStories, чтобы не перекрывать слои */}
            <div
                ref={headerRef}
                className={"flex flex-row w-full justify-end pr-2.5 cursor-pointer"}
                style={{ zIndex: 1000 }}
                onClick={handleHideStories}
            >
                <IconContext.Provider value={{className: "w-6 h-6"}}>
                    <IoMdClose />
                </IconContext.Provider>
            </div>
            {/*BODY*/}
            <div
                ref={contentRef}
                className={"flex flex-col w-full items-center pt-4 px-3"}
                style={{height: contentHeight - 20, maxHeight: contentHeight - 20}}
            >

            </div>
        </div>
    );
};

Story.propTypes = {
    userInfo: PropTypes.object,
    currentSprint: PropTypes.object,
    content: PropTypes.any,
    toggleMore: PropTypes.any,
    action: PropTypes.any,
    hideStories: PropTypes.func,
    previewStory: PropTypes.func,
    nextStory: PropTypes.func,
    resetToInitStory: PropTypes.func,
    settings: PropTypes.object,
    contentRef: PropTypes.any,
    nearestEnrollmentSprintDate: PropTypes.any,
    height: PropTypes.number,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    currentSprint: state.config.currentSprint,
    nearestEnrollmentSprintDate: state.common.nearestEnrollmentSprintDate
})

const mapDispatchToProps = (dispatch) => ({
    hideStories: () => dispatch({type: HIDE_STORIES}),
})

export default connect(mapStateToProps, mapDispatchToProps)(Story)