import React from 'react'

import {connect} from "react-redux";
import { HiLogout } from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

const LogoutButton = ( {rotate='rotate-180'} ) => {

    const navigate = useNavigate()

    return (
        <HiLogout
            className={`h-8 w-8 cursor-pointer ${rotate}`}
            onClick={()=>navigate('/logout')}
        />
    )
}

LogoutButton.propTypes = {
    rotate: PropTypes.string
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LogoutButton)