import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {
    CLEAN_HOMEWORK_DATA,
    GET_HOMEWORK,
} from "../../redux/actions/configurator-actions";
import {connect} from "react-redux";
import {withRouter} from '../../helpers/with-router'
import FillHomework from "./fill/index";
import {Link, useParams} from "react-router-dom";
import QuizletHomework from "./quizlet";
import Loading from "../components/Loading";
import {usePrevious} from "../../hooks/usePrevious";

const HomeworkScreen = ({
                            userInfo,
                            getHomework,
                            cleanHomeworkData,
                            homeworkType,
                            loadingGetHomework,
                            homeworkPermissions,
                            currentSprint,
                            loadingUserInfo
}) => {

    const { homeworkId } = useParams();

    const [userAllowed, setUserAllowed] = useState(false)
    const [denyMessage, setDenyMessage] = useState(null)

    const prevUserInfo = usePrevious(userInfo)

    const loading = loadingGetHomework || loadingUserInfo

    useEffect(()=>{
        if((prevUserInfo === undefined || prevUserInfo === null) && userInfo){
            getHomework({
                homeworkId: homeworkId,
                studentUid: userInfo.uid
            })
        }
    }, [prevUserInfo, userInfo])

    useEffect(()=>{
        return () => {
            cleanHomeworkData()
        };
    }, [])

    useEffect(() => {
        if(homeworkPermissions && currentSprint && userInfo) {
            if(homeworkPermissions.condition === 'if_current_sprint' && homeworkPermissions.allow === true){
                if(homeworkPermissions.sprintId === currentSprint.id && userInfo.canAccessSprint.granted) {
                    setUserAllowed(true)
                } else {
                    setUserAllowed(false)

                    if(homeworkPermissions.sprintId !== currentSprint.id){
                        setDenyMessage(
                            <div className={"flex flex-col text-center text-lg"}>
                                <span>{`You did’t sign up for that sprint.`}</span>
                                <span>{`Available sprints can be checked `}
                                    <Link to={'/stats?tab=sprints'} className={"text-warning after:content-['_↗']"}>
                                        here
                                    </Link>
                                </span>
                            </div>
                        )
                    }

                    if(!userInfo.canAccessSprint.granted) {
                        setDenyMessage(<div className={"text-center text-lg"}>{`You did’t sign up for that sprint.`}</div>)
                    }
                }
            } else if (homeworkPermissions.allow === true) {
                setUserAllowed(true)
            } else {
                setUserAllowed(false)

                switch (homeworkPermissions.condition) {
                    case 'sprintOrHomeworkNotFound':
                        setDenyMessage(<div className={"text-center text-lg"}>{`Sprint or homework not found.`}</div>)
                        break;
                    case 'student_not_enrolled':
                        setDenyMessage(<div className={"text-center text-lg"}>{`You were not in that sprint.`}</div>)
                        break;
                }
            }
        }
    }, [homeworkPermissions, currentSprint, userInfo]);

    return (
        <React.Fragment>
            {loading ? (
                <Loading isFullScreen={true}/>
            ) : (

                <>
                    {userAllowed ? (
                        {
                            'quizlet': <QuizletHomework />,
                            'fill': <FillHomework />
                        }[homeworkType]
                    ) : (
                        <div className={`relative flex flex-row justify-center items-center w-full min-h-screen`}>
                            {denyMessage}
                        </div>
                    )}

                </>
            )}
        </React.Fragment>
    );
}

HomeworkScreen.propTypes = {
    userInfo: PropTypes.object,
    homeworkType: PropTypes.string,
    loadingGetHomework: PropTypes.bool,
    getHomework: PropTypes.func,
    cleanHomeworkData: PropTypes.func,
    homeworkPermissions: PropTypes.object,
    currentSprint: PropTypes.object,
    needUpdateUserInfo: PropTypes.bool,
    loadingUserInfo: PropTypes.bool
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    homeworkType: state.config.type,
    loadingGetHomework: state.config.loadingGetHomework,
    homeworkPermissions: state.config.homeworkPermissions,
    currentSprint: state.config.currentSprint,
    loadingUserInfo: state.user.loadingUserInfo
})

const mapDispatchToProps = (dispatch) => ({
    getHomework: (params) => dispatch({ type: GET_HOMEWORK, payload: params }),
    cleanHomeworkData: () => dispatch({ type: CLEAN_HOMEWORK_DATA }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeworkScreen))