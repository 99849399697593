import React from 'react'
import {connect} from "react-redux";


const Skeleton = () => {

    return (
        <div className="p-4 card bg-info text-dark pt-10 min-h-36">
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 items-end gap-4 border-b border-slate-200 pb-1">
                            <div className="h-4 bg-slate-200 rounded col-span-2"></div>
                            <div className={"h-3 bg-slate-200 rounded col-span-1"}></div>
                        </div>
                        <div className="h-4 bg-slate-200 rounded"></div>
                        <div className="h-8 bg-slate-200 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Skeleton.propTypes = {}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Skeleton)