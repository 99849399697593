import React from "react";
import { connect } from "react-redux";
import Text from "./Text"
import Header from "./Header";
const CookiesPolicy = () => {

    return <React.Fragment>
        <div className={"w-full xs:max-w-laptop prose text-primary pt-10 px-4"}>
            <h1 className={"text-3xl font-bold text-primary"}>Cookie Policy</h1>
            <p className={"text-sm font-bold pb-3"}>Last Updated: December 01, 2023</p>
            <Text>
                {`This cookie policy outlines the usage of "cookies" and similar technologies on the website,
                    BlueBabushka.com, and its subdomains. Any capitalized term used and not defined below can be found in
                    the associated Privacy Policy.`}
            </Text>

            <Header>1. What is a Cookie?</Header>
            <Text>
                {`Cookies are small text files stored in a computer's browser directory.
                    They assist site providers in understanding user interactions, remembering login details, and storing
                    site preferences.`}
            </Text>

            <Header>Does Bluebabushka Use Cookies?</Header>
            <Text>
                Yes, Blue Babushka uses cookies in adherence to its Privacy Policy. Cookies are utilized to:
                <ul className={"list-outside"}>
                    <li>
                        Ensure proper functionality of our Services.
                    </li>
                    <li>
                        Detect and prevent fraud.
                    </li>
                    <li>
                        Understand visitor engagement with our Site.
                    </li>
                    <li>
                        Analyze and enhance Services.
                    </li>
                </ul>
            </Text>

            <Header>{`Who Sets Cookies When Using BlueBabushka's Site?`}</Header>
            <Text>
                There are two main types of cookies:

                <ul className={"list-outside"}>
                    <li>
                        <span className={"italic"}>First-Party Cookies</span>: Placed and read by Blue Babushka directly.
                    </li>
                    <li>
                        <span className={"italic"}>Third-Party Cookies</span>: Set by other companies (e.g., Google, Facebook) for site analytics.
                        Refer to the section below for managing these cookies.
                    </li>
                </ul>
            </Text>

            <Header>How BlueBabushka Uses Cookies?</Header>
            <Text>
                Cookies are crucial for effective and secure Services. Commonly used cookie types include:

                <h4 className={"text-base text-primary"}>
                    Necessary Cookies:
                </h4>
                <ul className={"list-outside"}>
                    <li>
                        <span className={"italic"}>Authentication</span>: Remembering login states for seamless navigation.
                    </li>
                    <li>
                        <span className={"italic"}>Fraud Prevention and Detection</span>: Utilizing cookies to identify potential misuse.
                    </li>
                    <li>
                        <span className={"italic"}>Security</span>: Protecting user data from unauthorized access.
                    </li>
                    <li>
                        <span className={"italic"}>Functionality</span>: Ensuring correct operation of the Site and Services.
                    </li>
                </ul>

                <h4 className={"text-base text-primary"}>
                    Preference Cookies:
                </h4>
                <ul className={"list-outside"}>
                    <li>Used to remember preferences and recognize returning users.</li>
                </ul>

                <h4 className={"text-base text-primary"}>
                    Analytics Cookies:
                </h4>
                <ul className={"list-outside"}>
                    <li>
                        <span className={"italic"}>Site Features and Services</span>: Remembering user preferences.
                    </li>
                    <li>
                        <span className={"italic"}>Analyzing and Improving Services</span>: Enhancing Site and Services
                        based on user insights.
                    </li>
                    <li>
                        <span className={"italic"}>Pixel Tags</span>: Tracking user actions and measuring marketing
                        campaign success.
                    </li>
                    <li>
                        <span className={"italic"}>Third-Party Analytics</span>: Utilizing Google Analytics to collect
                        and analyze information.
                    </li>
                </ul>

                <h4 className={"text-base text-primary"}>
                    Analytics Cookies:
                </h4>
                <ul className={"list-outside"}>
                    <li>Used to display targeted ads for BlueBabushka Services and measure user engagement.</li>
                </ul>
            </Text>

            <Header>5. Can Users Opt-Out?</Header>
            <Text>
                Users can manage cookie preferences in their web browsers. Disabling cookies may impact certain features
                of the Site or Services.
            </Text>
            <Text>
                <span className={"italic"}>
                    Web Browser Links for Managing Cookie Preferences:
                </span>

                <ul className={"list-outside"}>
                    <li>
                        <a href={"https://support.google.com/chrome/answer/95647?hl=en"}
                           className={"text-blue-500 after:content-['_↗']"}
                        >
                            Chrome
                        </a>
                    </li>
                    <li>
                        <a href={"https://support.google.com/chrome/answer/95647?hl=en"}
                           className={"text-blue-500 after:content-['_↗']"}
                        >
                            Safari
                        </a>
                    </li>
                    <li>
                        <a href={"https://support.google.com/chrome/answer/95647?hl=en"}
                           className={"text-blue-500 after:content-['_↗']"}
                        >
                            Firefox
                        </a>
                    </li>
                    <li>
                        <a href={"https://support.google.com/chrome/answer/95647?hl=en"}
                           className={"text-blue-500 after:content-['_↗']"}
                        >
                            Explorer
                        </a>
                    </li>
                    <li>
                        <a href={"https://support.google.com/chrome/answer/95647?hl=en"}
                           className={"text-blue-500 after:content-['_↗']"}
                        >
                            Opera
                        </a>
                    </li>
                </ul>
            </Text>

        </div>
    </React.Fragment>
}

CookiesPolicy.propTypes = {
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CookiesPolicy)