import client from './client'
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import {accountApiUrl, paymentApiUrl} from "../../helpers/constants";

const accountClient = client(`${accountApiUrl}/api/v1`)
const paymentClient = client(`${paymentApiUrl}`)

export const updateUserProfile = async (params) => {

  try {
    let data = snakecaseKeys(params)
    const response = await accountClient.put(
        `/profile/${params.user}/`,
        data,
    )
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const getUserInfo = async (uid) => {

  try {
    const response = await accountClient.get(`/users/${uid}/`)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const getUserStats = async (uid) => {

  try {
    const response = await accountClient.get(`/users/${uid}/stats/`)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

const searchProfileByParams = async (params) => {

  const query = new URLSearchParams(params)

  try {
    const response = await accountClient.get(`/profile/?${query}`)
    return camelcaseKeys(response, {deep: true})
  } catch (response) {
    return camelcaseKeys(response, {deep: true})
  }
}

const searchAccountByParams = async (params) => {

  const query = new URLSearchParams(params)

  try {
    const response = await accountClient.get(`/users/?${query}`)
    return camelcaseKeys(response, {deep: true})
  } catch (response) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const getUserBalanceHistory = async (params) => {

  const query = new URLSearchParams(params.query)

  try {
    const response = await accountClient.get(`/users/${params.uid}/balance_history/?${query}`)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const getUserSubscription = async (params) => {

  const query = new URLSearchParams(params.query)

  try {
    const response = await paymentClient.get(`/user/${params.uid}/subscription/?${query}`)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const getAllPaymentMethods = async (uid) => {
  try {
    const response = await paymentClient.get(`/user/${uid}/payment_method/`)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

const userApi = {
  updateUserProfile,
  getUserInfo,
  getUserStats,
  getUserBalanceHistory,
  getUserSubscription,
  searchProfileByParams,
  searchAccountByParams,
  getAllPaymentMethods
};

export default userApi;
