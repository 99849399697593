import React from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import PropTypes from "prop-types";
import {connect} from "react-redux";

const StripeCardElement = ({ setPaymentCardError, setIsCompletePaymentCard, paymentCardError }) => {

    const stripe = useStripe();
    const elements = useElements();

    const cardElementOptions = {
        hidePostalCode: true,
        style: {
            base: {
                color: "#4C637D",
                letterSpacing: "0.025em",
                fontFamily: "Montserrat, sans-serif",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#F83A3A"
            }
        }
    }

    if (!stripe || !elements) {
        return '';
    }

    return (
        <>
            <CardElement
                options={cardElementOptions}
                onChange={(event) => {
                    if (event.error) {
                        setPaymentCardError(event.error);
                    } else {
                        setPaymentCardError(null)
                    }
                    setIsCompletePaymentCard(event.complete)
                }}
            />
            {paymentCardError &&
                <label className="label">
                    <span className="label-text-alt text-brandRed">{ paymentCardError.message }</span>
                </label>
            }
        </>
    )
}

StripeCardElement.propTypes = {
    setPaymentCardError: PropTypes.func.isRequired,
    setIsCompletePaymentCard: PropTypes.func.isRequired,
    paymentCardError: PropTypes.object
}

const mapStateToProps = () => ({

})

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(StripeCardElement)
