import React, {useEffect} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {usePrevious} from "../../../hooks/usePrevious";
import Loading from "../../components/Loading";

const PutOnHoldModal = ({ openModal, setOpenModalState, switchOff, updateProfileLoading, updateSwitchState, week }) => {

    const prevLoadingState = usePrevious(updateProfileLoading)

    const handleButton = () => {
        updateSwitchState()
    }

    useEffect(()=>{
        if(prevLoadingState === true && updateProfileLoading === false){
            setOpenModalState(false)
        }
    }, [updateProfileLoading])

    const content = [
        // Confirm Sprints Cancellation
        <>
            <h3 className="font-bold text-lg">Put your weeks on hold</h3>
            <p className={"py-4"}>
                <span className={"text-error font-bold"}>Warning</span>:
                If you turn on the switch, you will not be added into the group {week}.
            </p>
        </>,
        // Confirm Sprints Started
        <>
            <h3 className="font-bold text-lg">Start your lessons</h3>
            <p className={"py-4"}>
                <span className={"text-error font-bold"}>Warning</span>:
                If you turn off the switch, you will to start your lessons and well be added into the group {week}.
            </p>
        </>
    ]

    return (
        <React.Fragment>
            {/* Put this part before </body> tag */}
            <dialog className={`flex flex-row justify-center modal modal-bottom ${openModal ? 'modal-open' : null}`}>
                <form method={"dialog"} className="p-5 modal-box bg-white w-screen xs:max-w-laptop">
                    {switchOff ? content[1] : content[0]}
                    <div className={"flex flex-row justify-end space-x-3 mt-5"}>
                        <button
                            onClick={handleButton}
                            className={`btn btn-primary w-24 rounded-full shadow-brand-button}`}
                        >
                            {updateProfileLoading ? <Loading isFullScreen={false} size={'sm'}/> : (
                                <span>{switchOff ? 'Turn off' : 'Turn on'}</span>
                            )}
                        </button>
                        <button
                            onClick={()=> setOpenModalState(false)}
                            className={`btn rounded-full shadow-brand-button ${updateProfileLoading ? 'btn-disabled btn-secondary' : 'btn-primary'}`}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </dialog>
        </React.Fragment>
    );
};

PutOnHoldModal.propTypes = {
    week: PropTypes.string,
    openModal: PropTypes.bool,
    setOpenModalState: PropTypes.func,
    switchOff: PropTypes.bool,
    setEnabled: PropTypes.func,
    updateSwitchState: PropTypes.func,
    updateProfileLoading: PropTypes.bool
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PutOnHoldModal)