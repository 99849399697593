import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Stories from "../welcome/stories";

const StoriesModal = ({maxHeight, maxWidth, series, storiesIsOpen, loop}) => {

    return (
        <dialog
            className={`flex flex-row justify-center modal h-screen rounded-none ${storiesIsOpen ? 'modal-open' : ''}`}
        >
            <form method={"dialog"}
                  style={{maxHeight: maxHeight, height: maxHeight, maxWidth: 450, width: maxWidth}}
                  className={`modal-box w-screen xs:max-w-laptop p-0 rounded-none`}
            >
                <Stories
                    loop={loop}
                    series={series}
                    height={maxHeight}
                    width={maxWidth > 450 ? 450 : maxWidth}
                />
            </form>
        </dialog>
    )
}

StoriesModal.propTypes = {
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    series: PropTypes.array,
    storiesIsOpen: PropTypes.bool,
    loop: PropTypes.bool
}

const mapStateToProps = (state) => ({
    storiesIsOpen: state.stories.storiesIsOpen,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(StoriesModal)