import React from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';
import {connect} from "react-redux";
import AssignmentCard from "./AssignmentCard";
import {GET_DAILY_SUMMARY} from "../../redux/actions/configurator-actions";
import {withRouter} from "../../helpers/with-router";
import Loading from "../components/Loading";
import moment from "moment-timezone";


class DailySummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            isSprintDay: this.props.today.isoWeekday() >= this.props.currentMonday.isoWeekday() && this.props.today.isoWeekday() < this.props.currentSunday.isoWeekday(),
            day: null
        };
    }

    componentDidMount() {

        let startDate;
        if(this.props.today.isoWeekday() === this.props.currentMonday.isoWeekday()){
            startDate = this.props.today
        } else if (this.props.today < moment().tz('Australia/Sydney').set('hour', 10).set('minute', 30) ){
            startDate = this.props.today.clone().subtract(1, "days")
        } else if(this.props.today.isoWeekday() === 6){
            startDate = this.props.today.clone().subtract(1, "days")
        } else {
            startDate = this.props.today
        }

        this.setState({ day: startDate.isoWeekday() })

        let query = {
            startDate: startDate.format("YYYY-MM-DD"),
            studentUid: this.props.currentUser.uid
        }

        this.props.getDailySummary(query)
    }

    render() {
        return (
            <>
                {this.props.loadingDailySummary ? (
                    <div className="flex flex-col justify-center items-center bg-brandYellow min-h-screen">
                        <Loading isFullScreen={true}/>
                    </div>
                ) : (
                    <>
                        {this.state.message ? (
                            <div className="flex flex-col items-center justify-center min-h-screen bg-brandYellow">
                                <div className={"w-full xs:max-w-laptop"}>
                                    <div className="justify-center pt-10 px-4 flex flex-row">
                                        <h1 className={"font-bold text-3xl text-center"}>{this.state.message}</h1>
                                    </div>
                                </div>
                            </div>


                        ) : (
                            <div className="flex flex-col items-center min-h-screen bg-brandYellow">
                                <div className={"w-full xs:max-w-laptop"}>
                                    <div className="flex flex-row justify-start items-center pt-10 px-4">
                                        <h2 className="mt-4 text-xl font-bold text-left">Daily Tasks {this.state.isSprintDay && ` - Day ${this.state.day}`}</h2>
                                    </div>
                                    { this.state.isSprintDay ? (
                                        <>
                                            { this.props.dailySummary && this.props.dailySummary.map((summary, index) => {
                                                const allAssignments = summary[0].allAssignments
                                                const correctAnswer = summary[0].correctAnswer
                                                return (
                                                    <AssignmentCard
                                                        key={uuidv4()}
                                                        number={index+1}
                                                        title={summary[0].homework.title}
                                                        type={summary[0].homework.type}
                                                        media={summary[0].homework.media}
                                                        link={`/homework/${summary[0].homework.id}`}
                                                        all={allAssignments}
                                                        correct={correctAnswer}
                                                        status={correctAnswer + allAssignments === 0 ? 'wait' : 'completed'}
                                                    />
                                                )
                                            })}
                                        </>
                                    ) : (
                                        <div className={"justify-start pt-10 px-4 flex flex-row h-full"}>
                                            <div>THERE ARE NO TASKS FOR YOU YET</div>
                                        </div>
                                    ) }

                                </div>
                            </div>
                        )}
                    </>
                )}
            </>
        )
    }
}

DailySummary.propTypes = {
    isLoggedIn: PropTypes.bool,
    currentUser: PropTypes.object,
    userInfo: PropTypes.object,
    lock: PropTypes.object,
    loadingDailySummary: PropTypes.bool,
    dailySummaryError: PropTypes.string,
    dailySummary: PropTypes.array,
    today: PropTypes.any,
    currentMonday: PropTypes.any,
    currentSunday: PropTypes.any,
    getDailySummary: PropTypes.func
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    userInfo: state.user.userInfo,
    lock: state.user.lock,
    today: state.common.today,
    currentMonday: state.common.currentMonday,
    currentSunday: state.common.currentSunday,
    loadingDailySummary: state.config.loadingDailySummary,
    dailySummaryError: state.config.dailySummaryError,
    dailySummary: state.config.dailySummary
})

const mapDispatchToProps = (dispatch) => ({
    getDailySummary: (date) => dispatch({ type: GET_DAILY_SUMMARY, payload: date }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DailySummary))
