import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import CreateSubscription from "./CreateSubscription";
import Subscription from "./Subscription";
import Spinner from "../components/Spinner";
import {useStripe} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import OneTimePayment from "./OneTimePaymentCard";
import usePaymentProcessor from "../../helpers/PaymentProcessor";

const StripeProcessing = ({ userSubscription, userInfo, priceItems }) => {

    const stripe = useStripe()
    const { processPayment } = usePaymentProcessor();

    const [hasStripe, setHasStripe] = useState(false)

    const [weeks, setWeeks] = useState(1)
    const [loading, setLoading] = useState(false)

    const processedPayment = async () => {
        await processPayment({
                provider: 'stripe',
                setLoading: setLoading,
                weeks: weeks,
                isNewCustomer: userInfo.isNewCustomer,
                userInfo: userInfo,
                priceItems: priceItems,
                returnUrl: process.env.REACT_APP_BASE_URL + '/billing'
            }
        );
    }

    useEffect(()=>{
        if(stripe){
            setHasStripe(true)
        }
    },[stripe])

    return (
        <React.Fragment>
            {hasStripe ? (
                <div className={"px-4"}>
                    {userSubscription?.subscription ? (
                        <Subscription />
                    ) : (
                        <>
                            <CreateSubscription />
                            <div className="divider">OR</div>
                            <OneTimePayment
                                weeks={weeks}
                                setWeeks={setWeeks}
                                proccessedPayment={processedPayment}
                                loading={loading}
                            />
                            {/*<StripePayment />*/}
                        </>
                    )}
                </div>
            ) : <Spinner />}

        </React.Fragment>
    )
}

StripeProcessing.propTypes = {
    userSubscription: PropTypes.object,
    userInfo: PropTypes.object,
    priceItems: PropTypes.object,
}

const mapStateToProps = (state) => ({
    userSubscription: state.billing.userSubscription,
    userInfo: state.user.userInfo,
    priceItems: state.preference.priceItems,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(StripeProcessing)
