import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";
// import { IoMdSettings } from "react-icons/io";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {MdOutlineManageAccounts} from "react-icons/md";

const SettingsButton = ({ userInfo }) => {

    const navigate = useNavigate()

    const [withAnimation, setAnimation] = useState(false)

    useEffect(()=>{
        if(userInfo){
            setAnimation(!userInfo.isAccountSynced)
        }
    },[userInfo])

    useEffect(() => {
        return ()=>{
            setAnimation(false)
        }
    }, []);

    return (
        <span
            className={"relative flex cursor-pointer"}
            onClick={()=> navigate('/account')}
        >
            {withAnimation && (
                <>
                    <span
                        className="animate-ping-slow absolute -top-0.5 -right-0.5 inline-flex h-3 w-3 rounded-full bg-orange opacity-75"/>
                    <span className="absolute top-0 right-0 inline-flex h-2 w-2 rounded-full bg-orange"></span>
                </>
            )}
            <MdOutlineManageAccounts
                className={"h-8 w-8"}

            />
        </span>

    )
}

SettingsButton.propTypes = {
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsButton)


