import React from 'react';
import PropTypes from "prop-types";

const Emoji = ({ className, label, symbol }) => {
    return (
        <span
            className={className}
            role="img"
            aria-label={label ? label : ""}
            aria-hidden={label ? "false" : "true"}
        >
            {symbol}
        </span>
    )
};

Emoji.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    symbol: PropTypes.string
}
export default Emoji;
