import client from './client'
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import {homeworkApiUrl} from "../../helpers/constants";
import moment from "moment";

const homeworkClient = client(`${homeworkApiUrl}/api`)

const getHomeworksByDate = async (date) => {
    try {
        const response = await homeworkClient.get(`/configuration/?homework__start_date=${date}&homework__status=published`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getConfigByHomeworkId = async (id) => {
    try {
        const response = await homeworkClient.get(`/configuration/${id}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getStudentAnswerByHomeworkId = async (params) => {
    try {
        const response = await homeworkClient.get(`/users/${params.studentUid}/homeworks/${params.homeworkId}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getSprintSummary = async (params) => {
    try {
        const response = await homeworkClient.get(`/users/${params.studentUid}/sprints/${params.sprintId}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getSprintsSummaries = async (params) => {
    try {
        const response = await homeworkClient.get(`/users/${params.studentUid}/sprints/`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getAllStudentAnswers = async (studentId) => {
    try {
        const answersData = await homeworkClient.get(`/answers/?student__uid=${studentId}`)
        return camelcaseKeys(answersData.data[0], {deep: true})
    } catch(err) {
        return console.error(err)
    }
}

const createAnswer = async (answer) => {
    try {
        const data = snakecaseKeys(answer)
        const response = await homeworkClient.post(`/answers/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getFtfByDates = async (dates) => {
    try {
        const response = await homeworkClient.get(`/ftf/?sprint__start_date=${dates.startDate}&sprint__end_date=${dates.endDate}`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getFtfPairByFtfAndStudent = async (params) => {
    try {
        const response = await homeworkClient.get(`/ftf-pairs/?ftf_id=${params.sprint}&student=${params.student}`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const findUserInCurrentSprint = async (uid) => {

    const startDate = moment().isoWeekday(1).format('YYYY-MM-DD');

    try {
        const response = await homeworkClient.get(`/sprint-students/?account=${uid}&sprint__start_date=${startDate}`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getSprint = async (weekNumber) => {

    try {
        const response = await homeworkClient.get(`/sprints/week/${weekNumber}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({ response }) {
        return camelcaseKeys(response, {deep: true})
    }
}

const confApi = {
    getHomeworksByDate,
    getConfigByHomeworkId,
    getSprintSummary,
    getStudentAnswerByHomeworkId,
    getAllStudentAnswers,
    createAnswer,
    getFtfByDates,
    getFtfPairByFtfAndStudent,
    findUserInCurrentSprint,
    getSprint,
    getSprintsSummaries
}

export default confApi
