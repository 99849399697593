import React, {useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import coverImage from "../../../../assets/achievement/сover.svg";
import Badge from "./Badge";
import moment from "moment-timezone";
import {SET_CURRENT_ACHIEVEMENT} from "../../../../redux/actions/achievement-actions";


const DetailAchievementTile = ({ achievement, isClose, setCurrentAchievement }) => {

    const [loadingBadge, setLoadingBadge] = useState(true)

    const shareButtonHandle = () => {
        setCurrentAchievement({achievement: achievement, modalIsOpen: true})
    }

    return (
        <React.Fragment>
            <div className={"flex flex-row w-9/12 space-x-4"}>
                <div className={"w-14"}>
                    {loadingBadge && (
                        <img
                            src={coverImage}
                            alt={"cover"}
                        />
                    )}
                    {achievement && <Badge
                        badge={achievement.achievement.badge}
                        number={achievement.result}
                        isClose={isClose}
                        isIcon={true}
                        setLoading={setLoadingBadge}
                    />}
                </div>

                <div className={"flex flex-col justify-between"}>
                    <header className={"flex flex-col items-start"}>
                        <span className={"text-sm text-primary font-semibold"}>{achievement.achievement.displayTitle}</span>
                        <span className={"text-xs text-primary opacity-50"}>{moment(achievement.createdAt).format('DD-MM-YYYY')}</span>
                    </header>
                    {!achievement.wasShown && (
                        <div className="badge badge-sm badge-accent pb-0.5">new</div>
                    )}
                </div>
            </div>
            <div className={"w-3/12 flex flex-row justify-end"}>
                <button
                    onClick={()=>shareButtonHandle(achievement)}
                    disabled={achievement.achievement.posters.length === 0}
                    className="rounded-full btn btn-primary btn-xs text-xs normal-case"
                >
                    Share
                </button>
            </div>
        </React.Fragment>
    );
};

DetailAchievementTile.propTypes = {
    achievement: PropTypes.object,
    isClose: PropTypes.bool,
    setCurrentAchievement: PropTypes.func
}

const mapStateToProps = () => ({

})

const mapDispatchToProps = (dispatch) => ({
    setCurrentAchievement: ({modalIsOpen, achievement}) => dispatch({
        type: SET_CURRENT_ACHIEVEMENT,
        payload: {modalIsOpen, achievement}
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailAchievementTile)