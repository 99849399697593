import React, { useEffect, useState} from "react";
import { SVG } from "@svgdotjs/svg.js";
import axios from "axios";
import numbers from "./numbers";
import PropTypes from "prop-types";
import {connect} from "react-redux";

const Badge = ({
                        isClose,
                        setLoading,
                        badge,
                        number,
                        isIcon = false
}) => {

    const [imageUrl, setImageUrl] = useState('');

    const handleBadgeOnLoad = () => {
        setLoading(false);
    };

    useEffect(() => {
        if (number !== null) {
            setLoading(true);
            axios.get(badge).then(response => {
                const svgTemplate = response.data;

                const s = SVG(svgTemplate);
                const digits = Array.from(String(number), String);

                if (digits.length > 1) {
                    digits.forEach((digit, index) => {
                        const path = s.findOne(`#number${index + 1}`);
                        path.attr({ d: numbers[`${digit}`] });
                    });
                } else if (digits.length === 1) {
                    const path = s.findOne(`#number`);
                    path.attr({ d: numbers[`${digits[0]}`] });
                }

                const svg = new Blob([s.node.outerHTML], { type: "image/svg+xml" });
                const url = URL.createObjectURL(svg);
                setImageUrl(url);
            });
        }

        return () => {
            setImageUrl('');
        };
    }, [number]);

    return (
        <>
            {imageUrl !== '' && <img
                alt="Badge"
                src={imageUrl}
                onLoad={handleBadgeOnLoad}
                className={`${isIcon ? 'w-full' : 'object-cover rounded-xl'} ${isClose ? 'grayscale' : 'grayscale-0'}`}
            />}
        </>
    );
};

Badge.propTypes = {
    isClose: PropTypes.bool,
    setLoading: PropTypes.func,
    badge: PropTypes.string,
    number: PropTypes.number,
    isIcon: PropTypes.bool
}

const mapStateToProps = () => ({

})

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Badge)