import React from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";

import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Rating from "../components/Rating";

const AssignmentCard = ({
                            number,
                            title,
                            type,
                            link,
                            all,
                            correct,
                            status
}) => {

    return (
        <div className={"flex flex-row items-center"}>
            <div className="avatar placeholder">
                <div className="mx-4 bg-white rounded-full w-5 h-5 p-3 shadow-brand">
                    <span>{number}</span>
                </div>
            </div>
            <div className={"w-screen flex flex-col mr-4"}>
                <Link to={link} className="link self-start text-primary font-medium pb-1">{title}</Link>
                <div className={"flex flex-row justify-between items-center"}>
                    <div className={"inline-flex"}>
                        {type !== 'quizlet' && (
                            <Rating status={status} all={all} correct={correct}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

AssignmentCard.propTypes = {
    status: PropTypes.string,
    number: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
    link: PropTypes.string,
    all: PropTypes.number,
    correct: PropTypes.number,
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

// To make those two function works register it using connect
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentCard)
