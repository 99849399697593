import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {BrowserRouter as Router} from "react-router-dom";
import {GTMProvider} from "@elgorditosalsero/react-gtm-hook";
import {loadStripe} from "@stripe/stripe-js";

import {Elements} from "@stripe/react-stripe-js";

import Main from "./screens/Main";
// import Notification from "./screens/components/Notification";

import AuthProvider from "./context/AuthContext";
import ToastProvider from "./context/ToastContext";

import {stripePublicKey} from "./helpers/constants";

import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener';

// eslint-disable-next-line no-unused-vars
// import {usePullToRefresh} from "./helpers/usePullToRefresh";
// import {IoReloadOutline} from "react-icons/io5";

import "./App.css";
import {getDisplayMode} from "./helpers/validation";

const App = ({ gtmParams }) => {

    const stripeOptions = {
        locale: 'en'
    }

    const [updateWaiting, setUpdateWaiting] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [swListener, setSwListener] = useState(null);
    const [displayMode, setDisplayMode] = useState("");

    const cloudPaymentsScript = document.createElement('script');

    // const MAXIMUM_PULL_LENGTH = 240;
    // const REFRESH_THRESHOLD = 100;
    //
    // // eslint-disable-next-line no-unused-vars
    // const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    //
    // const reload = () => {
    //     // Perform any additional actions if needed before reloading
    //     window.location.reload() // This will reload the current page
    // };
    //
    // // Use isReady as needed
    // const isReady = true;
    //
    // // eslint-disable-next-line no-unused-vars
    // const { isRefreshing, pullPosition, containerRef } = usePullToRefresh({
    //     // you can choose what behavior for `onRefresh`, could be calling an API to load more data, or refresh whole page.
    //     onRefresh: reload,
    //     maximumPullLength: MAXIMUM_PULL_LENGTH,
    //     refreshThreshold: REFRESH_THRESHOLD,
    //     isDisabled: !isReady,
    // });

    useEffect(()=>{

        cloudPaymentsScript.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
        cloudPaymentsScript.async = true;
        cloudPaymentsScript.onload = function() {
            // setLoading(false)
        };
        document.body.appendChild(cloudPaymentsScript);

        return () => {
            if (cloudPaymentsScript.parentNode === document.body) {
                document.body.removeChild(cloudPaymentsScript);
            }
        }
    },[])


    useEffect(() => {
        setDisplayMode(getDisplayMode())
        const updater = async () => {
            if (process.env.NODE_ENV !== "development") {
                const listener = await new ServiceWorkerUpdateListener();
                setSwListener(listener);

                listener.onupdatewaiting = () => {
                    setUpdateWaiting(true);
                };
                listener.onupdateready = () => {
                    window.location.reload();
                };

                navigator.serviceWorker.getRegistration().then((reg) => {
                    listener.addRegistration(reg);
                    setRegistration(reg);
                });

                return () => {
                    listener.removeEventListener();
                };
            }
        };

        updater();

    }, []);

    useEffect(() => {
        if (updateWaiting) {
            toast.info(<UpdateWaiting />, {
                autoClose: false,
                closeOnClick: false,
                toastId: "update"
            });
        } else {
            toast.dismiss('update');
        }

    }, [updateWaiting]);

    const UpdateWaiting = () => {
        const handleUpdateButton = (event) => {
            event.preventDefault();
            try {
                swListener.skipWaiting(registration.waiting);
                toast.dismiss('update');
            } catch {
                toast.dismiss('update');
            }
        };

        return (
            <div className="flex flex-row justify-between items-center w-full xs:max-w-laptop">
                <div>Update waiting!</div>
                <button className="btn btn-md btn-primary ml-2" onClick={handleUpdateButton}>Update</button>
            </div>
        );
    };

    return (
        <GTMProvider state={gtmParams}>
            <Router>
                <AuthProvider>
                    <ToastProvider>
                        <Elements stripe={loadStripe(stripePublicKey)} options={stripeOptions}>
                            {/*{displayMode === 'standalone' && isiOS ? (*/}
                            {/*    <div*/}
                            {/*        ref={containerRef}*/}
                            {/*        style={{*/}
                            {/*            // top: 1,*/}
                            {/*            opacity: isRefreshing || pullPosition > 0 ? 1 : 0,*/}
                            {/*            zIndex: 999*/}
                            {/*        }}*/}
                            {/*        className='bg-base-100 top-1 fixed inset-x-1/2 h-8 w-8 -translate-x-1/2 rounded-full p-2 shadow'*/}
                            {/*    >*/}
                            {/*        <div*/}
                            {/*            className={`h-full w-full ${isRefreshing ? 'animate-spin' : ''}`}*/}
                            {/*            style={!isRefreshing ? {transform: `rotate(${pullPosition}deg)`} : {}}*/}
                            {/*        >*/}
                            {/*            <IoReloadOutline className='h-full w-full'/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*) : null}*/}

                            <Main displayMode={displayMode}/>
                        </Elements>
                        {/*<Notification/>*/}
                    </ToastProvider>
                </AuthProvider>
            </Router>
        </GTMProvider>
    );
};

App.propTypes = {
    gtmParams: PropTypes.object,
}

const mapStateToProps = (state) => ({
    gtmParams: state.common.gtmData,
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(App);