import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from './redux/store'
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react'
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";

import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(<React.Fragment>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>

</React.Fragment>);

serviceWorkerRegistration.register();

reportWebVitals();
