import React, {useEffect, useState} from "react";
import {SVG} from "@svgdotjs/svg.js";
import axios from "axios";
import numbers from "../../achievement/numbers";
import Spinner from "../../../../components/Spinner";
import PropTypes from "prop-types";

const Template = ({ index, currentIndex, sizing, template, number, setSvg }) => {

    const [backgroundUrl, setBackgroundUrl] = useState('')
    const [loadingTemplate, setLoadingTemplate] = useState(true)
    const [svgElement, setSvgElement] = useState(null)

    const handleBadgeOnLoad = () => {
        setLoadingTemplate(false)
    }

    useEffect(() => {
        if(currentIndex === index) {
            setSvg(svgElement)
        }
    }, [currentIndex, index, setSvg, svgElement]);

    useEffect(() => {
        if(number !== null) {
            // setLoading(true)
            axios.get(template).then(response => {

                const s = SVG(response.data)
                // const badge = s.findOne('#achievement')

                const digits = Array.from(String(number), String);

                if (digits.length > 1) {
                    digits.forEach((digit, index) => {
                        const path = s.findOne(`#number${index + 1}`);
                        path.attr({ d: numbers[`${digit}`] });
                    });
                } else if (digits.length === 1) {
                    const path = s.findOne(`#number`);
                    path.attr({ d: numbers[`${digits[0]}`] });
                }

                const svg = new Blob([s.node.outerHTML], { type: "image/svg+xml" });
                const url = URL.createObjectURL(svg);
                setBackgroundUrl(url)

                setSvgElement(s)
            })
        }

        return () => {
            setBackgroundUrl('')
        }
    }, [number]);

    return (
        <>
            {loadingTemplate && (
                <div
                    className={"flex items-center justify-center"}
                    style={{width: sizing.width, height: sizing.height}}
                >
                    <Spinner size="lg" />
                </div>
            )}
            {backgroundUrl !== '' && <img
                alt="Template"
                src={backgroundUrl}
                onLoad={handleBadgeOnLoad}
                className={`absolute top-0 left-0 z-50`}
                style={{width: sizing.width, height: sizing.height}}
            />}
        </>
    )
}

Template.propTypes = {
    index: PropTypes.number,
    currentIndex: PropTypes.number,
    sizing: PropTypes.object,
    template: PropTypes.string,
    number: PropTypes.number,
    setSvg: PropTypes.func
}

export default Template