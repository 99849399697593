import React from "react";
import {CookieConsent} from "react-cookie-consent";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

const CookieConsentMessage = () => {
    return (
        <div className={"text-sm"}>
            <CookieConsent
                buttonText="Okay"
                disableStyles
                buttonClasses={"btn rounded-full shadow-brand-button btn-xs btn-primary text-xs"}
                contentClasses={"pb-3"}
                expires={150}
                onAccept={() => {
                    toast.dismiss('cookieConsent');
                }}
            >
                We use cookies to improve your experience and for marketing.
                Read our
                <Link className={"pl-1 inline text-blue-500 after:content-['_↗']"}
                      to={'/cookies-policy'}
                      target="_blank"
                      rel="noopener noreferrer"
                >
                    cookie policy
                </Link>.
            </CookieConsent>
        </div>

    )
}

export default CookieConsentMessage
