import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Emoji from "../../components/Emoji";
import { v4 as uuidv4 } from 'uuid';

const ParticipantCard = ({ participant }) => {
    return (
        <div className={"card shadow-brand-button rounded-none border-2 w-full card-compact"}>
            <div className={"card-body border-b-2 bg-brandYellow"}>
                <div className={"flex flex-row flex-wrap justify-between"}>
                    <div className={"card-title text-lg"}>
                        {participant.user.profile.instagram || 'Name unknown'}
                    </div>
                    <div className={"space-x-1 text-lg"}>
                        <span>{participant.balance}</span>
                        <div className={"inline"}>
                            <Emoji symbol={'🏆'} />
                        </div>
                    </div>

                </div>

                <div className={"flex flex-row justify-start items-start gap-4 sm:gap-1 flex-wrap"}>
                    {participant.actions.map((action) => {
                        return (
                            <div key={uuidv4()} className={"flex flex-col items-center justify-center"}>
                                <span>{action.displayName}</span>
                                {action.completed ? (
                                    action.status === 'success' || action.status === undefined?
                                        <Emoji symbol={'✅'} /> :
                                        (
                                            <div className={"flex flex-row gap-1"}>
                                                {action.progress.completed} / {action.progress.available}
                                                <Emoji symbol={'🚫'} />
                                            </div>
                                        )
                                ): (
                                    <span>
                                        {action.progress !== null && !action.completed ? (
                                            <>
                                                {participant.targetIsCompleted || participant.targetIsCompleted === undefined ? (
                                                    <>
                                                        {action.progress.completed} / {action.progress.available}
                                                    </>
                                                ) : (
                                                    <Emoji symbol={'🕒'} />
                                                )}
                                            </>
                                        ) : (
                                            <Emoji symbol={'🕒'} />
                                        )}
                                    </span>

                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={`card-actions flex flex-row justify-center ${participant.completed ? 'bg-brandGreen' : 'bg-white'}`}>
                {participant.completed ? (
                        <>
                            {participant.status === 'success' ? (
                                <div className={"flex flex-row justify-center items-center space-x-1 text-sm p-1"}>
                                    <div className={"text-xl"}>
                                        <Emoji symbol={'🎉'} />
                                    </div>
                                    <span className={"text-semibold"}>{`Challenge completed, you've earned ${participant.balance}`}</span>
                                    <Emoji symbol={'🏆'} />
                                </div>
                            ) : (
                                <div className={"flex flex-row justify-center items-center space-x-1 text-sm p-1"}>
                                    <div className={"text-xl"}>
                                        <Emoji symbol={'😞'} />
                                    </div>
                                    <span className={"text-semibold"}>Challenge not completed, please try again</span>
                                </div>
                            )}
                        </>

                ) : (
                    <div className={"flex flex-row justify-center items-center space-x-1 text-sm p-2"}>
                        <span className={"text-semibold"}>Current results {participant.balance}</span>
                        <Emoji symbol={'🏆'} />
                    </div>
                )}
            </div>
        </div>

    )
}

ParticipantCard.propTypes = {
    participant: PropTypes.object
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantCard)