import {
  SET_ENABLING_NOTIFICATION_SUBSCRIPTION,
  CREATE_FCM_REGISTRATION_REQUEST,
  CREATE_FCM_REGISTRATION_SUCCESS,
  CREATE_FCM_REGISTRATION_ERROR,
  DELETE_FCM_REGISTRATION_REQUEST,
  DELETE_FCM_REGISTRATION_SUCCESS,
  DELETE_FCM_REGISTRATION_ERROR,
  CHANGE_NOTIFICATION_SUBSCRIPTION_ERROR,
  CHANGE_NOTIFICATION_SUBSCRIPTION_REQUEST,
  CHANGE_NOTIFICATION_SUBSCRIPTION_SUCCESS,
  GET_NOTIFICATION_SETTINGS_SUCCESS,
  GET_NOTIFICATION_SETTINGS_REQUEST,
  GET_NOTIFICATION_SETTINGS_ERROR
} from "../actions/notification-actions";

const initialState = {
  notificationSettings: [],
  creatingFMCRegistration: false,
  deletingFMCRegistration: false,
  enablingNotificationSubscription: false,
  loadingNotificationSettings: false
};

export default function notificationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case SET_ENABLING_NOTIFICATION_SUBSCRIPTION:
      return {
        ...state,
        enablingNotificationSubscription: payload
      }

    case CREATE_FCM_REGISTRATION_REQUEST:
      return {
        ...state,
        enablingNotificationSubscription: true
      }

    case CREATE_FCM_REGISTRATION_SUCCESS:
      return {
        ...state,
        enablingNotificationSubscription: false,
      }

    case CREATE_FCM_REGISTRATION_ERROR:
      // TODO: ADD NOTIFICATION ABOUT ERROR
      return {
        ...state,
        enablingNotificationSubscription: false
      }

    case DELETE_FCM_REGISTRATION_REQUEST:
      return {
        ...state,
        enablingNotificationSubscription: true
      }

    case DELETE_FCM_REGISTRATION_SUCCESS:
      return {
        ...state,
        // notificationSettings: state.notificationSettings.map(settings => {
        //   if (settings.channel === 'push') {
        //     return {
        //       ...settings,
        //       enabled: false
        //     };
        //   }
        //   return settings;
        // }),
        enablingNotificationSubscription: false
      }

    case DELETE_FCM_REGISTRATION_ERROR:
      // TODO: ADD NOTIFICATION ABOUT ERROR
      return {
        ...state,
        enablingNotificationSubscription: false
      }

    case CHANGE_NOTIFICATION_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        enablingNotificationSubscription: true
      }

    case CHANGE_NOTIFICATION_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        notificationSettings: state.notificationSettings.length === 0 ? [payload] : state.notificationSettings.some(settings=> settings.id === payload.id)
            ? state.notificationSettings.map(settings => settings.id === payload.id ? payload : settings)
            : [...state.notificationSettings, payload],
        enablingNotificationSubscription: false
      }

    case CHANGE_NOTIFICATION_SUBSCRIPTION_ERROR:
      return {
        ...state,
        enablingNotificationSubscription: false
      }

    case GET_NOTIFICATION_SETTINGS_REQUEST:
      return {
        ...state,
        loadingNotificationSettings: true,
      }

    case GET_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingNotificationSettings: false,
        notificationSettings: payload
      }

    case GET_NOTIFICATION_SETTINGS_ERROR:
      return {
        ...state,
        loadingNotificationSettings: false,
      }


    default:
      return state;
  }
}
