import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Text = ({ children }) => {
    return <p className={"text-sm"}>{children}</p>
}

Text.propTypes = {
    children: PropTypes.any
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Text)