import React from "react";
import PropTypes from "prop-types";

const Spinner = ({ size }) => {

    return (
        <span className={`loading loading-spinner loading-${size ? size : 'xs'}`}></span>
    )
}

Spinner.propTypes = {
    size: PropTypes.string
}

export default Spinner
