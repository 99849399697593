export const GET_ACHIEVEMENTS = "GET_ACHIEVEMENTS"
export const GET_ACHIEVEMENTS_REQUEST = "GET_ACHIEVEMENTS_REQUEST"
export const GET_ACHIEVEMENTS_SUCCESS = "GET_ACHIEVEMENTS_SUCCESS"
export const GET_ACHIEVEMENTS_ERROR = "GET_ACHIEVEMENTS_ERROR"
export const SET_ACHIEVEMENT_DETAIL = "SET_ACHIEVEMENT_DETAIL"
export const SET_CURRENT_ACHIEVEMENT = "SET_CURRENT_ACHIEVEMENT"
export const SET_NEW_USER_ACHIEVEMENTS_COUNT = "SET_NEW_USER_ACHIEVEMENTS_COUNT"
export const BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN = "BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN"
export const BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_REQUEST = "BULK_UPDATE_USER_ACHIEVEMENTS_REQUEST"
export const BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_SUCCESS = "BULK_UPDATE_USER_ACHIEVEMENTS_SUCCESS"
export const BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_ERROR = "BULK_UPDATE_USER_ACHIEVEMENTS_ERROR"
export const SET_NEED_UPDATE_ACHIEVEMENTS = "SET_NEED_UPDATE_ACHIEVEMENTS"
export const RESET_ACHIEVEMENTS_DATA = "RESET_ACHIEVEMENTS_DATA"