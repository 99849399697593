import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Emoji from "../../components/Emoji";
import ParticipantCard from "./ParticipantCard";
import OwnedChallengeTitle from "./OwnedChallengeTitle";
import RewardTile from "./RewardTile";
import ChallengeReward from "./ChallengeReward";
import ChallengeDetails from "./ChallengeDetails";

import {
    CLOSE_CHALLENGE_DETAILS_DIALOG,
    SEND_CHALLENGE_ACTION
} from "../../../redux/actions/challenge-actions";


const Index = ({
                                        userChallenge,
                                        closeDialog,
                                        sendChallengeAction
}) => {

    const [tab, setTab] = useState('participant')
    const [rewardsToRedeemCount, setRewardsToRedeemCount] = useState(0)
    const [participants, setParticipants] = useState([])

    useEffect(()=>{

        const displayName = (code) => {
            const words = code.split("_");
            return words.map((word, index) => {
                if (index === 0) {
                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                } else {
                    return word.toLowerCase();
                }
            }).join(" ");
        }

        const formattedUserActions = (participant) => {

            let userActions = []

            if(userChallenge.referral !== null){
                userActions = userChallenge.referral.responses.find(response => response.user === participant.user.uid)['actions']

                userActions = userActions.map(action => {
                    return {
                        code: action,
                        completed: true,
                        progress: null,
                    }
                })
            }

            const userChallengeActions = userChallenge.participantsActions.filter(action => action.user === participant.user.uid)

            if(userChallengeActions.length > 0) {
                userChallengeActions.forEach((userAction) => {
                    const action = userChallenge.challenge.actions.find(challengeAction => challengeAction.id === userAction.action)
                    userActions.push({
                        code: action.type.code,
                        completed: userAction.completed,
                        status: userAction.status,
                        progress: {available: action.availableCount, completed: userAction.usageCount}
                    })
                })
            }

            const formattedActions = userActions.map(action => {
                action['displayName'] = displayName(action.code)
                return action
            })

            if(userChallenge.challenge.targetAction !== null) {
                if(formattedActions.find(action => action.code === userChallenge.challenge.targetAction.code) === undefined) {
                    formattedActions.splice(1, 0, {
                        code: userChallenge.challenge.targetAction.code,
                        completed: false,
                        progress: null,
                        displayName: displayName(userChallenge.challenge.targetAction.code),
                    });
                    participant['isTargetCompleted'] = false
                } else {
                    participant['isTargetCompleted'] = true
                }
            }

            return formattedActions
        }


        if(userChallenge){

            let formattedParticipants = [...userChallenge.participants]

            formattedParticipants = formattedParticipants.map(participant => {
                const userBalance = userChallenge.balances.find(balance => balance.user === participant.user.uid && !balance.isOwner)
                participant['actions'] = formattedUserActions(participant)
                participant['balance'] = userBalance.points

                return participant
            })

            setParticipants(formattedParticipants)
        }

    }, [userChallenge])


    useEffect(()=>{
        if(userChallenge){
            setRewardsToRedeemCount(userChallenge.ownerRewards.filter(reward => reward.status === 'top_up').length);
            userChallenge.ownerRewards.sort((a, b) => {
                if (a.total < b.total) {
                    return -1; // a должно быть перед b
                }
                if (a.total > b.total) {
                    return 1; // b должно быть перед a
                }
                return 0; // порядок не важен
            })
        }

    }, [userChallenge])

    return (
        <React.Fragment>
            <>
                {userChallenge != null ? (
                    <div className="flex flex-col h-full text-sm">
                        <header>
                            <button
                                className="btn btn-xs btn-circle btn-primary absolute right-4 top-4"
                                onClick={closeDialog}
                            >
                                ✕
                            </button>
                            <div className={"flex flex-col space-y-4"}>
                                <OwnedChallengeTitle userChallenge={userChallenge} />
                                <div className="tabs tabs-boxed">
                                    <button
                                        onClick={() => setTab('participant')}
                                        className={`tab ${ tab === 'participant' ? 'tab-active' : '' } text-xs`}
                                    >
                                        {userChallenge.challenge.participantType === 'owner_only' ? 'You progress' : 'Participants progress'}
                                    </button>
                                    <button
                                        onClick={() => setTab('rewards')}
                                        className={`tab ${ tab === 'rewards' ? 'tab-active' : '' } text-xs`}
                                    >
                                        Rewards
                                        {rewardsToRedeemCount > 0 && <div className="ml-1 badge badge-success text-white">{rewardsToRedeemCount}</div>}

                                    </button>
                                    <button
                                        onClick={() => setTab('details')}
                                        className={`tab ${ tab === 'details' ? 'tab-active' : '' } text-xs`}
                                    >
                                        Challenge
                                    </button>
                                </div>
                            </div>
                        </header>
                        <main className="flex-grow overflow-y-scroll overscroll-contain pt-2 pb-10">
                            {tab === 'participant' && (
                                <>
                                    {participants.length === 0 ? (
                                        <div className={"flex flex-row justify-center items-center pt-28"}>No one has accepted your invitation yet. <Emoji className={"inline"} symbol={'🤔'}/></div>
                                    ) : (
                                        <>
                                            {participants.map((participant) => {
                                                if(participant.balance !== null && participant.actions) {
                                                    return (
                                                        <div key={participant.user.uid} className={"py-2"}>
                                                            <ParticipantCard
                                                                key={participant.id}
                                                                participant={participant}
                                                            />
                                                        </div>
                                                    )
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </>
                                    )}
                                </>
                            )}
                            {tab === 'rewards' && userChallenge.challenge.reward && (
                                <>
                                    <p className={"font-semibold pt-3"}>Reward</p>
                                    <div className={"pb-8"}>
                                        <ChallengeReward
                                            challengeScore={userChallenge.challenge.score}
                                            reward={userChallenge.challenge.reward.rewards}
                                            rewardType={userChallenge.challenge.reward.type}
                                            isRepeatable={userChallenge.challenge.isRepeatable}
                                        />
                                    </div>
                                    {userChallenge.ownerRewards.length === 0 ? (
                                        <div className={"flex flex-row justify-center items-center pt-28"}>{"You don't have any rewards yet."} <Emoji className={"inline"} symbol={'🤔'}/></div>
                                    ) : (
                                        <div className={"divide-y"}>
                                            {userChallenge.ownerRewards.map(reward => {
                                                return <RewardTile
                                                    key={reward.id}
                                                    ownerReward={reward}
                                                    challengeReward={userChallenge.challenge.reward}
                                                    sendChallengeAction={sendChallengeAction}
                                                />
                                            })}
                                        </div>
                                    )}

                                </>
                            )}
                            {tab === 'details' && userChallenge.challenge && (
                                <ChallengeDetails challenge={userChallenge.challenge} />
                            )}
                        </main>
                        <footer className="pt-4 border-t-2 border-t-brandDark-100">
                            <div className={`flex flex-row flex-wrap justify-end`}>
                                <button
                                    onClick={closeDialog}
                                    className={`btn rounded-full shadow-brand-button btn-primary`}
                                >
                                    Close
                                </button>
                            </div>
                        </footer>
                    </div>
                ) : null }
            </>
        </React.Fragment>
    );
};

Index.propTypes = {
    userChallenge: PropTypes.object,
    showDetailsDialog: PropTypes.string,
    closeDialog: PropTypes.func,
    sendChallengeAction: PropTypes.func
}

const mapStateToProps = (state) => ({
    showDetailsDialog: state.challenge.showDetailsDialog,
})

const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch({ type: CLOSE_CHALLENGE_DETAILS_DIALOG }),
    sendChallengeAction: (params) => dispatch({ type: SEND_CHALLENGE_ACTION, payload: params })
})

export default connect(mapStateToProps, mapDispatchToProps)(Index)