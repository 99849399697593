import React from 'react'
import {connect} from "react-redux";

const Skeleton = () => {

    return (
        <div className="w-full card bg-dark min-h-32">
            <div className={"animate-pulse  flex flex-col justify-between px-3 py-3 text-white space-y-2 min-h-32"}>
                <div className={"flex flex-row justify-between items-end pb-1"}>
                    <span className="text-base font-normal">
                        Stats
                    </span>
                    <div
                        className={"flex flex-row items-center space-x-2"}
                    >
                        <div className="w-14 h-4 bg-slate-400 col-span-2 rounded"></div>
                        <div className="w-6 h-6 bg-slate-400 col-span-2 rounded"></div>
                    </div>
                </div>
                <div className={"flex flex-row space-x-4 items-center text-white"}>
                    <div className={"bg-slate-400 flex flex-col justify-center h-16 w-20 rounded"}></div>
                    <div className={"bg-slate-400 flex flex-col justify-center h-16 w-20 rounded"}></div>
                    <div className={"bg-slate-400 flex flex-col justify-center h-16 w-20 rounded"}></div>
                </div>
            </div>
        </div>
    )
}

Skeleton.propTypes = {}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Skeleton)