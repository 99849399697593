import React, {useEffect} from "react";
import { connect } from "react-redux";
import {GET_USER_STATS, RESET_USER_STATS} from "../../../redux/actions/user-actions";
import PropTypes from "prop-types";
import Stat from "../components/Stat";
import { v4 as uuidv4 } from 'uuid';
import Spinner from "../../components/Spinner";

const Stats = ({ userUid, getUserStats, userStats, userStatsLoading, resetStats }) => {

    useEffect(()=>{
        getUserStats(userUid)

        return () => {
            resetStats()
        }

    }, [])

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <React.Fragment>
            {userStatsLoading ? (
                <div className={'flex flex-row flex-1 w-full justify-center items-center'}>
                    <Spinner size={"lg"} />
                </div>
            ) : (
                <div className={"px-5 space-y-4"}>
                    {userStats && userStats.categories.map(category=>{
                        return (
                            <div key={uuidv4()}>
                                <div className={"text-primary font-semibold pb-3"}>{capitalizeFirstLetter(category)}</div>
                                <div className="stats stats-vertical shadow-brand-button rounded-none w-full">
                                    {userStats.stats.filter(stat=>stat.category === category).map(stat=>{
                                        return <Stat
                                            key={uuidv4()}
                                            value={stat.value}
                                            title={stat.title}
                                            type={stat.type}
                                            symbol={stat.symbol}
                                            description={stat.description}
                                        />
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}

        </React.Fragment>
    )
}

Stats.propTypes = {
    userStatsLoading: PropTypes.bool,
    userUid: PropTypes.string,
    userStats: PropTypes.object,
    getUserStats: PropTypes.func,
    resetStats: PropTypes.func
}

const mapStateToProps = (state) => ({
    userStats: state.user.userStats,
    userStatsLoading: state.user.userStatsLoading
})

const mapDispatchToProps = (dispatch) => ({
    getUserStats: (userUid) => dispatch({ type: GET_USER_STATS, payload: userUid }),
    resetStats: () => dispatch({ type: RESET_USER_STATS })
})

export default connect(mapStateToProps, mapDispatchToProps)(Stats)
