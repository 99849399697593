import { toast } from "react-toastify";
import React from "react";

import ToastMessage from "../../screens/components/toast";
import {
    BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_ERROR,
    BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_REQUEST,
    BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_SUCCESS,
    GET_ACHIEVEMENTS_ERROR,
    GET_ACHIEVEMENTS_REQUEST,
    GET_ACHIEVEMENTS_SUCCESS, RESET_ACHIEVEMENTS_DATA,
    SET_ACHIEVEMENT_DETAIL,
    SET_CURRENT_ACHIEVEMENT, SET_NEED_UPDATE_ACHIEVEMENTS,
    SET_NEW_USER_ACHIEVEMENTS_COUNT,
} from "../actions/achievement-actions";

import {
    generateAchievements,
} from "../../screens/challenges/achievements/helper";

const initialState = {
    userAchievements: null,
    achievementDetail: null,
    currentAchievement: null,
    achievementWasShown: null,
    newUserAchievementsCount: 0,
    loadingAchievements: false,
    achievementDetailModalIsOpen: false,
    shareAchievementModalIsOpen: false,
    needUpdateAchievements: false
};

export default function achievementReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case GET_ACHIEVEMENTS_REQUEST:
            return {
                ...state,
                loadingAchievements: true
            }

        case GET_ACHIEVEMENTS_SUCCESS:

            return {
                ...state,
                loadingAchievements: false,
                userAchievements: generateAchievements(payload),
            }

        case GET_ACHIEVEMENTS_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get achievements."} withSupportButton={true} withTryAgain={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingAchievements: false,
            }

        case SET_ACHIEVEMENT_DETAIL:
            return {
                ...state,
                achievementDetailModalIsOpen: payload.modalIsOpen,
                achievementDetail: payload.detail,
            }

        case SET_CURRENT_ACHIEVEMENT:
            return {
                ...state,
                currentAchievement: payload.achievement,
                shareAchievementModalIsOpen: payload.modalIsOpen
            }

        case SET_NEW_USER_ACHIEVEMENTS_COUNT:
            return {
                ...state,
                newUserAchievementsCount: payload
            }

        case BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_REQUEST:
            return {
                ...state,
                needUpdateAchievements: false,
            }

        case BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_SUCCESS:
            return {
                ...state,
                needUpdateAchievements: true,
                newUserAchievementsCount: payload.newUserAchievementsCount,
                achievementWasShown: payload.achievementId
            }

        case BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_ERROR:
            return {
                ...state,
                needUpdateAchievements: false,
                achievementWasShown: null
            }

        case SET_NEED_UPDATE_ACHIEVEMENTS:
            return {
                ...state,
                needUpdateAchievements: payload,
                achievementWasShown: null
            }

        case RESET_ACHIEVEMENTS_DATA:
            return {
                ...state,
                userAchievements: initialState.userAchievements,
                achievementDetail: initialState.achievementDetail,
                currentAchievement: initialState.currentAchievement,
                achievementWasShown: initialState.achievementWasShown,
                loadingAchievements: initialState.loadingAchievements,
                achievementDetailModalIsOpen: initialState.achievementDetailModalIsOpen,
                shareAchievementModalIsOpen: initialState.shareAchievementModalIsOpen,
                needUpdateAchievements: initialState.needUpdateAchievements
            }

        default:
            return state;
    }
}
