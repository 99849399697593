export const posterWidth = 1080
export const posterHeight = 1920

const stackCount = 3;

function getFakeChallengeUserAchievement(achievement) {
    return {
        "user": null,
        "achievement": achievement,
        "createdAt": null,
        "status": "close",
        "wasShown": false,
        "target": {
            "user": null,
            "reward": null,
            "challenge": achievement.targetObjectId,
            "total": achievement.target.score,
            "metadata": null,
            "status": null
        },
        "metadata": {
            "challenge": {
                "id": achievement.targetObjectId,
                "score": achievement.target.score,
                "title": achievement.target.title,
                "description": achievement.target.description,
                "isRepeatable": achievement.target.isRepeatable,
                "userChallenge": {
                    "createdAt": null
                }
            }
        },
        "result": null,
        "isFake": true
    };
}

function getFakeTriggerUserAchievement(achievement) {
    return {
        "user": null,
        "achievement": achievement,
        "createdAt": null,
        "status": "close",
        "wasShown": false,
        "target": {
            "user": null,
            "achievement": achievement.id,
            "targetContentType": null,
            "targetObjectId": null,
            "metadata": {
                "result": 1,
                "homework": null
            }
        },
        "metadata": {
            "result": 1,
            "homework": null
        },
        "result": 1
    };
}

function addFakeElements(element, additionalCount) {
    let fakeList = [];
    let lastUserAchievement = null

    if (additionalCount !== stackCount) {
        fakeList = [...element.userAchievement];
        lastUserAchievement = element.userAchievement.slice(-1)[0]

    }

    for (let i = 0; i < additionalCount; i++) {
        let previewsUserAchievement = null;
        let fakeUserAchievement = {};

        if (additionalCount !== stackCount) {
            if(i === 0) {
                previewsUserAchievement = lastUserAchievement
            } else {
                previewsUserAchievement = fakeList.slice(-1)[0];
            }

        } else {
            if (i > 0) {
                previewsUserAchievement = fakeList.slice(-1)[0];
            }
        }

        if (element.type === 'challenge') {
            fakeUserAchievement = JSON.parse(JSON.stringify(getFakeChallengeUserAchievement(element)));
        } else if (element.type === 'trigger') {
            fakeUserAchievement = JSON.parse(JSON.stringify(getFakeTriggerUserAchievement(element)));
        }

        if (previewsUserAchievement !== null) {
            if (element.type === 'challenge' && element.target.isRepeatable) {
                fakeUserAchievement.target.total = previewsUserAchievement.target.total + previewsUserAchievement.metadata.challenge.score;
            } else if (element.type === 'trigger') {
                let delta
                if(additionalCount < stackCount) {
                    delta = (additionalCount === 2 && i === 0) ? element.number - 1 : element.number;
                } else {
                    delta = (i === 1) ? element.number - 1 : element.number;
                }
                fakeUserAchievement.target.metadata.result = previewsUserAchievement.target.metadata.result + delta;
            }
        }

        fakeList.push(fakeUserAchievement);
    }

    element.userAchievement = fakeList;
    return element;
}

function getChallengeElement(achievement) {
    const element = { ...achievement };

    if (element.userAchievement === null) {
        element.isStack = false;
        element.userAchievement = [];
        addFakeElements(element, stackCount);
    } else {
        const userAchievementCount = element.userAchievement.length;
        element.isStack = userAchievementCount > stackCount;

        if (!element.isStack) {
            const additionalCount = stackCount - userAchievementCount;
            element.userAchievement.sort((a, b) => a.target.total - b.target.total);
            if (additionalCount > 0) {
                addFakeElements(element, additionalCount);
            }
        } else {
            element.userAchievement.sort((a, b) => b.target.total - a.target.total);
        }
    }

    element.userAchievement.forEach(userAchievement => {
        userAchievement.result = (userAchievement.target.total / userAchievement.metadata.challenge.score) * element.number;
    });

    return element;
}

function getTriggerElement(achievement) {
    let element = { ...achievement };

    if (element.userAchievement === null) {
        element.isStack = false;
        element.userAchievement = [];
        element = addFakeElements(element, stackCount);
    } else {
        const userAchievementCount = element.userAchievement.length;
        element.isStack = userAchievementCount > stackCount;

        if (!element.isStack) {
            const additionalCount = stackCount - userAchievementCount;
            element.userAchievement.sort((a, b) => a.target.metadata.result - b.target.metadata.result);
            element = addFakeElements(element, additionalCount);
        } else {
            element.userAchievement.sort((a, b) => b.target.metadata.result - a.target.metadata.result);
        }
    }

    element.userAchievement.forEach(userAchievement => {
        userAchievement.result = userAchievement.target.metadata.result;
    });

    return element;
}

export function generateAchievements(achievements) {
    const achievementsList = [];
    achievements.forEach(achievement => {
        if (achievement.type === 'challenge') {
            const element = getChallengeElement(achievement);
            achievementsList.push(element);
        } else if (achievement.type === 'trigger') {
            const element = getTriggerElement(achievement);
            achievementsList.push(element);
        }
    });

    return achievementsList;
}