import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../components/Spinner";
import {GET_ALL_PAYMENT_METHODS} from "../../redux/actions/payment-method-actions";
import PaymentMethodCard from "./paymentMethod/PaymentMethodCard";
import {GoPlus} from "react-icons/go";
import AddPaymentMethodForm from "./paymentMethod/AddPaymentMethod";
import paymentApi from "../../redux/api/payment-api";

const PaymentMethodList = ({ paymentMethods, getPaymentMethodsLoading, getAllPaymentMethods, userInfo, userSubscription }) => {

    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null)
    const [setupIntentSecret, setSetupIntentSecret] = useState(null)
    const [showDialog, setShowDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    const createSetupIntent = () => {
        setLoading(true)
        paymentApi.createSetupIntent({
            customerId: userSubscription.customer,
        }).then(response => {
            if(response.status === 200){
                setSetupIntentSecret(response.data.clientSecret)
                setShowDialog(true)
            } else {

                // ОБРАБОТКА ОШИБКИ
            }
            setLoading(false)
        })
    }

    useEffect(()=>{
        if(userSubscription.subscription){
            if(userSubscription.subscription.object === 'subscription_schedule') {
                setDefaultPaymentMethod(userSubscription.subscription.defaultSettings.defaultPaymentMethod)
            } else if (userSubscription.subscription.object === 'subscription'){
                setDefaultPaymentMethod(userSubscription.subscription.defaultPaymentMethod)
            }

            getAllPaymentMethods(userInfo.uid)
        }
    }, [userSubscription])

    return (
        <React.Fragment>
            {paymentMethods.length > 0 && userSubscription.subscription ? (
                <div className={"bg-white px-3 shadow-brand-button py-5"}>
                    <h3 className="font-bold text-lg mt-5 text-primary">Payment method</h3>
                    <div className={"divider p-0 m-0"} />
                    <ul className="w-full rounded-lg mt-4 space-y-5">
                        {getPaymentMethodsLoading ? (
                            <Spinner />
                        ) : (
                            // Auto-assigning unique keys
                            React.Children.toArray(
                                paymentMethods.map((method, index) => {
                                    const isDefault = method.id === defaultPaymentMethod
                                    return <PaymentMethodCard index={index} paymentMethod={method} isDefault={isDefault} />
                                })
                            )
                        )}
                    </ul>
                    {!getPaymentMethodsLoading && paymentMethods.length > 0 && (
                        <div className={"py-5"}>
                            <div
                                onClick={createSetupIntent}
                                className={"flex flex-row justify-start items-center space-x-3 cursor-pointer"}
                            >
                                {loading ?  <Spinner className={"w-4 h-4"} /> : <GoPlus className={"prose w-4 h-4"}/>}
                                <span className={"prose uppercase"}>Add payment method</span>


                            </div>
                            {showDialog && (
                                <AddPaymentMethodForm
                                    showDialog={showDialog}
                                    setShowDialog={setShowDialog}
                                    setupIntentSecret={setupIntentSecret}
                                />
                            )}
                        </div>
                    )}

                </div>
            ) : null}

        </React.Fragment>
    )
}

PaymentMethodList.propTypes = {
    userInfo: PropTypes.object,
    paymentMethods: PropTypes.array,
    getPaymentMethodsLoading: PropTypes.bool,
    userSubscription: PropTypes.object,
    getAllPaymentMethods: PropTypes.func
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    paymentMethods: state.paymentMethod.paymentMethods,
    userSubscription: state.billing.userSubscription,
    getPaymentMethodsLoading: state.paymentMethod.getPaymentMethodsLoading
})

const mapDispatchToProps = (dispatch) => ({
    getAllPaymentMethods: (userUid) => dispatch({ type: GET_ALL_PAYMENT_METHODS, payload: userUid })
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodList)
