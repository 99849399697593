import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects'

import {
    GET_USER_INFO,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_REQUEST,
    GET_USER_INFO_SUCCESS,
    GET_USER_STATS,
    GET_USER_STATS_ERROR,
    GET_USER_STATS_REQUEST,
    GET_USER_STATS_SUCCESS,
    UPDATE_STUDENT_PROFILE,
    UPDATE_STUDENT_PROFILE_ERROR,
    UPDATE_STUDENT_PROFILE_REQUEST,
    UPDATE_STUDENT_PROFILE_SUCCESS
} from '../actions/user-actions'

import userApi from '../api/user-api'

function* getUserInfo({ payload }) {

    yield put({ type: GET_USER_INFO_REQUEST })

    let response = yield call(userApi.getUserInfo, payload)

    if ( response.status === 200 ) {
        yield put({ type: GET_USER_INFO_SUCCESS, payload: response.data })
    } else if( response.status === 400 ){
        yield put({ type: GET_USER_INFO_ERROR, payload: response.status })
    } else {
        yield put({ type: GET_USER_INFO_ERROR, payload: response.status })
    }
}

function* getUserStats({ payload }) {

    yield put({ type: GET_USER_STATS_REQUEST })

    let response = yield call(userApi.getUserStats, payload)

    if ( response.status === 200 ) {
        yield put({ type: GET_USER_STATS_SUCCESS, payload: response.data })
    } else if( response.status === 400 ){
        yield put({ type: GET_USER_STATS_SUCCESS, payload: null })
    } else {
        yield put({ type: GET_USER_STATS_ERROR, payload: response.status })
    }
}

function* updateStudentProfile({ payload }){

    yield put({ type: UPDATE_STUDENT_PROFILE_REQUEST })

    const response = yield call(userApi.updateUserProfile, payload)

    if (response.status === 200) {
        yield put({ type: UPDATE_STUDENT_PROFILE_SUCCESS, payload: response.data })
    }
    if(response.status === 400 || response.status > 401){
        yield put({ type: UPDATE_STUDENT_PROFILE_ERROR, payload: response.status } )
    }
}

export default function* userSaga() {
    yield takeLatest(GET_USER_INFO, getUserInfo)
    yield takeLatest(GET_USER_STATS, getUserStats)
    yield takeLatest(UPDATE_STUDENT_PROFILE, updateStudentProfile)
}
