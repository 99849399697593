import React, {useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {toast} from "react-toastify";

import validator from "validator";

import ToastMessage from "../components/toast";

import {useAuth} from "../../context/AuthContext";
import {withUseFormHook} from "../../helpers/with-form-hook";

import {
    CLEAN_RESET_PASSWORD_DATA,
    RESET_PASSWORD,
} from "../../redux/actions/auth-actions";

const ResetPassword = ({ form }) => {

    let [loading, setLoading] = useState(false)

    const { doResetPassword } = useAuth()

    const resetPassword = async (data) => {
        setLoading(true)
        try {
            toast.dismiss()
            await doResetPassword(data.email)
            toast.success(<ToastMessage text={"Check your email for reset password"} />)
        } catch (error) {
            switch (error.code) {
                case 'auth/user-not-found':
                    toast.error(<ToastMessage text={"User not found."} withTryAgain={true} />,
                        {autoClose: false}
                    )
                    break;
                default:
                    console.error(error)
                    toast.error(
                        <ToastMessage text={`An error occurred ${error.code}.`} withSupportButton={true} />,
                        {autoClose: false}
                    )
                    break;
            }
        }
        setLoading(false)
    }

    const { register, handleSubmit, formState: { errors } } = form;

    return (
        <React.Fragment>
            <form className="w-full space-y-4 mt-4">
                <div className="form-control mb-2">
                    <input
                        type="text"
                        placeholder="Enter your email"
                        className={`input input-bordered input-md ${errors.email && 'input-error'}`}
                        {...register("email", {
                            required: "The field should not be empty",
                            validate: (email) => {
                                return validator.isEmail(email) || "Enter email with correct format"
                            }
                        })}
                    />
                    {errors.email &&
                        <label className="label">
                            {<span className="label-text-alt text-brandRed">{errors.email.message}</span>}
                        </label>
                    }
                </div>
                <button
                    className={`py-2 px-4 w-full btn-primary btn btn-md rounded-full btn-wide shadow-brand-button`}
                    onClick={handleSubmit(resetPassword)}
                >
                    {loading && <span className="loading loading-spinner"></span>}
                    <span className="font-medium">SEND RESET LINK</span>
                </button>
            </form>
        </React.Fragment>
    )
}

ResetPassword.propTypes = {
    form: PropTypes.object,
    resetUserPassword: PropTypes.func.isRequired,
    cleanData: PropTypes.func.isRequired,
    detail: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.object
}

const mapStateToProps = (state) => ({
    error: state.auth.resetPasswordError,
    loading: state.auth.loadingResetPassword,
    detail: state.auth.resetPasswordDetail
})

const mapDispatchToProps = (dispatch) => ({
    resetUserPassword: (params) => dispatch({ type: RESET_PASSWORD, payload: params }),
    cleanData: () => dispatch({ type: CLEAN_RESET_PASSWORD_DATA }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(ResetPassword))
