import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import {MdOutlineInfo} from "react-icons/md";
import { v4 as uuidv4 } from 'uuid';
import {IoMdCloseCircleOutline} from "react-icons/io";
import {CiCircleCheck} from "react-icons/ci";
import {Link} from "react-router-dom";

const HelpDropdownIcon = ({ title, reasons, canPaid }) => {

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
    const [reasonKeys, setReasonKeys] = useState([])

    useEffect(() => {
        if(Object.keys(reasons).length > 0) {
            const keys = Object.keys(reasons);
            setReasonKeys(keys)
        }
    }, [reasons]);

    function handleDropDownState () {
        setDropdownIsOpen(!dropdownIsOpen)
        const elem = document.activeElement;
        if (elem) {
            elem?.blur();
        }
    }

    return (
        <div className={`z-40 w-full dropdown ${ dropdownIsOpen ? 'dropdown-open' : null}`}>
            <div className={"flex flex-row items-center space-x-1"}>
                <span className={"text-sm"}>{`You don't have access.`}</span>
                <div
                    tabIndex={0}
                    role={"button"}
                    className="btn btn-circle btn-xs btn-ghost text-blue border-none"
                    onClick={(event) => handleDropDownState(event)}
                >
                    <MdOutlineInfo className={"w-5 h-5"}/>
                </div>
            </div>
            <div
                tabIndex={0}
                className="card compact dropdown-content z-[1] shadow bg-base-100 rounded-box w-full"
            >
                <div
                    tabIndex={0}
                    className="card-body"
                >
                    <h2 className="card-title text-sm">{title}</h2>
                    <ul className={`pl-0 text-slate-900 dark:text-slate-200 text-xs space-y-2 `}>
                        {reasonKeys.map(reasonKey => {

                            const reasonValue = reasons[reasonKey].value
                            const reasonDescription = reasons[reasonKey].description

                            let reasonAction

                            if (reasonKey === 'hasPositiveBalance' && reasonValue === false && canPaid) {
                                reasonAction = (
                                    <span> Top up your balance
                                        <Link className={"text-blue after:content-['_↗']"} to={"/billing"}> here</Link>.
                                    </span>
                                )
                            } else if (reasonKey === 'hasActiveStatus' && reasonValue === false) {
                                reasonAction = (
                                    <span> Change your account status
                                        <Link className={"text-blue after:content-['_↗']"} to={"/account"}> here</Link>.
                                    </span>
                                )
                            } else if (reasonKey === 'completeProfile' && reasonValue === false) {
                                reasonAction = (
                                    <span> Complete your profile
                                        <Link className={"text-blue after:content-['_↗']"} to={"/account"}> here</Link>.
                                    </span>
                                )
                            }

                            if(reasonKey === 'isStudent' && reasonValue === true) {
                                return null
                            }
                            return (
                                <li key={uuidv4()} className={"flex flex-row space-x-1"}>
                                    {reasonValue === true ? (
                                        <CiCircleCheck className={"flex-none fill-green w-4 h-4"} />
                                    ) : (
                                        <IoMdCloseCircleOutline className={"flex-none fill-red-900 w-4 h-4"} />
                                    )}
                                    <span className={"hyphens-auto"}>
                                        {reasonDescription}
                                        {reasonAction}
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

HelpDropdownIcon.propTypes = {
    title: PropTypes.string,
    reasons: PropTypes.object,
    canPaid: PropTypes.bool
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(HelpDropdownIcon)