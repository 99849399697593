export const GET_CHALLENGES = "GET_CHALLENGES"
export const GET_CHALLENGES_REQUEST = "GET_CHALLENGES_REQUEST"
export const GET_CHALLENGES_SUCCESS = "GET_CHALLENGES_SUCCESS"
export const GET_CHALLENGES_ERROR = "GET_CHALLENGES_ERROR"
export const GET_USER_CHALLENGES = "GET_USER_CHALLENGES"
export const GET_USER_CHALLENGES_REQUEST = "GET_USER_CHALLENGES_REQUEST"
export const GET_USER_CHALLENGES_SUCCESS = "GET_USER_CHALLENGES_SUCCESS"
export const GET_USER_CHALLENGES_ERROR = "GET_USER_CHALLENGES_ERROR"
export const START_CHALLENGE = "START_CHALLENGE"
export const START_CHALLENGE_REQUEST = "START_CHALLENGE_REQUEST"
export const START_CHALLENGE_SUCCESS = "START_CHALLENGE_SUCCESS"
export const START_CHALLENGE_ERROR = "START_CHALLENGE_ERROR"

export const SEND_CHALLENGE_ACTION = "SEND_CHALLENGE_ACTION"

export const SEND_CHALLENGE_ACTION_REQUEST = "SEND_CHALLENGE_ACTION_REQUEST"

export const SEND_CHALLENGE_ACTION_SUCCESS = "SEND_CHALLENGE_ACTION_SUCCESS"

export const SEND_CHALLENGE_ACTION_ERROR = "SEND_CHALLENGE_ACTION_ERROR"

export const UPDATE_USER_CHALLENGE = "UPDATE_USER_CHALLENGE"

export const UPDATE_USER_CHALLENGE_REQUEST = "UPDATE_USER_CHALLENGE_REQUEST"

export const UPDATE_USER_CHALLENGE_SUCCESS = "UPDATE_USER_CHALLENGE_SUCCESS"

export const UPDATE_USER_CHALLENGE_ERROR = "UPDATE_USER_CHALLENGE_ERROR"

export const OPEN_CHALLENGE_DETAILS_DIALOG = "OPEN_CHALLENGE_DETAILS_DIALOG"

export const CLOSE_CHALLENGE_DETAILS_DIALOG = "CLOSE_CHALLENGE_DETAILS_DIALOG"

export const SET_CHALLENGES_TAB = "SET_CHALLENGES_TAB"