import {accountApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import client from "./client";
import snakecaseKeys from "snakecase-keys";

const accountClient = client(`${accountApiUrl}/api/v1`)

const getGiftCardsByUser = async (payload) => {
    try {
        const response = await accountClient.get(`/gift/?user__uid=${payload}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const redeemGiftCard = async (params) => {
    const data = snakecaseKeys(params)
    try {
        const response = await accountClient.post(`/gift/redeem/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const giftApi = {
    getGiftCardsByUser,
    redeemGiftCard,
}

export default giftApi
