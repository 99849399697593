import {useEffect} from "react";
import { connect } from "react-redux";

const Referrals = () => {
    useEffect(()=>{
        window.location.replace('https://bluebabushka.com')
    },[])
    return null
}

Referrals.propTypes = {
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Referrals)