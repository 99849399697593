import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
// import Pluralize from "react-pluralize";

import Emoji from "../../components/Emoji";
import {usePrevious} from "../../../hooks/usePrevious";
import pluralize from "pluralize";

const RewardTile = ({
                        ownerReward,
                        challengeReward,
                        sendingChallengeAction,
                        sendChallengeAction,
                        loadingChallenges
}) => {

    const [loading, setLoading] = useState(false)
    const prevSendingChallengeActionStatus = usePrevious(sendingChallengeAction)
    const prevLoadingChallengesState = usePrevious(loadingChallenges)

    function redeem(event, ownerRewardId){
        event.preventDefault()

        setLoading(true)

        sendChallengeAction({
            actionCode: 'REDEEM_OWNER_REWARD',
            actionCodeType: 'TOP_UP',
            ownerRewardId: ownerRewardId
        })
    }

    useEffect(()=>{
        const actionSent = prevSendingChallengeActionStatus === true && sendingChallengeAction === false
        const challengesUpdated = prevLoadingChallengesState === true && loadingChallenges === false

        if(actionSent && challengesUpdated) {
            setLoading(false)
        }
    }, [loadingChallenges, prevLoadingChallengesState, prevSendingChallengeActionStatus, sendingChallengeAction])

    return (
        <React.Fragment>
            <div className="flex justify-between items-center pl-2 py-3">
                <div className={"flex items-center justify-start space-x-4"}>
                    <div className="avatar placeholder">
                        <div className="bg-brandGreen rounded-full w-12 ring ring-brandOrange ring-offset-base-100 ring-offset-2">
                            <Emoji className={'text-3xl'} symbol={'🎉'} />
                        </div>
                    </div>
                    <div className={"flex flex-col items-start"}>
                        <span className="text-lg font-semibold">
                            {`${challengeReward.rewards} ${pluralize(challengeReward.type, challengeReward.rewards)}`}
                            {/*<Pluralize*/}
                            {/*    singular={}*/}
                            {/*    count={}*/}
                            {/*    showCount={true}*/}
                            {/*/>*/}
                        </span>
                        <span className="text-sm font-medium">{ownerReward.total} <Emoji symbol={'🏆'} /></span>
                    </div>
                </div>
                <div>
                    {ownerReward.status === 'top_up' ? (
                        <button
                            onClick={(event)=>redeem(event, ownerReward.id)}
                            className={"btn btn-primary btn-sm shadow-brand-button rounded-full"}
                        >
                            {loading && <span className="loading loading-spinner loading-xs"></span>}
                            Redeem
                        </button>
                    ) : (
                        <div className={"p-2 text-branGrayDark"}>Redeemed</div>
                    )}
                </div>

            </div>
        </React.Fragment>
    )
}

RewardTile.propTypes = {
    sendingChallengeAction: PropTypes.bool,
    ownerReward: PropTypes.object,
    challengeReward: PropTypes.object,
    sendChallengeAction: PropTypes.func,
    redeem: PropTypes.func,
    loadingChallenges: PropTypes.bool
}

const mapStateToProps = (state) => ({
    sendingChallengeAction: state.challenge.sendingChallengeAction,
    loadingChallenges: state.challenge.loadingChallenges
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(RewardTile)