import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import pluralize from "pluralize";

const GiftCard = ({
                       giftCard
}) => {

    return (
        <React.Fragment>
            <div key={giftCard.id} className={"flex flex-col space-y-5 mt-5"}>
                <div className="card card-compact bg-base-100 shadow-brand-button rounded-none w-full">
                    <div className="card-body">
                        <h2 className="card-title">
                            {giftCard.title ? giftCard.title : (
                                <span>
                                    {`${giftCard.initialUnits} ${pluralize('week', giftCard.initialUnits)}`}
                                    {/*<Pluralize singular={'week'} plural={'weeks'} count={giftCard.initialUnits} />*/}
                                    &nbsp;of English in Stories
                                </span>
                            )}
                        </h2>
                        <div className="flex flex-row flex-wrap xs:space-x-2">
                            <span>Purchaser:</span>
                            <span className={"break-all xs:break-words"}>{giftCard.purchaserName} {giftCard.purchaserEmail && `(${giftCard.purchaserEmail})`}</span>
                        </div>
                        <div className="flex flex-row flex-wrap xs:space-x-2">
                            <span>Message:</span>
                            <span className={"break-words"}>{giftCard.message}</span>
                        </div>
                        <div className="card-actions justify-between mt-3">
                            <div className="badge badge-outline">{giftCard.code}</div>
                            <div className="badge badge-outline">{giftCard.status}</div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

GiftCard.propTypes = {
    giftCard: PropTypes.object,
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(GiftCard)