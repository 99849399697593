import { toast } from "react-toastify";
import React from "react";

import ToastMessage from "../../screens/components/toast";
import {
    CLOSE_CHALLENGE_DETAILS_DIALOG,
    GET_CHALLENGES_ERROR,
    GET_CHALLENGES_REQUEST,
    GET_CHALLENGES_SUCCESS,
    GET_USER_CHALLENGES_ERROR,
    GET_USER_CHALLENGES_REQUEST,
    GET_USER_CHALLENGES_SUCCESS,
    OPEN_CHALLENGE_DETAILS_DIALOG,
    SEND_CHALLENGE_ACTION_ERROR,
    SEND_CHALLENGE_ACTION_REQUEST,
    SEND_CHALLENGE_ACTION_SUCCESS,
    SET_CHALLENGES_TAB,
    START_CHALLENGE_ERROR,
    START_CHALLENGE_REQUEST,
    START_CHALLENGE_SUCCESS,
    UPDATE_USER_CHALLENGE_ERROR,
    UPDATE_USER_CHALLENGE_REQUEST,
    UPDATE_USER_CHALLENGE_SUCCESS
} from "../actions/challenge-actions";

const initialState = {
    ownedChallenges: null,
    notOwnedChallenges: null,
    userChallenges: null,
    loadingChallenges: false,
    loadingUserChallenges: false,
    challengesNeedUpdate: false,
    loadingStartChallenge: false,
    sendingChallengeAction: false,
    challenge: null,
    showDetailsDialog: null,
    updatingUserChallenge: false,
    challengesTab: 'not_owned',
};

export default function challengeReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case GET_CHALLENGES_REQUEST:
            return {
                ...state,
                loadingChallenges: true
            }

        case GET_CHALLENGES_SUCCESS:

            return {
                ...state,
                loadingChallenges: false,
                ownedChallenges: payload.ownedChallenges,
                notOwnedChallenges: payload.notOwnedChallenges,
                challenge: state.challenge != null ? payload.ownedChallenges.find(challenge => challenge.id === state.challenge.id) : state.challenge
            }

        case GET_CHALLENGES_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get challenges."} withSupportButton={true} withTryAgain={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingChallenges: false,
            }

        case GET_USER_CHALLENGES_REQUEST:
            return {
                ...state,
                loadingUserChallenges: true
            }

        case GET_USER_CHALLENGES_SUCCESS:
            return {
                ...state,
                loadingUserChallenges: false,
                userChallenges: payload
            }

        case GET_USER_CHALLENGES_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get your challenges."} withSupportButton={true} withTryAgain={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingUserChallenges: false,
            }

        case START_CHALLENGE_REQUEST:
            return {
                ...state,
                loadingStartChallenge: true,
                challengesNeedUpdate: false
            }

        case START_CHALLENGE_SUCCESS:
            return {
                ...state,
                loadingStartChallenge: false,
                challengesNeedUpdate: true
            }

        case START_CHALLENGE_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get your challenges."} withSupportButton={true} withTryAgain={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingStartChallenge: false,
                challengesNeedUpdate: false
            }

        case SEND_CHALLENGE_ACTION_REQUEST:
            return {
                ...state,
                sendingChallengeAction: true,
                challengesNeedUpdate: false
            }

        case SEND_CHALLENGE_ACTION_SUCCESS:
             payload.message !== null && toast.info(<ToastMessage text={payload.message} />, {
                autoClose: false
            })
            return {
                ...state,
                sendingChallengeAction: false,
                challengesNeedUpdate: true
            }

        case SEND_CHALLENGE_ACTION_ERROR:
            payload.code !== 403 && toast.error(<ToastMessage text={payload.message} withSupportButton={true} withTryAgain={true} code={payload.code} />, {
                autoClose: false
            })
            return {
                ...state,
                sendingChallengeAction: false,
                challengesNeedUpdate: false
            }

        case UPDATE_USER_CHALLENGE_REQUEST:
            return  {
                ...state,
                challengesNeedUpdate: false,
                updatingUserChallenge: true
            }

        case UPDATE_USER_CHALLENGE_SUCCESS:
            return  {
                ...state,
                challengesNeedUpdate: true,
                updatingUserChallenge: false
            }

        case UPDATE_USER_CHALLENGE_ERROR:
            payload.code !== 403 && toast.error(<ToastMessage text={payload.message.detail} withSupportButton={true} withTryAgain={true} code={payload.code} />, {
                autoClose: false
            })
            return  {
                ...state,
                challengesNeedUpdate: false,
                updatingUserChallenge: false
            }

        case OPEN_CHALLENGE_DETAILS_DIALOG:
            // eslint-disable-next-line
            let challenge;

            if (payload.isOwned) {
                challenge = state.ownedChallenges.find(challenge => challenge.id === payload.challengeId)
            } else {
                challenge = state.notOwnedChallenges.find(challenge => challenge.id === payload.challengeId)
            }

            return {
                ...state,
                showDetailsDialog: payload.isOwned ? 'owned' : 'not_owned',
                challenge: challenge,
            }

        case CLOSE_CHALLENGE_DETAILS_DIALOG:
            return {
                ...state,
                showDetailsDialog: null,
                challenge: null,
            }

        case SET_CHALLENGES_TAB:
            return {
                ...state,
                challengesTab: payload
            }

        default:
            return state;
    }
}
