import {useLocation, useParams} from "react-router-dom";
import React from "react";
import {useAuth} from "../context/AuthContext";
import PropTypes from "prop-types";
import {connect} from "react-redux";

// eslint-disable-next-line
export const withRouter = WrappedComponent => props => {
    const params = useParams();
    const {currentUser} = useAuth()
    const location = useLocation();

    return (
        <WrappedComponent
            {...props}
            params={params}
            location={location}
            currentUser={currentUser}
        />
    );
};

withRouter.propTypes = {
    lock: PropTypes.object,
    userInfo: PropTypes.object,
}

const mapStateToProps = (state) => ({
    lock: state.user.lock,
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter)
