import React, {useEffect, useState} from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';
import {connect} from "react-redux";
import {GET_SPRINT_SUMMARY} from "../../redux/actions/configurator-actions";
import {withRouter} from "../../helpers/with-router";
import Loading from "../components/Loading";
import moment from "moment-timezone";
import DisclosureComponent from "./DisclosureComponent";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {getSummaryDays} from "../../helpers/utils";
import {usePrevious} from "../../hooks/usePrevious";

const SprintSummary = ({
                           today,
                           currentSprint,
                           sprintSummary,
                           loadingSprintSummary,
                           getSprintSummary,
                           currentSprintLoading
}) => {

    const { currentUser } = useAuth()
    const navigate = useNavigate()

    const prevCurrentSprintLoading = usePrevious(currentSprintLoading)

    // const isSprintDay = today.isoWeekday() >= currentMonday.isoWeekday() && today.isoWeekday() < currentSunday.isoWeekday()
    // eslint-disable-next-line no-unused-vars
    const sprintStartDate = moment(currentSprint?.startDate).format('DD-MM-YYYY')
    // eslint-disable-next-line no-unused-vars
    const sprintEndDate = moment(currentSprint?.endDate).format('DD-MM-YYYY')
    // eslint-disable-next-line no-unused-vars
    const title = currentSprint?.title

    // const [day, setDay] = useState(null)
    // eslint-disable-next-line no-unused-vars
    const [sprintResult, setSprintResult] = useState(70)
    const [sprintDays, setSprintDays] = useState([])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }, [])

    useEffect(()=>{

        if(prevCurrentSprintLoading === true && currentSprintLoading === false) {
            if(currentSprint){
                let query = {
                    sprintId: currentSprint.id,
                    studentUid: currentUser.uid
                }
                getSprintSummary(query)
            } else {
                navigate('/welcome')
            }
        } else if (currentSprint) {
            let query = {
                sprintId: currentSprint.id,
                studentUid: currentUser.uid
            }
            getSprintSummary(query)
        }

    }, [currentSprint, currentUser.uid, getSprintSummary, navigate])

    useEffect(()=>{
        if(sprintSummary){

            const days = getSummaryDays(sprintSummary.days)

            setSprintDays(days)

            const sprint = sprintSummary.sprint.allAssignments !== 0 ? sprintSummary.sprint.correctAnswer / sprintSummary.sprint.allAssignments * 100 : 0
            setSprintResult(Number((sprint).toFixed(0)))
        }
    }, [sprintSummary])

    return (
        <>
            {currentSprintLoading || loadingSprintSummary ? (
                <Loading isFullScreen={true}/>
            ) : (
                <>
                    {/*<div className="flex flex-row justify-between items-center mt-5 mx-4 pt-24">*/}
                    {/*    <div className={"flex flex-col w-3/4"}>*/}
                    {/*        <h2 className="text-xl font-bold text-left">{title}</h2>*/}
                    {/*        <p className={"prose text-sm text-left text-base-500"}>from {sprintStartDate} to {sprintEndDate}</p>*/}
                    {/*    </div>*/}
                    {/*    <div*/}
                    {/*        className="radial-progress bg-brandBlue text-primary border-4 border-info"*/}
                    {/*        style={{ "--value": sprintResult, "--size": "60px" }}*/}
                    {/*    >*/}
                    {/*        <span className={"text-2xs"}>{sprintResult}%</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={"divider m-0"} />*/}
                    <div className={"flex flex-col space-y-5 pt-24 px-4"}>
                        {sprintDays.map((day) => {
                            const allAssignments = day.allAssignments
                            const correctAnswer = day.correctAnswer
                            const results = Number((allAssignments !== 0 ? correctAnswer / allAssignments * 100 : 0).toFixed(0))

                            const dayNumber = Number(day.day)

                            if(dayNumber < today.isoWeekday()){
                                return <DisclosureComponent
                                    key={uuidv4()}
                                    status={'finished'}
                                    day={dayNumber}
                                    results={results}
                                    homeworks={day.homeworks}
                                />
                            } else if(dayNumber === today.isoWeekday()){
                                return <DisclosureComponent
                                    key={uuidv4()}
                                    status={'inProgress'}
                                    day={dayNumber}
                                    results={results}
                                    homeworks={day.homeworks}
                                />
                            } else {
                                return <DisclosureComponent
                                    key={uuidv4()}
                                    status={'wait'}
                                    day={dayNumber}
                                    results={0}
                                />
                            }
                        })}
                    </div>

                    {/*{ isSprintDay ? (*/}
                    {/*    <>*/}
                    {/*        {dailySummary && dailySummary.map((summary, index) => {*/}
                    {/*            const allAssignments = summary[0].allAssignments*/}
                    {/*            const correctAnswer = summary[0].correctAnswer*/}
                    {/*            return (*/}
                    {/*                <AssignmentCard*/}
                    {/*                    key={uuidv4()}*/}
                    {/*                    number={index+1}*/}
                    {/*                    title={summary[0].homework.title}*/}
                    {/*                    type={summary[0].homework.type}*/}
                    {/*                    media={summary[0].homework.media}*/}
                    {/*                    link={`/homework/${summary[0].homework.id}`}*/}
                    {/*                    all={allAssignments}*/}
                    {/*                    correct={correctAnswer}*/}
                    {/*                    status={correctAnswer + allAssignments === 0 ? 'wait' : 'completed'}*/}
                    {/*                />*/}
                    {/*            )*/}
                    {/*        })}*/}
                    {/*    </>*/}
                    {/*) : (*/}
                    {/*    <div className={"justify-start pt-10 px-4 flex flex-row h-full"}>*/}
                    {/*        <div>THERE ARE NO TASKS FOR YOU YET</div>*/}
                    {/*    </div>*/}
                    {/*) }*/}
                </>
            )}
        </>
    )

}

SprintSummary.propTypes = {
    currentUser: PropTypes.object,
    today: PropTypes.any,
    currentMonday: PropTypes.any,
    currentSunday: PropTypes.any,
    currentSprint: PropTypes.object,
    sprintSummary: PropTypes.object,
    getSprintSummary: PropTypes.func,
    loadingSprintSummary: PropTypes.bool,
    currentSprintLoading: PropTypes.bool
}

const mapStateToProps = (state) => ({
    today: state.common.today,
    currentMonday: state.common.currentMonday,
    currentSunday: state.common.currentSunday,
    currentSprint: state.config.currentSprint,
    sprintSummary: state.config.sprintSummary,
    loadingSprintSummary: state.config.loadingSprintSummary,
    currentSprintLoading: state.config.currentSprintLoading
})

const mapDispatchToProps = (dispatch) => ({
    getSprintSummary: (params) => dispatch({ type: GET_SPRINT_SUMMARY, payload: params }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SprintSummary))
