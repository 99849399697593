import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";

import OneColumnTile from "./OneColumnTile";
import SprintCard from "../cards/SprintCard";
import CardStack from "./StackedCards";
import BalanceCard from "../cards/BalanceCard";
import SpecialOfferCard from "../../components/SpecialOfferCard";
import GiftCard from "../cards/GiftCard";
import PropTypes from "prop-types";

const Grid = ({ userInfo }) => {

    const [userOffers, setUserOffers] = useState([])

    useEffect(() => {
        if(userInfo){
            setUserOffers(userInfo.userOffers)
        }
    }, [userInfo]);

    const [giftCode, setGiftCode] = useState('')

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if(query.get("gift")){
            setGiftCode(query.get("gift"))
        }
    }, []);

    return (
        <div
            className={"z-40 px-5 pt-24 space-y-3"}
        >
            <OneColumnTile>
                <SprintCard/>
            </OneColumnTile>
            <OneColumnTile>
                {/* eslint-disable-next-line no-undef */}
                <CardStack />
            </OneColumnTile>
            {/*<OneColumnTile>*/}
            {/*    /!* eslint-disable-next-line no-undef *!/*/}
            {/*    <NewStack />*/}
            {/*</OneColumnTile>*/}
            <OneColumnTile>
                <BalanceCard/>
            </OneColumnTile>
            {/* НЕОБХОДИМО УСТАНАВЛИВАТЬ Z-INDEX ДЛЯ ВСЕХ ЭЛЕМЕНТОВ ПЕРЕД SpecialOfferCard */}
            <OneColumnTile>
                <GiftCard giftCodeFromQueryString={giftCode} />
            </OneColumnTile>
            {userOffers.map(offer => {
                return (
                    <OneColumnTile key={offer.id}>
                        <SpecialOfferCard
                            offer={offer}
                        />
                    </OneColumnTile>
                )
            })}
        </div>
    )
}

Grid.propTypes = {
    userInfo: PropTypes.object,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Grid)


