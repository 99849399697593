import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Divider from '../../assets/divider.svg'

import {BsApple, BsFacebook, BsGoogle} from "react-icons/bs";
import LocalLogin from "./LocalLogin";
import LocalRegistration from "./LocalRegistration";
import ResetPassword from "./ResetPassword";
import {Link, Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import {
    facebookProvider,
    googleProvider,
    appleProvider
} from "../../firebase";
import Loading from "../components/Loading";

import ToastMessage from "../components/toast";
import {toast} from "react-toastify";


function Login ({
                    message,
                }) {

    const location = useLocation();
    const from = location.state?.from === undefined || location.state?.from === '/login' ? '/' : location.state?.from

    const pathname = location.pathname === undefined ? '/' : location.pathname

    const { currentUser } = useAuth();

    const navigateTo = (pathname === '/login' && from !== '/' ? from : '/') + `${window.location.search}`

    const [renderComponent, setRenderComponent] = useState(<Loading/>)

    useEffect(()=>{
        if(currentUser) {
            setRenderComponent(<Navigate to={navigateTo}/>)
        } else {
            setRenderComponent(<LoginScreen message={message} navigateTo={navigateTo} />)
        }

    }, [currentUser, message, navigateTo])

    return (
        <>
            {renderComponent}
        </>
    )
}

Login.propTypes = {
    message: PropTypes.string,
}

const mapStateToProps = (state) => ({
    message: state.message.message,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)

const LoginScreen = ({ navigateTo, message }) => {

    const [tab, setTab] = useState("tab-1");
    const [resetPassword, setResetPassword] = useState(false)

    const { doSocialSignIn } = useAuth();

    const signInWithSocialAccount = async (provider) => {
        try {
            window.history.replaceState({}, '', navigateTo);
            await doSocialSignIn(provider);
        } catch (error) {
            toast.error(<ToastMessage text={"error while signing up with provider"} withTryAgain={true} />)
        }
    };

    return <React.Fragment>
        <div className="relative flex flex-col justify-center items-center bg-brandYellow min-h-screen">
            <div className="bg-white text-center px-5 py-3">
                <h1 className="font-semibold text-2xl inline-block align-middle">EIS </h1>
                <img
                    alt="Divider"
                    className="inline-block mx-4"
                    src={Divider}
                />
                <p className="inline-block text-xl align-middle">English in Stories</p>
            </div>
            <div className="flex flex-col w-full xs:max-w-laptop items-center">
                {resetPassword ? null : (
                    <div role="tablist" className="tabs tabs-bordered px-5 pt-10">
                        <button
                            className={`tab ${tab === 'tab-1' ? 'tab-active' : ''}`}
                            onClick={() => {
                                setResetPassword(false)
                                setTab('tab-1')
                            }}
                        >
                            Login
                        </button>
                        <button
                            className={`tab ${tab === 'tab-2' ? 'tab-active' : ''}`}
                            onClick={() => {
                                setTab('tab-2')
                            }}
                        >
                            Registration
                        </button>
                    </div>
                )}

                {tab === 'tab-1' && (
                    <div className={'w-full pt-4 px-10 flex flex-col items-center'}>
                        {resetPassword ? (
                            <>
                                <ResetPassword/>
                                <p
                                    className={"mt-4 underline underline-offset-1 cursor-pointer"}
                                    onClick={() => setResetPassword(false)}
                                >
                                    Continue with email?
                                </p>
                            </>
                        ) : (
                            <>
                                <LocalLogin/>
                                <p
                                    className={"mt-4 underline underline-offset-1 cursor-pointer"}
                                    onClick={() => setResetPassword(true)}
                                >
                                    Reset password?
                                </p>
                            </>
                        ) }

                    </div>
                )}

                {tab === 'tab-2' && (
                    <div className={ 'w-full pt-4 px-10' }>
                        <LocalRegistration />
                    </div>
                )}

                <div className="flex flex-col w-full">
                    <h2 className="text-center mt-4 mb-4">Or authorize your account</h2>
                    <div className={"flex flex-row justify-center"}>
                        <div onClick={() => signInWithSocialAccount(googleProvider)} className="avatar placeholder mx-4">
                            <div className="p-4 bg-white rounded-full shadow-brand">
                                <BsGoogle className={"w-6 h-6"}/>
                            </div>
                        </div>
                        <div onClick={() => signInWithSocialAccount(facebookProvider)} className="avatar placeholder mx-4">
                            <div className="p-4 bg-white rounded-full shadow-brand">
                                <BsFacebook className={"w-6 h-6"}/>
                            </div>
                        </div>
                        <div onClick={() => signInWithSocialAccount(appleProvider)} className="avatar placeholder mx-4">
                            <div className="p-4 bg-white rounded-full shadow-brand">
                                <BsApple className={"w-6 h-6"}/>
                            </div>
                        </div>
                    </div>
                </div>
                {message && (
                    <div className="alert glass alert-error mx-10 mt-10 text-center">
                        {message}
                    </div>
                )}
            </div>
            <div className={"flex flex-row w-full fixed bottom-0 left-0 justify-center items-center pb-4"}>
                <Link
                    className={"prose text-sm text-primary font-semibold"}
                    target={"_blank"}
                    to={"https://drive.google.com/file/d/1UCgNvrruBsQipiRg3YbQmJmLSbDRvm1C/view?"}
                >
                    Privacy policy
                </Link>
                <div className={"divider divider-horizontal"} />
                <Link
                    className={"prose text-sm text-primary font-semibold"}
                    target={"_blank"}
                    to={"https://drive.google.com/file/d/1MuyZcW32lsPGyITQ2_fQfqIlZjvsqaFn/view?usp=sharing"}
                >
                    Offer
                </Link>
            </div>
        </div>
    </React.Fragment>
}

LoginScreen.propTypes = {
    navigateTo: PropTypes.string,
    message: PropTypes.string
}