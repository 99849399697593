import React, {Component} from "react";

class Hello extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="hero min-h-screen bg-base-200">
                    <div className="text-center hero-content">
                        <div className="max-w-md">
                            <h1 className="mb-5 text-5xl font-bold">
                                Hello there
                            </h1>
                            <p className="mb-5">
                                To start the task, please follow the link on the EIS Stories.
                                Not working?
                            </p>
                            <a href={"https://www.instagram.com/bluebabushka/"} className="btn btn-primary">Please let us know</a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Hello
