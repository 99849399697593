import React, {useEffect, useRef, useState} from 'react'

import {connect} from "react-redux";

import Avatar from "./Avatar";
import SettingsButton from "./SettingsButton";
import LogoutButton from "./LogoutButton";
import HomeButton from "./HomeButton";
import {useLocation} from "react-router-dom";
import UAParser from "ua-parser-js";
import BackButton from "./BackButton";
import PropTypes from "prop-types";
import {SET_HEADER_ELEMENT_RECT} from "../../../redux/actions/common-actions";

const Header = ({ setHeaderElementRect }) => {

    const headerElementRef = useRef(null)

    const location = useLocation()
    const pathname = location.pathname

    const parser = new UAParser()
    const browser = parser.getBrowser().name.toLowerCase()


    const [centralIcon, setCentralIcon] = useState(null)
    const [leftIcon, setLeftIcon] = useState(
        <div className={"pl-5 pb-2 text-white"}>
            <HomeButton/>
        </div>
    )
    const [rightIcon, setRightIcon] = useState(
        <div className={"pr-5 pb-2 text-white"}>
            <SettingsButton/>
        </div>
    )

    useEffect(() => {

        if(headerElementRef.current != null) {
            const calculateHeight = () => {
                const headerElementRect = headerElementRef.current.getBoundingClientRect();
                setHeaderElementRect(headerElementRect)
            };

            calculateHeight();
        }

        return () => {
        };
    }, [headerElementRef]);

    useEffect(() => {

        switch (true) {
            case pathname === '/':
            case pathname === '/welcome':
                setLeftIcon(
                    <div className={"pl-5 pb-2 text-white"}>
                        <LogoutButton/>
                    </div>
                )
                setCentralIcon(
                    <div className={"z-50 relative h-24 w-24"}>
                        <Avatar />
                    </div>
                )
                setRightIcon(
                    <div className={"pr-5 pb-2 text-white"}>
                        <SettingsButton/>
                    </div>
                )
                break;
            case pathname === '/account':
                setRightIcon(
                    <div className={"pr-5 pb-2 text-white"}>
                        <LogoutButton rotate={''} />
                    </div>
                )
                setCentralIcon(null)
                setLeftIcon(
                    <div className={"pl-5 pb-2 text-white"}>
                        <HomeButton/>
                    </div>
                )
                break;
            case pathname.startsWith('/homework'):
                setLeftIcon(browser !== 'instagram' ? (
                    <div className={"pl-4 pb-2 text-white"}>
                        <BackButton/>
                    </div>
                ) : null )
                setCentralIcon(null)
                setRightIcon(
                    <div className={"pr-5 pb-2 text-white"}>
                        <SettingsButton/>
                    </div>
                )
                break;
            default:
                setCentralIcon(null)
                setLeftIcon(
                    <div className={"pl-5 pb-2 text-white"}>
                        <HomeButton/>
                    </div>
                )
                setRightIcon(
                    <div className={"pr-5 pb-2 text-white"}>
                        <SettingsButton/>
                    </div>
                )
                break;
        }

    }, [pathname]);

    return (
        <div
            // data-theme={"sprintedtheme2"}
            ref={headerElementRef}
            className={`z-50 fixed inset-x-0 top-0 ${browser === 'instagram' ? 'h-14' : 'h-20'} flex flex-row justify-center w-full`}
        >
            <div className="flex flex-row justify-between items-end rounded-b-3xl border-2 border-primary bg-primary w-full xs:max-w-laptop">
                <div className={`flex flex-row ${browser === 'instagram' && pathname.startsWith('/homework') ? 'justify-end' : 'justify-between'} items-end w-full h-full`}>
                    {leftIcon}
                    {centralIcon}
                    {rightIcon}
                </div>
            </div>
        </div>
    )
}

Header.propTypes = {
    setHeaderElementRect: PropTypes.func
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = (dispatch) => ({
    setHeaderElementRect: (headerElementRect) => dispatch({ type: SET_HEADER_ELEMENT_RECT, payload: headerElementRect })
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)


