import React, {useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {useAuth} from "../../context/AuthContext";
import {withUseFormHook} from "../../helpers/with-form-hook";

import validator from "validator";
import userApi from "../../redux/api/user-api";

import {CLEAN_REGISTRATION_DATA, REGISTRATION} from "../../redux/actions/auth-actions";

const LocalRegistration = ({ form }) => {

    let [loading, setLoading] = useState(false)

    const { doSignupWithEmailPass } = useAuth()

    const { register, handleSubmit, formState: { errors }, watch, reset, setError, clearErrors } = form;

    const localRegistration = async (data) => {
        setLoading(true)
        const result = await doSignupWithEmailPass(data.email, data.password1)
        if(result){
            reset({
                email: "",
                password1: "",
                password2: ""
            })
        }
        setLoading(false)
    }

    const current_password = watch("password1", "");

    async function searchByParams(params){

        const result = await userApi.searchAccountByParams(params)

        if(result.status === 200) {
            if(result.data.results.length === 0) {
                reset({
                    email: params.email,
                })
                clearErrors("email")
            }
            else {
                setError("email", { type: 'exist', message: `This email already exists with another user` });
            }
        } else {
            setError("email", { type: 'exist', message: `Could not check the email. Try again later` });
        }
    }

    return (
        <React.Fragment>
            <form className="w-full space-y-3 mt-4">
                <div className="form-control">
                    <input
                        type="text"
                        placeholder="Enter your email"
                        className={`input input-bordered input-md ${errors.email && 'input-error'}`}
                        {...register("email", {
                            required: "The field should not be empty",
                            onChange: async (e) => {
                                const value = e.target.value
                                if(validator.isEmail(value)) {
                                    await searchByParams({username: value})
                                }
                            },
                            validate: (email) => {
                                return validator.isEmail(email) || "Enter email with correct format"
                            }
                        })}
                    />
                    {errors.email &&
                        <label className="label">
                            {<span className="label-text-alt text-brandRed">{errors.email.message}</span>}
                        </label>
                    }
                </div>
                <div className="form-control">
                    <input
                        type="password"
                        placeholder="Enter your password"
                        className={`input input-bordered input-md ${errors.password1 && 'input-error'}`}
                        {...register("password1", {
                            required: "The field should not be empty",
                            minLength: {
                                value: 8,
                                message: "Minimum length - 8 symbols"
                            },
                        })}
                    />
                    {errors.password1 &&
                        <label className="label">
                            {<span className="label-text-alt text-brandRed">{errors.password1.message}</span>}
                        </label>
                    }
                </div>
                <div className="form-control">
                    <input
                        type="password"
                        placeholder="Repeat your password"
                        className={`input input-bordered input-md ${errors.password2 && 'input-error'}`}
                        {...register("password2", {
                            required: "The field should not be empty",
                            validate: value =>
                                value === current_password || "The passwords do not match"
                        })}
                    />
                    <label className="label">
                        {errors.password2 && <span className="label-text-alt">{errors.password2.message}</span>}
                    </label>
                </div>
                <button
                    className={`py-2 px-4 w-full btn-primary btn btn-md rounded-full btn-wide shadow-brand-button`}
                    onClick={handleSubmit(localRegistration)}
                >
                    {loading && <span className="loading loading-spinner"></span>}
                    <span className="font-medium">Create account</span>
                </button>
            </form>

        </React.Fragment>
    )
}

LocalRegistration.propTypes = {
    form: PropTypes.object,
    registration: PropTypes.func.isRequired,
    cleanRegistrationData: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.object
}

const mapStateToProps = (state) => ({
    error: state.auth.registrationError,
    loading: state.auth.loadingRegistration
})

const mapDispatchToProps = (dispatch) => ({
    registration: (credentials) => dispatch({ type: REGISTRATION, payload: credentials }),
    cleanRegistrationData: () => dispatch({ type: CLEAN_REGISTRATION_DATA }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(LocalRegistration))
