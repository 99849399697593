import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Emoji from "../../components/Emoji";
import ChallengeStats from "./ChallengeStats";
import StartChallengeButton from "../StartChallengeButton";
import Preconditions from "./ChallengePreconditions";
import ActionTile from "./ActionTile";

import {CLOSE_CHALLENGE_DETAILS_DIALOG} from "../../../redux/actions/challenge-actions";
import pluralize from "pluralize";
// import Pluralize from "react-pluralize";



const NotOwnedChallengeDetailsModal = ({
                                           challenge,
                                           closeDialog
}) => {

    const [showPrecondition, setPreconditionState] = useState(false)
    const [challengeActionsText, setChallengeActionsText] = useState('')


    useEffect(()=>{
        if(challenge){
            if(challenge.targetAction) {
                setPreconditionState(true)
            }

            if(challenge.participantType){
                // eslint-disable-next-line default-case
                switch (challenge.participantType){
                    case 'owner_only':
                        setChallengeActionsText('You have to')
                        break;
                    case 'participants_only':
                        setChallengeActionsText('Each of your friends has to')
                        break;
                    case 'participants_and_owner':
                        setChallengeActionsText('Your friends and you have to')
                        break;
                }
            }
        }



    }, [challenge])

    return challenge && (
        <React.Fragment>
            <div className="flex flex-col h-full text-sm ms:text-base">
                <header className={"flex-none"}>
                    <button
                        className="btn btn-xs btn-circle btn-primary absolute right-4 top-4"
                        onClick={closeDialog}
                    >
                        ✕
                    </button>
                    <div className={"flex flex-row justify-start"}>
                        <div className={"text-lg ms:text-xl font-bold pb-2"}>{challenge.title}</div>
                    </div>
                    <ChallengeStats
                        isRepeatable={challenge.isRepeatable}
                        score={challenge.score}
                        type={challenge.participantType}
                    />
                </header>
                <main className="grow overflow-y-scroll overscroll-contain pt-2 pb-10">
                    {showPrecondition && (
                        <div className={"pt-5"}>
                            <p className={"font-semibold"}>What needs to be done?</p>
                            <Preconditions targetAction={challenge.targetAction} challengeActionsText={challengeActionsText} />
                        </div>
                    )}
                    <div className={"pt-5 flex flex-col"}>
                        <p className={"font-semibold"}>Challenge</p>
                        <span>{challengeActionsText}:</span>
                        <div className={"flex flex-col space-y-4"}>
                            <div className={"divide-y"}>
                                {challenge.actions.map((action)=> {
                                    return (
                                        <div key={action.id} className={"pl-0 pt-4"}>
                                            <ActionTile action={action} participantType={challenge.participantType} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <p className={"font-semibold pt-3"}>Reward</p>
                        <div className={"flex flex-row flex-wrap"}>
                            You will get&nbsp;
                            <span className={"font-bold"}>
                                        <span>{challenge.reward.rewards}&nbsp;</span>
                                {challenge.reward.type === 'week' ? (
                                    <>
                                        free&nbsp;
                                        <span className={"inline"}>
                                            {`${challenge.reward.rewards} ${pluralize(challenge.reward.type, challenge.reward.rewards)}`}
                                        </span>&nbsp;
                                        {/*<Pluralize*/}
                                        {/*    className={"inline"}*/}
                                        {/*    singular={challenge.reward.type}*/}
                                        {/*    count={challenge.reward.rewards}*/}
                                        {/*    showCount={false}*/}
                                        {/*/>&nbsp;*/}

                                    </>
                                ) : (
                                    <span>
                                                {challenge.reward.type}&nbsp;
                                            </span>
                                )}
                                    </span>
                            <span>for {challenge.isRepeatable ? 'every': ''} {challenge.score}</span><Emoji symbol={'🏆'} />&nbsp;
                        </div>
                    </div>
                </main>
                <footer className="flex-none pt-4 border-t-2 border-t-brandDark-100">
                    <div className={"flex flex-row justify-between"}>
                        <button
                            onClick={closeDialog}
                            className={`btn btn-primary btn-sm ms:btn-md rounded-full shadow-brand-button`}
                        >
                            Cancel
                        </button>
                        <StartChallengeButton
                            challengeId={challenge.id}
                            closeDialog={closeDialog}
                            title={challenge.hasReferral ? 'Create link and start' : 'Start challenge'}
                        />
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
};

NotOwnedChallengeDetailsModal.propTypes = {
    challenge: PropTypes.object,
    showDetailsDialog: PropTypes.string,
    closeDialog: PropTypes.func,
}

const mapStateToProps = (state) => ({
    showDetailsDialog: state.challenge.showDetailsDialog,
})

const mapDispatchToProps = (dispatch) => ({
    closeDialog: () => dispatch({ type: CLOSE_CHALLENGE_DETAILS_DIALOG }),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotOwnedChallengeDetailsModal)