import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Spinner from "../../../components/Spinner";
import {GET_GIFTS_BY_USER} from "../../../../redux/actions/gift-actions";
import GiftCard from "./card";

const GiftCards = ({
                       userInfo,
                       getGiftCardsByUser,
                       giftCards,
                       loadingGifts,
}) => {

    useEffect(()=>{
        getGiftCardsByUser(userInfo.uid)
    }, [])

    return (
        <React.Fragment>
            {loadingGifts ? (
                <div className={'flex flex-row flex-1 w-full justify-center items-center'}>
                    <Spinner />
                </div>
            ) : (
                <div className={"px-4"}>
                    {giftCards && giftCards.map((giftCard) => <GiftCard key={giftCard.id} giftCard={giftCard} />)}
                </div>
            )}

        </React.Fragment>
    )
}

GiftCards.propTypes = {
    userInfo: PropTypes.object,
    giftCards: PropTypes.array,
    loadingGifts: PropTypes.bool,
    getGiftCardsByUser: PropTypes.func,

}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    giftCards: state.gift.giftCards,
    loadingGifts: state.gift.loadingGifts,
})

const mapDispatchToProps = (dispatch) => ({
    getGiftCardsByUser: (userId) => dispatch({ type: GET_GIFTS_BY_USER, payload: userId }),
})

export default connect(mapStateToProps, mapDispatchToProps)(GiftCards)
