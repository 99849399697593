import {
    call,
    put,
    takeLatest,
} from 'redux-saga/effects'
import {
    BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN,
    BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_ERROR,
    BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_REQUEST,
    BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_SUCCESS,
    GET_ACHIEVEMENTS,
    GET_ACHIEVEMENTS_ERROR,
    GET_ACHIEVEMENTS_REQUEST,
    GET_ACHIEVEMENTS_SUCCESS,
} from "../actions/achievement-actions";
import achievementApi from "../api/achievement-api";

function* getAchievements({ payload }) {

    yield put({ type: GET_ACHIEVEMENTS_REQUEST })

    const response = yield call(achievementApi.getAchievements, payload)

    if(response.status === 200) {
        yield put({ type: GET_ACHIEVEMENTS_SUCCESS, payload: response.data })
    } else if (response.status === 404) {
        yield put({ type: GET_ACHIEVEMENTS_SUCCESS, payload: null })
    } else {
        yield put({ type: GET_ACHIEVEMENTS_ERROR, payload: response.status })
    }
}

function* bulkUpdateUserAchievementsWasShown({ payload }) {

    yield put({ type: BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_REQUEST })

    const response = yield call(achievementApi.bulkUpdateUserAchievementsWasShown, payload.updateList)
    const data = response.data
    data.achievementId = payload.achievementId

    if(response.status === 200) {
        yield put({ type: BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_SUCCESS, payload: data })
    } else {
        yield put({ type: BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN_ERROR, payload: response.status })
    }
}

export default function* achievementSaga() {
    yield takeLatest(GET_ACHIEVEMENTS, getAchievements)
    yield takeLatest(BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN, bulkUpdateUserAchievementsWasShown)
}
