import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useForm} from "react-hook-form";
import {REDEEM_GIFT} from "../../../../redux/actions/gift-actions";
import {validateGiftCardCode} from "../../../../helpers/validation";
import {MdDone} from "react-icons/md";
import {IconContext} from "react-icons/lib";
import HasSubscriptionAlertModal from "./HasSubscriptionAlertModal";

const GiftCard = ({
                      userInfo,
                      redeemGiftCard,
                      loadingRedeemGift,
                      loadingUserInfo,
                      giftCodeFromQueryString
}) => {

    const disableButton = (userInfo !== null && loadingUserInfo) || loadingRedeemGift

    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors }, setError
    } = useForm();

    const [canPaid, setCanPaid] = useState(false)
    const [hasSubscription, setHasSubscription] = useState(false)

    const [alertModalIsOpen, setAlertModalIsOpen] = useState(false)
    const [giftData, setGiftData] = useState(null)
    const [withAnimation, setWithAnimation] = useState(null)

    const handleRedeem = (data) => {

        const code = giftData !== null ? giftData.giftCode.toUpperCase().trim() : data.giftCode.toUpperCase().trim()

        if(validateGiftCardCode(code)){
            redeemGiftCard({
                redeemParams : { code: code, isNewStudent: userInfo.isNewCustomer},
                userId: userInfo.uid
            })
        } else {
            setError('giftCode', { type: 'validate', message: "Please check the number of your gift certificate" });
        }
    }

    const beforeRedeem = data => {

        setWithAnimation(false)

        if(hasSubscription){
            setGiftData(data)
            setAlertModalIsOpen(true)
        } else {
            handleRedeem(data)
        }
    }

    useEffect(()=>{
        if(userInfo){
            setCanPaid(userInfo.isAccountSynced)
            setHasSubscription(userInfo.userPaymentInfo?.hasSubscription)
        }
    }, [userInfo])

    useEffect(()=>{
        if(giftCodeFromQueryString){
            setWithAnimation(true)
            reset({giftCode: giftCodeFromQueryString})
        }
    },[giftCodeFromQueryString])

    const redeemGiftButton = useMemo(() => (
        <button
            disabled={!canPaid || disableButton}
            className={`shadow btn btn-circle btn-primary`}
            onClick={canPaid ? handleSubmit(beforeRedeem) : null}
        >
            {loadingRedeemGift ? (
                <span className="loading loading-spinner"></span>
            ) : (
                <>
                    < IconContext.Provider value={{className: "w-8 h-8"}}>
                        <MdDone/>
                    </IconContext.Provider>
                </>

            )}
        </button>
    ), [canPaid, disableButton, loadingRedeemGift]);

    return (
        <React.Fragment>
            <div className="flex flex-row justify-between items-center w-full space-x-2">
                <div className="flex-1">

                    <input
                        type="shadow text relative"
                        disabled={!canPaid}
                        placeholder={'Enter your gift code'}
                        onFocus={() => {
                            setWithAnimation(false)
                        }}
                        className={`w-full input input-bordered border-2 rounded-full text-neutral prose uppercase`}
                        {...register("giftCode", {
                            required: 'Enter your gift code',
                            onBlur: (event) => {
                                if(event.target.value === '') {
                                    clearErrors(["giftCode"])
                                }
                            }
                        })}
                    />
                    {withAnimation && (
                        <>
                            <span className="animate-ping-slow absolute top-0 left-1 inline-flex h-3 w-3 rounded-full bg-orange opacity-75"/>
                            <span className="absolute top-0 left-1 inline-flex h-3 w-3 rounded-full bg-orange"></span>
                        </>
                    )}
                </div>
                {redeemGiftButton}
            </div>
            {errors.giftCode &&
                <label className="label">
                    {<span className="label-text-alt text-brandRed">{errors.giftCode.message}</span>}
                </label>
            }
            <HasSubscriptionAlertModal
                isOpen={alertModalIsOpen}
                setModalIsOpen={setAlertModalIsOpen}
                handleRedeem={handleRedeem}
                loadingRedeemGift={loadingRedeemGift}
                disableButton={disableButton}
            />
        </React.Fragment>
    )
}

GiftCard.propTypes = {
    userInfo: PropTypes.object,
    giftCodeFromQueryString: PropTypes.string,
    loadingUserInfo: PropTypes.bool,
    loadingGifts: PropTypes.bool,
    loadingRedeemGift: PropTypes.bool,
    redeemGiftCard: PropTypes.func,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    loadingUserInfo: state.user.loadingUserInfo,
    loadingGifts: state.gift.loadingGifts,
    loadingRedeemGift: state.gift.loadingRedeemGift,
})

const mapDispatchToProps = (dispatch) => ({
    redeemGiftCard: (code) => dispatch({ type: REDEEM_GIFT, payload: code }),
})

export default connect(mapStateToProps, mapDispatchToProps)(GiftCard)
