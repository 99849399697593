import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Template from "./templates/WithoutFilterTemplate";

const AchievementTemplate = ({poster, index, achievement, setSvg, currentIndex}) => {

    const ref = useRef(null)
    const [parentSize, setParentSize] = useState({ width:0, height: 0 })
    const [showTemplate, setShowTemplate] = useState(false)

    useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {

            const boundingRect = ref.current.getBoundingClientRect();
            const height = boundingRect.height
            const width = boundingRect.width

            if(width > 0 && height > 0){
                setParentSize({ width:width, height: height });
                setShowTemplate(true)
            }
        });
        resizeObserver.observe(ref.current);

        return () => resizeObserver.disconnect(); // clean up
    }, []);


    return (
        <React.Fragment>
            <div ref={ref} id={`item${index}`} className="relative carousel-item w-full h-full z-10 rounded-box">
                {showTemplate && (
                    <Template index={index} currentIndex={currentIndex} sizing={parentSize} number={achievement.result} template={poster.image} setSvg={setSvg}/>
                )}
            </div>
        </React.Fragment>
    );
};

AchievementTemplate.propTypes = {
    poster: PropTypes.object,
    index: PropTypes.number,
    setSvg: PropTypes.func,
    currentIndex: PropTypes.number,
    achievement: PropTypes.object,
    isOpen: PropTypes.bool,
    callback: PropTypes.func,
    footerHeight: PropTypes.number
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(AchievementTemplate)