import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {IoClose} from "react-icons/io5";
import PaymentMethodActions from "./PaymentMethodActions";
import Visa from "../../../assets/cards/visa-10.svg"
import Amex from "../../../assets/cards/american-express.svg"
import DinersClub from "../../../assets/cards/diners_club.png"
import Discover from "../../../assets/cards/discover.svg"
import JSB from "../../../assets/cards/jcb.png"
import Mastercard from "../../../assets/cards/mastercard.png"
import Unionpay from "../../../assets/cards/china_union.png"
import {BsCreditCard2Front} from "react-icons/bs";

const CardBrand = ({ brand }) => {
    switch (brand) {
        case 'visa':
            return <img className={"h-7 w-7"} src={Visa} alt={brand} />
        case 'amex':
            return <img className={"h-7 w-7"} src={Amex} alt={brand} />
        case 'diners_club':
            return <img className={"h-7 w-7"} src={DinersClub} alt={brand} />
        case 'discover':
            return <img className={"h-7 w-7"} src={Discover} alt={brand} />
        case 'jcb':
            return <img className={"h-7 w-7"} src={JSB} alt={brand} />
        case 'mastercard':
            return <img className={"h-7 w-7"} src={Mastercard} alt={brand} />
        case 'unionpay':
            return <img className={"h-7 w-7"} src={Unionpay} alt={brand} />
        default:
            return <BsCreditCard2Front />
    }
}

CardBrand.propTypes = {
    brand: PropTypes.string
}

const PaymentMethodCard = ({ paymentMethod, isDefault, index }) => {

    const card = paymentMethod.card

    return (
        <div key={index}>
            <div className={"flex flex-row flex-wrap justify-between"}>
                <div className={"flex flex-none flex-row items-center space-x-3"}>
                    <CardBrand brand={card.brand} />
                    <div className={"flex flex-col justify-center"}>
                        <div className={"prose flex flex-row items-center space-x-2 font-semibold"}>
                            <span className={"capitalize"}>{card.brand}</span>
                            <span className={"capitalize"}>****</span>
                            <span className={"capitalize"}>{card.last4}</span>

                        </div>
                        <div className={"flex flex-row items-center space-x-2"}>
                            <span className={"prose text-sm"}>Expires</span>
                            <span className={"prose text-sm"}>{card.expMonth}/{card.expYear}</span>
                        </div>

                    </div>
                </div>
                <div className="flex flex-row flex-1 items-center  justify-end space-x-5">
                    {isDefault ? (
                        <>
                            <div className="prose badge badge-ghost rounded-md p-3">Default</div>
                            <IoClose
                                className={"h-5 w-5 text-gray-400"}
                            />
                        </>
                    ) : (
                        <PaymentMethodActions
                            isDefault={isDefault}
                            paymentMethodId={paymentMethod.id}
                        />
                    )}
                </div>

            </div>
        </div>
    )
}

PaymentMethodCard.propTypes = {
    userSubscription: PropTypes.object,
    paymentMethod: PropTypes.object,
    isDefault: PropTypes.bool,
    index: PropTypes.number
}

const mapStateToProps = (state) => ({
    userSubscription: state.billing.userInfo,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodCard)
