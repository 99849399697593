import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class Error extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="hero min-h-screen bg-base-200">
                    <div className="text-center hero-content">
                        <div className="max-w-md">
                            {this.props.error.title ? (
                                <h1 className="mb-5 text-3xl font-bold">
                                    {this.props.error.title}
                                </h1>
                            ) : null}
                            <p className="mb-5 text-xl">
                                {this.props.error.message}
                            </p>
                            { this.props.error.params.withSupportButton ? (
                                <a href={"https://www.instagram.com/eis_support/"} className="btn btn-primary">Contact with support</a>
                            ) : null }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

Error.propTypes = {
    error: PropTypes.object.isRequired,
}

const mapStateToProps = () => ({

})

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Error)
