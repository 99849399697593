import {stringToBoolean} from "./validation";

export const paymentApiUrl = process.env.REACT_APP_PAYMENT_URL+'/api/v1'
export const homeworkApiUrl = process.env.REACT_APP_HOMEWORK_API_URL
export const accountApiUrl = process.env.REACT_APP_ACCOUNT_API_URL
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLICK_KEY
export const cloudpaymentsPublicKey = process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_KEY

export const authPaths = [
    '/login',
    '/logout',
    '/verify-email',
]

export const stripeCurrencies = ['aud', 'eur', 'usd']

export const isProduction = stringToBoolean(process.env.REACT_APP_TEST) === false
