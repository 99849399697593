import client from './client'
import camelcaseKeys from "camelcase-keys";
import {accountApiUrl} from "../../helpers/constants";
import snakecaseKeys from "snakecase-keys";

const accountClient = client(`${accountApiUrl}/api/v1`)

export const getChallenges = async () => {
  try {
    const response = await accountClient.get(`/challenge/challenge/get_challenges_for_user/`)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const sendChallengeAction = async (params) => {

  try {
    const data = snakecaseKeys(params)
    const response = await accountClient.post(
        `/challenge/challenge/challenge_action_code/`,
        data,
    )
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const getUserChallenges = async (uid) => {

  try {
    const response = await accountClient.get(`/challenge/user_challenge/?user_uid=${uid}`)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const startChallenge = async (params) => {

  try {
    const data = snakecaseKeys(params)
    const response = await accountClient.post(
        `/challenge/user_challenge/`,
        data,
        // {
        //   headers: {
        //     'X-CSRFToken': csrfToken,
        //   },
        // }
    )
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const updateUserChallenge = async (params) => {

  try {
    const data = snakecaseKeys(params)
    const response = await accountClient.put(
        `/challenge/user_challenge/${params.id}/`,
        data,
    )
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

const challengeApi = {
  getChallenges,
  getUserChallenges,
  startChallenge,
  sendChallengeAction,
  updateUserChallenge
};

export default challengeApi;
