import React, {useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import paymentApi from "../../redux/api/payment-api";
import CardPaymentMethod from "./CardPaymentMethod";
// import Pluralize from "react-pluralize";
import {useGTMDispatch} from "@elgorditosalsero/react-gtm-hook";
import {isProduction} from "../../helpers/constants";
import pluralize from "pluralize";

const CreateSubscription = ({ userSubscription, userInfo, priceItems }) => {

    const sendDataToGTM = useGTMDispatch()

    const [setupIntentSecret, setSetupIntentSecret] = useState(null)
    const [showDialog, setShowDialog] = useState(false)
    const [showSubscribeDialog, setShowSubscribeDialog] = useState(false)
    const [showPositiveBalanceDialog, setShowPositiveBalanceDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleCreateSubscription = async (e) => {
        e.preventDefault();

        if(userInfo.balance.weeks > 0) {
            setShowPositiveBalanceDialog(true)
        } else if(userInfo.balance.weeks === 0){
            if(userInfo.profile.currency === 'aud') {
                createSubscription()
            } else {
                setShowSubscribeDialog(true)
            }
        }
    }

    const createSubscription = () => {
        setLoading(true)
        paymentApi.createSetupIntent({

            customerId: userSubscription.customer,
        }).then(response => {
            if(response.status === 200){

                isProduction && sendDataToGTM({
                    user_id: userInfo.uid,
                    event: 'ga4event',
                    event_category: 'begin_checkout',
                    ecommerce: {
                        payment_type: 'subscription',
                        items: [{
                            item_name: 'English in Stories',
                            price: (priceItems.aud).toString(),
                            week_number: "1",
                            quantity: "1"
                        }]
                    }
                })

                setSetupIntentSecret(response.data.clientSecret)
                setShowDialog(true)
            } else {

                // ОБРАБОТКА ОШИБКИ
            }
            setLoading(false)
            if(setShowSubscribeDialog) {
                setShowSubscribeDialog(false)
            }
        })
    }

    return (

        <React.Fragment>
            <div className={"pt-10 flex flex-row items-center justify-between"}>
                <div className={"flex flex-col justify-start"}>
                    <div className={"prose text-lg font-semibold"}>Subscribe to EIS</div>
                    {/*<div className={"prose text-sm"}>{offerString}</div>*/}
                </div>

                <button
                    onClick={handleCreateSubscription}
                    className={`btn w-40 btn-primary rounded-full shadow-brand-button`}
                >
                    {loading && <span className="loading loading-spinner"></span>}
                    Subscribe
                </button>

                {showDialog && (
                    <CardPaymentMethod
                        setupIntentSecret={setupIntentSecret}
                        showDialog={showDialog}
                        setShowDialog={setShowDialog}
                    />
                )}

                <dialog className={`flex flex-row justify-center modal modal-bottom ${showSubscribeDialog ? 'modal-open' : ''}`}>
                    <form method={"dialog"} className="modal-box bg-white w-screen xs:max-w-laptop pt-4 px-4">
                        {/* CLOSE MODAL BUTTON */}
                        <button
                            onClick={() => setShowSubscribeDialog(false)}
                            className="btn btn-sm btn-circle btn-primary absolute right-4 top-3"
                        >✕</button>

                        {/* MODAL BODY */}
                        <h3 className="prose font-bold text-lg">Subscribe to EIS</h3>
                        <p className="prose py-4 text-justify">Although the subscription is only available in Australian dollars, you can pay for your subscription in any currency. The final amount charged will depend on the exchange rate of your bank</p>
                        <div className="modal-action">
                            <button
                                onClick={createSubscription}
                                className={`btn rounded-full shadow-brand-button btn-primary`}
                            >
                                {loading && <span className="loading loading-spinner"></span>}
                                Got it
                            </button>
                            <button
                                onClick={()=> setShowSubscribeDialog(false)}
                                className={`btn btn rounded-full shadow-brand-button ${loading ? 'btn-disabled btn-secondary' : 'btn-primary'}`}
                            >
                                Go back to weekly
                            </button>
                        </div>
                    </form>
                </dialog>

                <dialog className={`flex flex-row justify-center modal modal-bottom ${showPositiveBalanceDialog ? 'modal-open' : ''}`}>
                    <form method={"dialog"} className="modal-box bg-white w-screen xs:max-w-laptop pt-4 px-4">
                        {/* CLOSE MODAL BUTTON */}
                        <button
                            onClick={() => setShowPositiveBalanceDialog(false)}
                            className="btn btn-sm btn-circle btn-primary absolute right-4 top-3"
                        >✕
                        </button>

                        {/* MODAL BODY */}
                        <h3 className="prose font-bold text-lg">Subscribe to EIS</h3>
                        {/*<p className="prose py-4 text-justify">Your current balance*/}
                        {/*    is <Pluralize*/}
                        {/*        singular={'week'} plural={'weeks'} count={userInfo.balance.weeks}/>. You’ll be able to*/}
                        {/*    subscribe once your balance is 0 weeks.</p>*/}
                        <p className="prose py-4 text-justify">Your current balance
                            is {`${userInfo.balance.weeks} ${pluralize('week', userInfo.balance.weeks)}`}. You’ll be able to
                            subscribe once your balance is 0 weeks.</p>
                        <div className="modal-action">
                            <button
                                onClick={() => setShowPositiveBalanceDialog(false)}
                                className={`btn btn rounded-full shadow-brand-button btn-primary`}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </dialog>
            </div>
        </React.Fragment>
    )
}

CreateSubscription.propTypes = {
    userInfo: PropTypes.object,
    userSubscription: PropTypes.object,
    priceItems: PropTypes.object,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    userSubscription: state.billing.userSubscription,
    priceItems: state.preference.priceItems
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscription)
