import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import OwnedChallengeDetailsModal from "./OwnedChallengeDetailsModal";
import NotOwnedChallengeDetailsModal from "./NotOwnedChallengeDetailsModal";

const ChallengeDetailsModal = ({
                                   challenge,
                                   showDetailsDialog,
                                   footerHeight
}) => {

    const [maxHeight, setMaxHeight] = useState(0)

    useEffect(() => {
        const calculateHeight = () => {
            const calculatedHeight = window.innerHeight; // - footerHeight;
            setMaxHeight(calculatedHeight);
        };

        calculateHeight();

        // Обработчик изменения размеров окна
        const handleResize = () => {
            calculateHeight();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [footerHeight]);

    return (
        <dialog
            className={`flex flex-row justify-center modal modal-top h-screen ${showDetailsDialog != null ? 'modal-open' : null}`}
        >
            <form method={"dialog"}
                  className={`p-5 modal-box rounded-none relative bg-white w-screen xs:max-w-laptop overflow-hidden`}
                  style={{ maxHeight: maxHeight, height: maxHeight }}
            >
                <>
                    {{
                        'owned': <OwnedChallengeDetailsModal userChallenge={challenge} />,
                        'not_owned': <NotOwnedChallengeDetailsModal challenge={challenge} />
                    }[showDetailsDialog]}
                </>

            </form>
        </dialog>
    )
}


ChallengeDetailsModal.propTypes = {
    challenge: PropTypes.object,
    showDetailsDialog: PropTypes.string,
    footerHeight: PropTypes.number
}

const mapStateToProps = (state) => ({
    challenge: state.challenge.challenge,
    showDetailsDialog: state.challenge.showDetailsDialog,
    footerHeight: state.common.footerHeight
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDetailsModal)
