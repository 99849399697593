import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects'

import {
    GET_GIFTS_BY_USER,
    GET_GIFTS_BY_USER_ERROR,
    GET_GIFTS_BY_USER_REQUEST,
    GET_GIFTS_BY_USER_SUCCESS,
    NOT_FOUND_GIFT_FOR_REDEEM,
    REDEEM_GIFT,
    REDEEM_GIFT_ERROR,
    REDEEM_GIFT_REQUEST,
    REDEEM_GIFT_SUCCESS
} from "../actions/gift-actions";
import giftApi from "../api/gift-api";
import {NEED_UPDATE_USER_INFO} from "../actions/user-actions";

function* getGiftCardsByUser({ payload }) {

    yield put({ type: GET_GIFTS_BY_USER_REQUEST})

    const response = yield call(giftApi.getGiftCardsByUser, payload)

    if(response.status === 200) {
        yield put({type: GET_GIFTS_BY_USER_SUCCESS, payload: response.data.results })
    } else if(response.status === 404) {
        yield put({type: GET_GIFTS_BY_USER_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_GIFTS_BY_USER_ERROR, payload: response.status })
    }

}

function* redeemGiftsCard({ payload }) {

    yield put({ type: REDEEM_GIFT_REQUEST})

    let response = yield call(giftApi.redeemGiftCard, payload.redeemParams)

    if(response.status === 204) {
        yield put({type: REDEEM_GIFT_SUCCESS })
        yield put({ type: NEED_UPDATE_USER_INFO, payload: true })
        yield call(getGiftCardsByUser, {payload: payload.userId} )
    } else if(response.status === 404) {
        yield put({type: NOT_FOUND_GIFT_FOR_REDEEM, payload: response.status })
    } else {
        yield put({ type: REDEEM_GIFT_ERROR, payload: {message: response.data.error, code: response.status} })
    }
}

export default function* giftSaga() {
    yield takeLatest(GET_GIFTS_BY_USER, getGiftCardsByUser)
    yield takeLatest(REDEEM_GIFT, redeemGiftsCard)
}
