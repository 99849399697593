import {Disclosure} from "@headlessui/react";

import React, {useEffect, useState} from 'react'
import Emoji from "../components/Emoji";
import { v4 as uuidv4 } from 'uuid';
import AssignmentCard from "./AssignmentCard";
import {FaChevronUp} from "react-icons/fa";
import PropTypes from "prop-types";

const DisclosureComponent = ({ status, day, results, homeworks }) => {

    const [buttonStyle, setButtonStyle] = useState('')
    const [titleStyle, setTitleStyle] = useState('')
    const [iconStyle, setIconStyle] = useState('')
    const [symbol, setSymbol] = useState('')

    useEffect(()=>{
        if(status === 'finished') {
            setSymbol("✅")
            setButtonStyle('border shadow-brand-button hover:bg-secondary focus:outline-none focus-visible:ring focus-visible:ring-secondary focus-visible:ring-opacity-75')
        } else if(status === 'inProgress'){
            setSymbol("🛫")
            setButtonStyle('bg-info border shadow-brand-button hover:bg-secondary hover:text-white focus:outline-none focus-visible:ring focus-visible:ring-neutral focus-visible:ring-opacity-75')
        } else if(status === 'wait'){
            setSymbol("😴")
            setButtonStyle('bg-gray-100 border shadow-brand-button')
            setTitleStyle('text-gray-500')
            setIconStyle('text-gray-500')
        }

        if(status !== 'wait'){
            setTitleStyle('text-primary')
            setIconStyle('text-primary')
        }

    }, [status])

    return (

        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button
                        disabled={status === 'wait'}
                        className={`${buttonStyle} h-16 flex w-full space-x-4 justify-start items-center rounded-lg px-4 py-2 text-left text-sm font-medium text-purple-900`}
                    >
                        <Emoji symbol={symbol} />
                        <span className={`${titleStyle} flex-1`}>Day {day}</span>
                        {status !== 'wait' && results >= 0 && (
                            <div
                                className="radial-progress text-primary"
                                style={{ "--value": results, "--size": "50px", "--thickness": "4px"}}
                            >
                                <span className={"text-2xs p-1"}>{results}%</span>
                            </div>
                        )}
                        {status !== 'wait' && (
                            <FaChevronUp
                                className={`${
                                    open ? 'rotate-180 transform' : ''
                                } h-5 w-5 ${iconStyle}`}
                            />
                        )}
                    </Disclosure.Button>
                    <Disclosure.Panel className="pt-4 pb-2 text-sm space-y-5 text-gray-500">
                        {homeworks && homeworks.map((homework, index)=>{

                            const allAssignments = homework.answers.allAssignments ? homework.answers.allAssignments : 0
                            const correctAnswer = homework.answers.correctAnswer ? homework.answers?.correctAnswer : 0

                            return (
                                <AssignmentCard
                                    key={uuidv4()}
                                    number={index+1}
                                    title={homework.title}
                                    type={homework.config.type}
                                    link={`/homework/${homework.id}`}
                                    all={allAssignments}
                                    correct={correctAnswer}
                                    status={correctAnswer + allAssignments === 0 ? 'wait' : 'completed'}
                                />
                            )
                        })}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

DisclosureComponent.propTypes = {
    status: PropTypes.string,
    day: PropTypes.number,
    results: PropTypes.number,
    homeworks: PropTypes.array
}

export default DisclosureComponent
