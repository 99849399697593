import React, {useEffect, useRef, useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";

import {cloudpaymentsPublicKey, stripeCurrencies} from "../../../helpers/constants";

import {useAuth} from "../../../context/AuthContext";
import usePaymentProcessor from "../../../helpers/PaymentProcessor";
import {SET_NEED_UPDATE_BALANCE_HISTORY} from "../../../redux/actions/billing-actions";
import Spinner from "../Spinner";

import Symbol from "../../../assets/symbols/percentage.svg"
import {NEED_UPDATE_USER_INFO} from "../../../redux/actions/user-actions";
import ReactCardFlip from "react-card-flip";
import Handlebars from "../../../helpers/TemplateHelper";


const SpecialOfferCard = ({
                              userInfo,
                              offer,
                              priceItems,
                              setNeedUpdateBalanceHistory,
                              setNeedUpdateUserInfo,
}) => {

    const { currentUser } = useAuth()
    const { processPayment } = usePaymentProcessor();

    const actionElementRef = useRef(null)
    const cardElementRef = useRef(null)
    const readMoreElementRef = useRef(null)
    const offerTermsRef = useRef(null)

    const [isFlipped, setIsFlipped] = useState(false);
    const [loading, setLoading] = useState(false)
    const [actionElementWidth, setActionElementWidth] = useState(0)
    const [cardElementWidth, setCardElementWidth] = useState(0)
    const [defaultPrice, setDefaultPrice] = useState(0)
    const [processingType, setProcessingType] = useState('')

    const [canPaid, setCanPaid] = useState(false)

    function handleFlip(event) {
        event.stopPropagation()
        setIsFlipped(!isFlipped);
    }


    useEffect(()=>{

        if(offerTermsRef.current && offer.terms && offer.price && defaultPrice && userInfo){
            const template = Handlebars.compile(`${offer.terms}`)

            const templateVariables = {
                offerPrice: offer.price.unitAmount,
                defaultPrice: defaultPrice,
                offerCurrency: userInfo.profile.currency.toUpperCase(),
                offerQuantity: offer.quantity
            }

            offerTermsRef.current.innerHTML = template(templateVariables)
        }
    },[offerTermsRef, offer, defaultPrice, userInfo])

    useEffect(() => {
        return ()=>{
            setCanPaid(false)
        }
    }, []);

    useEffect(() => {
        if(userInfo) {
            setCanPaid(userInfo.isAccountSynced)
        }
    }, [userInfo])

    useEffect(()=>{
        if(actionElementRef.current){
            setActionElementWidth(actionElementRef.current.clientWidth)
        }
    }, [actionElementRef])

    useEffect(()=>{
        if(cardElementRef.current){
            setCardElementWidth(cardElementRef.current.clientHeight)
        }
    }, [actionElementRef])

    useEffect(() => {
        if(priceItems && userInfo){
            setDefaultPrice(priceItems[`${userInfo.profile.currency}`].toFixed(2))
            setProcessingType(stripeCurrencies.includes(userInfo?.profile?.currency) ? 'stripe' : 'cloudPayments')
        }
    }, [priceItems, userInfo]);

    async function handleSpecialOfferAction () {
        await processPayment({
                provider: processingType,
                setLoading: setLoading,
                weeks: offer.quantity,
                isNewCustomer: userInfo.isNewCustomer,
                userInfo: userInfo,
                priceItems: priceItems,
                currentUser: currentUser,
                setNeedUpdateBalanceHistory: setNeedUpdateBalanceHistory,
                setNeedUpdateUserInfo: setNeedUpdateUserInfo,
                cloudpaymentsPublicKey: cloudpaymentsPublicKey,
                defaultPrice: defaultPrice,
                returnUrl: window.location.href,
                offer: offer
            }
        );
    }

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
            <div
                ref={cardElementRef}
                onClick={canPaid && processingType !== '' ? handleSpecialOfferAction : null}
                className={`shadow card z-40 min-h-28 bg-blue ${!canPaid && processingType === '' ? 'opacity-20' : 'cursor-pointer'} ${!userInfo && loading ? 'animate-pulse' : ''}`}
            >
                <img
                    src={Symbol}
                    alt={'Symbol'}
                    className={"z-10 object-none absolute top-[-2px] left-[-2px] opacity-30"}
                    style={{height: `${cardElementWidth + 3}px`}}
                />
                <div className={"flex flex-col justify-between space-y-2 min-h-28"}>
                    {/* HEADER */}
                    <div className={"flex flex-row justify-between items-center px-3 pt-3 pb-1"}>
                        <span className="text-base font-normal">
                            {offer.displayName}
                        </span>
                        <div className={"flex flex-row flex-wrap justify-end items-center space-x-1"}>
                            <span className={"text-red-800"}>
                                {offer.price.unitAmount * offer.quantity} {userInfo.profile.currency.toUpperCase()}
                            </span>
                            <span className={"text-sm line-through decoration-2"}>
                                {defaultPrice * offer.quantity} {userInfo.profile.currency.toUpperCase()}
                            </span>
                        </div>
                    </div>

                    {/* BODY */}
                    <div
                        className={`z-30 flex flex-row justify-between items-end pl-3 w-full space-x-3 ${loading ? 'animation-pulse' : ''}
                        ${canPaid && processingType !== '' ? 'opacity-90' : ''}`}
                    >
                        <div className={"flex-1 h-full pb-3"}>
                            <p
                                className={"text-base font-bold line-clamp-3 ms:line-clamp-2"}
                            >
                                {offer.description + " "}
                                {offer.terms && (
                                    <span
                                        className={"inline z-30 text-black underline underline-offset-2 decoration-2 decoration-solid decoration-red-800"}
                                        ref={readMoreElementRef}
                                        onClick={handleFlip}
                                    >Read more</span>
                                )}
                            </p>

                        </div>
                        <div
                            ref={actionElementRef}
                            style={loading ? {width: actionElementWidth} : {}}
                            onClick={canPaid && processingType !== '' ? handleSpecialOfferAction : null}
                            className={`p-0 bg-dark items-center justify-center flex flex-row rounded-none
                            rounded-br-[2.5rem] rounded-tl-[100px] w-24 ${canPaid && processingType !== '' ? 'cursor-pointer' : ''}`}
                        >
                            {loading ? (
                                <span className={"pb-2.5 pt-2.5 pl-2.5 text-white"}>
                                    <Spinner/>
                                </span>
                            ) : (
                                <span
                                    className={"pb-2.5 pt-2.5 pl-2.5 text-white"}
                                >
                                    {`GET ${offer.quantity}`}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {offer.description && (
                <div
                    ref={offerTermsRef}
                    onClick={handleFlip}
                    className={`text-[0.95rem] card p-4 shadow z-40 min-h-28 bg-blue ${!canPaid && processingType === '' ? 'opacity-20' : 'cursor-pointer'}
                    ${!userInfo && loading ? 'animate-pulse' : ''}`}
                />
            )}
        </ReactCardFlip>
    )
}

SpecialOfferCard.propTypes = {
    offer: PropTypes.object,
    onClick: PropTypes.func,
    userInfo: PropTypes.object,
    priceItems: PropTypes.object,
    setNeedUpdateBalanceHistory: PropTypes.func,
    setNeedUpdateUserInfo: PropTypes.func
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    priceItems: state.preference.priceItems,
})

const mapDispatchToProps = (dispatch) => ({
    setNeedUpdateBalanceHistory: (status) => dispatch({type: SET_NEED_UPDATE_BALANCE_HISTORY, payload: status}),
    setNeedUpdateUserInfo: (status) => dispatch({type: NEED_UPDATE_USER_INFO, payload: status})
})

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOfferCard)