import React, {
    useEffect, useRef,
    useState,
} from 'react'
import {
    Routes,
    Route,
    useLocation,
} from "react-router-dom";

import {connect} from "react-redux";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {getCookieConsentValue} from "react-cookie-consent";
import {useGTMDispatch} from "@elgorditosalsero/react-gtm-hook";

import {useAuth} from "../context/AuthContext";
import {usePrevious} from "../hooks/usePrevious";

import Login from "./auth/Login";
import Logout from "./auth/Logout";
import VerifyEmail from "./auth/VerifyEmail";
import Holiday from "./Holiday";
import ResetPassword from "./auth/ResetPassword";
import CookiesPolicy from "./cookies";
import Welcome from "./welcome";
import Referrals from "./Referrals";
import Hello from "./Hello";
import SprintSummary from "./summary/SprintSummary";
import DailySummary from "./summary/DailySummary";
import Homework from "./homework";
import Challenges from "./challenges";
import Account from "./account";
import Billing from "./billing";
import StatsScreen from "./stats";

import Header from "./components/Header";
import Footer from "./components/Footer";
import ThanksModal from "./components/ThanksModal";
import PrivateRoute from "../routers/PrivateRoute";
import CookieConsentMessage from "./components/CookieConsentMessage";

import {stringToBoolean} from "../helpers/validation";
import {getSHA256string} from "../helpers/utils";
import {authPaths, isProduction} from "../helpers/constants";

import {
    GET_USER_INFO,
} from "../redux/actions/user-actions";

import {GET_SPRINT} from "../redux/actions/configurator-actions";

import {
    CAN_INJECT_GTM_SCRIPT,
    SET_GTM_DATA, SET_NEAREST_ENROLLMENT_SPRINT_DATE,
    SET_PAGE_BACKGROUND_REQUESTED
} from "../redux/actions/common-actions";

import {
    GET_OPTIONS,
    SET_OPTIONS
} from "../redux/actions/preference-actions";
import Test from "./components/test/Test";

const Main = ({
                  options,
                  pageBackground,
                  userInfo,
                  getSprint,
                  weekNumber,
                  currentSprint,
                  displayMode,
                  setOptions,
                  getOptions,
                  footerHeight,
                  setGtmData,
                  canInjectGtmScript,
                  gtmData,
                  setPageBackground,
                  needUpdateUserInfo,
                  getUserInfo,
                  thanksModalIsOpen,
                  setNearestEnrollmentSprintDate,
                  isWeekEnd,
                  currentMonday,
                  nextMonday,
                  needUpdateSprint
}) => {

    const { currentUser } = useAuth()
    const location = useLocation()
    const sendDataToGTM = useGTMDispatch()
    const cookiesConsent = getCookieConsentValue("CookieConsent")

    const mainContainerRef = useRef(null)

    const [showHoliday, setShowHoliday] = useState(false)
    const prevUserInfo = usePrevious(userInfo)

    const pathname = location.pathname
    const prevPathname = usePrevious(pathname)

    const [canSendGtm, setCanSendGtm] = useState(false)
    const [hasSentGtm, setHasSentGtm] = useState(false);

    const [showHeader, setShowHeader] = useState(false)
    const [showFooter, setShowFooter] = useState(false)

    useEffect(()=>{
        if(needUpdateUserInfo && currentUser !== null){
            getUserInfo(currentUser.uid)
        }
    }, [needUpdateUserInfo, currentUser])

    useEffect(()=>{
        if(needUpdateSprint && currentUser !== null){
            getSprint(weekNumber)
        }
    },[needUpdateSprint, currentUser])

    useEffect(() => {
        if(cookiesConsent === undefined && location.pathname !== '/cookies-policy') {
            toast(<CookieConsentMessage />, {
                autoClose: false,
                closeOnClick: false,
                toastId: "cookieConsent",
                closeButton: false
            })
        }
    }, [cookiesConsent, location.pathname]);

    // FOR GTM ANALYTICS
    useEffect(() => {

        if(pathname && prevPathname !== '/logout' && pathname !== '/logout') {
            isProduction && sendDataToGTM({
                event: 'ga4event',
                event_category: 'page_view'
            });
        }


    }, [pathname, sendDataToGTM]);

    // FOR GTM ANALYTICS
    useEffect(()=>{

        const gtmObject = {}

        if(userInfo && prevUserInfo){

            // CHECK account_sync EVENT
            const prevProfileEmpty = !prevUserInfo.profile.instagram && !prevUserInfo.profile.email && !prevUserInfo.profile.currency
            const isProfileSync = userInfo.profile.instagram !== null && userInfo.profile.email !== null && userInfo.profile.currency !== null

            if(prevProfileEmpty && isProfileSync) {
                gtmObject.is_account_synced = true

                isProduction && sendDataToGTM({
                    user_id: userInfo.uid,
                    event: 'ga4event',
                    event_category: 'account_sync'
                });
            }

            // Init dataLayer or push data
            gtmObject.user_id = userInfo.uid
            gtmObject.email = getSHA256string(userInfo.profile.email)

            if (userInfo.gtm !== undefined) {
                gtmObject.is_student = userInfo.gtm.isStudent
                gtmObject.is_customer = userInfo.gtm.isCustomer
            }

            if(!prevProfileEmpty && isProfileSync) {
                gtmObject.is_account_synced = true
            }

            if(prevProfileEmpty && !isProfileSync) {
                gtmObject.is_account_synced = false
            }

            // if after init DataLayer
            if(gtmData.injectScript === true) {
                isProduction && sendDataToGTM(gtmObject)
            } else {
                // if init DataLayer (first page loading)
                gtmObject.login = true
                setGtmData(gtmObject)
                isProduction && setCanSendGtm(true);
            }

        }

    }, [userInfo, prevUserInfo, currentUser, gtmData.injectScript, sendDataToGTM, setGtmData])

    // FOR GTM ANALYTICS
    useEffect(() => {
        if (canSendGtm && !hasSentGtm) {
            if(gtmData.injectScript === false) {
                isProduction && canInjectGtmScript(true)
            }
            setHasSentGtm(true);
        }
    }, [canSendGtm, hasSentGtm, gtmData.injectScript, canInjectGtmScript]);

    // FOR GTM ANALYTICS
    useEffect(() => {
        if(pathname === '/login' && !currentUser) {
            setGtmData({login: false})
            isProduction && setCanSendGtm(true);
        }
    }, [canInjectGtmScript, gtmData.injectScript, pathname]);
    // FOR GTM ANALYTICS END

    // STAY HERE
    useEffect(()=> {
        if(options && options.length > 0) {

            const sprintSchedule = options.find(option => option.code === 'sprint_schedule')
            const isHoliday = !stringToBoolean(sprintSchedule.value)
            setShowHoliday(isHoliday)

            if(!isHoliday) {
                getSprint(weekNumber)
            }
        }
    }, [getSprint, options, showHoliday, weekNumber])

    useEffect(()=>{
        document.getElementById('body').style = `background: ${pageBackground.background};`
    }, [pageBackground])

    useEffect(() => {
        if(pathname !== '/welcome' && pathname !== '/'){
            setPageBackground({background: '#fff7ef'})
        } else {
            setPageBackground({background: '#ffffff'})
        }

    }, [pathname]);

    useEffect(() => {
        if(currentUser != null && pathname !== '/logout') {
            getOptions()
        }

        // Очистка интервала при размонтировании компонента
        return () => {
            setOptions(null)
        };
    }, [currentUser]);

    useEffect(()=>{
        setShowFooter(pathname === '/' || pathname==='/welcome')
        setShowHeader(pathname === '/' || pathname==='/welcome' || (!authPaths.includes(pathname) &&  pathname !== "/test"))
    },[pathname])

    useEffect(() => {
        if(currentSprint){
            const isEnrolled = currentSprint.enrolled

            if(isWeekEnd) {
                setNearestEnrollmentSprintDate(nextMonday)
            } else {
                if(isEnrolled){
                    setNearestEnrollmentSprintDate(nextMonday)
                } else {
                    setNearestEnrollmentSprintDate(currentMonday)
                }
            }

        }
    }, [currentSprint]);

    return (
        <div
            ref={mainContainerRef}
            className={"flex flex-col min-h-screen w-full xs:max-w-laptop relative"}
            style={{paddingBottom: footerHeight + 20}}
        >
            {showHeader && <Header/>}
            <Routers
                mainContainerRef={mainContainerRef}
                showHoliday={showHoliday}
                currentSprint={currentSprint}
                displayMode={displayMode}
            />
            {showFooter && <Footer/>}
            <ThanksModal isOpen={thanksModalIsOpen}/>
        </div>
    )
}

Main.propTypes = {
    userInfo: PropTypes.object,
    getUserInfo: PropTypes.func,
    needUpdateUserInfo: PropTypes.bool,
    needUpdateSprint: PropTypes.bool,
    getSprint: PropTypes.func,
    weekNumber: PropTypes.string,
    displayMode: PropTypes.string,
    footerHeight: PropTypes.number,
    canInjectGtmScript: PropTypes.func,
    pageBackground: PropTypes.object,
    currentSprint: PropTypes.object,
    getOptions: PropTypes.func,
    setOptions: PropTypes.func,
    options: PropTypes.array,
    loadingOptions: PropTypes.bool,
    setGtmData: PropTypes.func,
    gtmData: PropTypes.object,
    setPageBackground: PropTypes.func,
    thanksModalIsOpen: PropTypes.bool,
    setNearestEnrollmentSprintDate: PropTypes.func,
    currentMonday: PropTypes.any,
    nextMonday: PropTypes.any,
    isWeekEnd: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    pageBackground: state.common.pageBackground,
    currentSprint: state.config.currentSprint,
    currentMonday: state.common.currentMonday,
    nextMonday: state.common.nextMonday,
    isWeekEnd: state.common.isWeekEnd,
    weekNumber: state.common.weekNumber,
    userInfo: state.user.userInfo,
    needUpdateUserInfo: state.user.needUpdateUserInfo,
    needUpdateSprint: state.config.needUpdateSprint,
    options: state.preference.options,
    loadingOptions: state.preference.loadingOptions,
    footerHeight: state.common.footerHeight,
    gtmData: state.common.gtmData,
    thanksModalIsOpen: state.billing.thanksModalIsOpen,
})

const mapDispatchToProps = (dispatch) => ({
    getUserInfo: (uid) => dispatch({ type: GET_USER_INFO, payload: uid }),
    getSprint: (weekNumber) => dispatch({ type: GET_SPRINT, payload: weekNumber }),
    getOptions: (params) => dispatch({type: GET_OPTIONS, payload: params}),
    setOptions: (options) => dispatch({type: SET_OPTIONS, payload: options}),
    setGtmData: (gtmData) => dispatch({ type: SET_GTM_DATA, payload: gtmData }),
    canInjectGtmScript: (status) => dispatch({ type: CAN_INJECT_GTM_SCRIPT, payload: status }),
    setPageBackground: (background) => dispatch({ type: SET_PAGE_BACKGROUND_REQUESTED, payload: background }),
    setNearestEnrollmentSprintDate: (date) => dispatch({ type: SET_NEAREST_ENROLLMENT_SPRINT_DATE, payload: date })
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)

const Routers = ({
                     showHoliday,
                     currentSprint,
                     displayMode,
                     mainContainerRef
}) => {

    if (showHoliday){
        return (
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/logout" element={<Logout />} />
                <Route exact path="*" element={<Holiday />} />
            </Routes>
        )
    } else {
        return (
            <Routes>
                {['/', '/welcome'].map((path, index) => (
                    <Route path={path}
                           element={<PrivateRoute><Welcome mainContainerRef={mainContainerRef}/></PrivateRoute>}
                           key={index}/>

                ))}
                <Route path={'/sprint'} element={<PrivateRoute><SprintSummary/></PrivateRoute>}/>
                <Route path={'/day'} element={<PrivateRoute><DailySummary/></PrivateRoute>}/>
                <Route exact path="/homework/:homeworkId" element={<PrivateRoute><Homework/></PrivateRoute>}/>
                <Route exact path="/reset/:uid/:token" element={<ResetPassword/>}/>
                {/*<Route path="/talks" element={<PrivateRoute><EisTalks /></PrivateRoute>} />*/}
                <Route path="/challenges" element={<PrivateRoute><Challenges/></PrivateRoute>}/>
                <Route path="*" element={<PrivateRoute><Hello/></PrivateRoute>}/>
                <Route exact path="/account" element={<PrivateRoute><Account sprint={currentSprint}
                                                                             displayMode={displayMode}/></PrivateRoute>}/>
                <Route exact path="/billing" element={<PrivateRoute><Billing/></PrivateRoute>}/>
                <Route exact path="/stats"
                       element={<PrivateRoute><StatsScreen displayMode={displayMode}/></PrivateRoute>}/>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/logout" element={<Logout/>}/>
                <Route exact path={"/verify-email"} element={<VerifyEmail/>}/>
                <Route exact path={"/referrals"} element={<Referrals/>}/>
                <Route exact path={"/cookies-policy"} element={<CookiesPolicy/>}/>
                <Route exact path={"/test"} element={<Test />}/>
            </Routes>
        )

    }
}

Routers.propTypes = {
    showHoliday: PropTypes.bool,
    displayMode: PropTypes.string,
    currentSprint: PropTypes.object,
    mainContainerRef: PropTypes.any
}