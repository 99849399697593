import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import HistoryTable from "./HistoryTable"
import Pagination from "./Pagination";
import {GET_BALANCE_HISTORY, SET_NEED_UPDATE_BALANCE_HISTORY} from "../../../../redux/actions/billing-actions";

const History = ({
                     userInfo,
                     pageSize,
                     getBalanceHistory,
                     needUpdateBalanceHistory,
                     billingTotalRecords
}) => {

    const [page, setPage] = useState(1)
    const [size, setSize] = useState(pageSize)

    // const [localBillingTotalRecords, setLocalBillingTotalRecords] = useState(false)
    // const [localBalanceHistoryLoading, setLocalBalanceHistoryLoading] = useState(false)
    //
    // const [loading, setLoading] = useState(false)
    //
    // useEffect(() => {
    //     setLocalBillingTotalRecords(billingTotalRecords)
    //     setLocalBalanceHistoryLoading(getBalanceHistoryLoading)
    // }, [billingTotalRecords, getBalanceHistoryLoading]);

    const onPageChanged = data => {
        const { currentPage, pageLimit } = data;

        setPage(currentPage)
        setSize(pageLimit)

        const query = {
            'page_size': pageLimit,
            'page': currentPage
        }

        getBalanceHistory({
            'query': query,
            'uid': userInfo.uid
        })
    }

    useEffect(()=>{

       if(needUpdateBalanceHistory === true) {
           // setLoading(true)
           const query = {
               'page_size': size,
               'page': page
           }

           getBalanceHistory({
               'query': query,
               'uid': userInfo.uid
           })
       }

    }, [getBalanceHistory, needUpdateBalanceHistory, page, size, userInfo.uid])

    return (
        <React.Fragment>
            <div className={"flex flex-col mt-5 space-y-4"}>
                {/*<span className={"prose"}>Next write-off date 21-08-2022</span>*/}
                {/*<span className={"prose font-semibold text-brandGray"}>Billing history</span>*/}
                <>
                    <HistoryTable />
                    <div className="flex flex-grow-1 justify-center mt-4 pb-10">
                        <Pagination
                            totalRecords={billingTotalRecords}
                            pageLimit={pageSize}
                            pageNeighbours={1}
                            onPageChanged={onPageChanged}
                        />
                    </div>
                </>
                {/*{!getBalanceHistoryLoading || !loading ? (*/}
                {/*    <>*/}
                {/*        <HistoryTable />*/}
                {/*        <div className="flex flex-grow-1 justify-center mt-4 pb-10">*/}
                {/*            <Pagination*/}
                {/*                totalRecords={billingTotalRecords}*/}
                {/*                pageLimit={pageSize}*/}
                {/*                pageNeighbours={1}*/}
                {/*                onPageChanged={onPageChanged}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </>*/}
                {/*) : (*/}
                {/*    <Spinner />*/}
                {/*)}*/}
            </div>
        </React.Fragment>
    )
}

History.propTypes = {
    userInfo: PropTypes.object,
    balanceHistory: PropTypes.array,
    needUpdateBalanceHistory: PropTypes.bool,
    billingTotalRecords: PropTypes.number,
    pageSize: PropTypes.number,
    getBalanceHistory: PropTypes.func,
    getBalanceHistoryLoading: PropTypes.bool
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    balanceHistory: state.billing.balanceHistory,
    billingTotalRecords: state.pagination.billingTotalRecords,
    needUpdateBalanceHistory: state.billing.needUpdateBalanceHistory,
    getBalanceHistoryLoading: state.billing.getBalanceHistoryLoading,
    pageSize: state.pagination.pageSize
})

const mapDispatchToProps = (dispatch) => ({
    getBalanceHistory: (params) => dispatch({ type: GET_BALANCE_HISTORY, payload: params }),
    setNeedUpdateBalanceHistory: (status) => dispatch({ type: SET_NEED_UPDATE_BALANCE_HISTORY, payload: status }),
})

export default connect(mapStateToProps, mapDispatchToProps)(History)
