import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {BsThreeDots} from "react-icons/bs";
import { Menu, Transition } from '@headlessui/react'
import {
    DETACH_PAYMENT_METHOD,
    UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD
} from "../../../redux/actions/payment-method-actions";
import Spinner from "../../components/Spinner";

const PaymentMethodActions = ({
                                  paymentMethodId,
                                  detachPaymentMethod,
                                  updateSubscriptionDefaultPaymentMethod,
                                  detachPaymentMethodLoading,
                                  updateDefaultMethodLoading,
                                  userSubscription,
                                  isDefault,
}) => {

    const [subscription, setSubscription] = useState(null)
    const [actionState, setActionState] = useState('init')
    // eslint-disable-next-line no-unused-vars
    const [open, setOpen] = useState(false)

    const deletePaymentMethod = (event) => {
        event.preventDefault()
        setActionState('pending')
        if(!isDefault) {
            detachPaymentMethod(paymentMethodId)
        }
    }

    const setDefaultPaymentMethod = (event) => {
        event.preventDefault()
        if(subscription){
            updateSubscriptionDefaultPaymentMethod({
                paymentMethodId: paymentMethodId,
                subscriptionId: subscription.id,
                subscriptionType: subscription.object
            })
        }
    }

    useEffect(()=>{
        if(userSubscription) {
            setSubscription(userSubscription.subscription)
        }
    }, [userSubscription])

    useEffect(()=>{
        if((!updateDefaultMethodLoading || !detachPaymentMethodLoading) && actionState === 'pending'){
            setActionState('complete')
            setOpen(false)
        }

    }, [updateDefaultMethodLoading, detachPaymentMethodLoading, actionState])

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <Menu.Button
                        onClick={()=> setOpen(!open)}
                        className="btn btn-square btn-ghost btn-sm"
                    >
                        <BsThreeDots className={"w-6 h-6"} />
                    </Menu.Button>
                    {open && (
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 mt-1 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="px-1 py-1 ">
                                    {subscription ? (
                                        <Menu.Item>
                                            <button
                                                onClick={setDefaultPaymentMethod}
                                                className={`group flex w-full items-center rounded-md px-2 py-2 text-base hover:bg-brandYellow justify-between`}
                                            >
                                                Make default
                                                {updateDefaultMethodLoading ? <Spinner />: null}
                                            </button>
                                        </Menu.Item>
                                    ) : null}
                                    <Menu.Item>
                                        <button
                                            onClick={deletePaymentMethod}
                                            className={`text-brandRed group flex w-full items-center rounded-md px-2 py-2 text-base hover:bg-brandYellow justify-between`}
                                        >
                                            Delete
                                            {detachPaymentMethodLoading ? <Spinner />: null}
                                        </button>
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    )}

                </>
            )}

        </Menu>
    )
}

PaymentMethodActions.propTypes = {
    paymentMethodId: PropTypes.string,
    userSubscription: PropTypes.object,
    paymentMethod: PropTypes.object,
    detachPaymentMethod: PropTypes.func,
    updateSubscriptionDefaultPaymentMethod: PropTypes.func,
    updateDefaultMethodLoading: PropTypes.bool,
    detachPaymentMethodLoading: PropTypes.bool,
    isDefault: PropTypes.bool
}

const mapStateToProps = (state) => ({
    userSubscription: state.billing.userSubscription,
    detachPaymentMethodLoading: state.paymentMethod.detachPaymentMethodLoading,
    updateDefaultMethodLoading: state.paymentMethod.updateDefaultMethodLoading
})

const mapDispatchToProps = (dispatch) => ({
    detachPaymentMethod: (paymentMethodId) => dispatch({ type: DETACH_PAYMENT_METHOD, payload: paymentMethodId }),
    updateSubscriptionDefaultPaymentMethod: (params) => dispatch({ type: UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD, payload: params })
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodActions)
