// combineReducers come from redux that used for combining reducers that we just made.
import { combineReducers } from 'redux'

// Reducers
import auth from './auth-reducer'
import user from "./user-reducer";
import billing from "./billing-reducer";
import config from './configurator-reducer'
import message from './message-reducer'
import common from './common-reducer'
import pagination from './pagination-reducer'
import paymentMethod from './payment-method-reducer'
import preference from './preference-reduser'
import gift from "./gift-reduser";
import challenge from "./challenge-reducer";
import achievement from './achievement-reducer';
import stories from './stories-reducer'
import notification from "./notification-reducer";

import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';


const userPersistConfig = {
    key: 'user',
    storage: storage,
    blacklist: ['lock' ,'updateProfileLoading', 'checked']
}

export default combineReducers({
    auth,
    user: persistReducer(userPersistConfig, user),
    billing,
    message,
    notification,
    config,
    common,
    pagination,
    paymentMethod,
    preference,
    gift,
    challenge,
    achievement,
    stories
})
