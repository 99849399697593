import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {withUseFormHook} from "../../../helpers/with-form-hook";
import {Link} from "react-router-dom";

const AccountHeader = ({
                           userInfo,
                           currentSprint,
                           setOpenPutOnHoldModalState,

}) => {

    const [hasSubscription, setHasSubscription] = useState(false)
    const [canPaid, setCanPaid] = useState(false)
    const [weeks, setWeeks] = useState(0)
    const [isActiveProfile, setIsActiveProfile] = useState(false)

    useEffect(() => {
        if(userInfo){
            setHasSubscription(userInfo.userPaymentInfo?.hasSubscription)
            setCanPaid(userInfo.isAccountSynced)
            setWeeks(userInfo.balance.weeks)
            setIsActiveProfile(userInfo.profile.status === 'active')
        }
    }, [userInfo]);

    return (
        <React.Fragment>
            { canPaid ? (
                <div className={`${!canPaid ? 'mb-4' : ''} w-full`}>
                    {weeks > 0 ? (
                        <div className={`flex flex-row items-start justify-between w-full`}>
                            <div className={"flex flex-col"}>
                                <h1 className={"text-neutral prose"}>Put your weeks on hold</h1>
                                <div className={"text-xs mb-1 leading-5 text-gray-500"}>
                                    {!hasSubscription ? (
                                        <span>You will not be added into the group&nbsp;
                                            <span
                                                className={"underline underline-offset-2 decoration-2 decoration-pink-500 inline"}
                                            >
                                                    {currentSprint?.enrolled ? 'next week' : 'this week'}
                                                </span>. Switch it off when you want to start your lessons again.</span>
                                    ) : (
                                        <span>You need to pause or cancel your subscription to stop your lessons</span>
                                    )}

                                </div>
                            </div>
                            {!hasSubscription && (
                                <label className="flex items-center cursor-pointer ml-4">
                                    <div className="relative">
                                        <input
                                            type="checkbox"
                                            className="toggle toggle-error"
                                            onChange={() => {
                                                setOpenPutOnHoldModalState(true)
                                            }}
                                            checked={!isActiveProfile}
                                        />
                                    </div>
                                </label>
                            )}
                        </div>
                    ) : (
                        <div role="alert" className="alert alert-info shadow w-full text-start text-sm">
                            <span>Now you can <Link to={'/billing'} className={"text-orange after:content-['_↗']"}>pay</Link> for your first week.</span>
                        </div>
                    )}
                </div>
            ) : (
                <div role="alert" className="alert alert-info shadow w-full text-start text-sm">
                    <span>Please complete the profile before you start.</span>
                </div>
            )}

        </React.Fragment>
    )
}

AccountHeader.propTypes = {
    userInfo: PropTypes.object,
    currentSprint: PropTypes.object,
    setOpenPutOnHoldModalState: PropTypes.func,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    currentSprint: state.config.currentSprint,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(AccountHeader))
