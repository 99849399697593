import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CountryDropdown } from 'react-country-region-selector';
import {withUseFormHook} from "../../../helpers/with-form-hook";
import {UPDATE_STUDENT_PROFILE} from "../../../redux/actions/user-actions";
import userApi from "../../../redux/api/user-api";
import validator from 'validator';
import {useForm} from "react-hook-form";


const Profile = ({
                     userUid,
                     country,
                     email,
                     currency,
                     instagram,
                     setCountry,
                     setEmail,
                     setCurrency,
                     setInstagram,
                     updateStudentProfile,
                     updateProfileLoading,
                     hasSubscription,
                     isSaveButton,
                     setIsSaveButton,
                     loadingUserInfo
}) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({ mode: 'onChange' });

    const [loading, setLoading] = useState(false)

    const selectCountry = (val) => {
        setCountry(val);
    }

    const submitAccountInfo = data => {
        const email = data.email.toLowerCase().trim()
        if(validator.isEmail(email)){
            setIsSaveButton(true)
            updateStudentProfile({
                user: userUid,
                country: country,
                currency: data.currency.toLowerCase(),
                email: data.email.toLowerCase().trim(),
                instagram: data.instagram.replace(/^@/, "").toLowerCase().trim()
            })
        } else {
            setError('email', { type: 'validate', message: "Incorrect email format" });
        }
    }

    async function searchByParams(params, type){

        let profile;

        const result = await userApi.searchProfileByParams(params)

        if(result.status === 200) {
            if(result.data.results.length === 0) {
                if(type === 'email') {
                    setEmail(params.email)
                }
                if(type === 'instagram') {
                    setInstagram(params.instagram)
                    errors.instagram && errors.instagram.type === 'validate' && clearErrors("instagram")
                }
            }
            else {
                profile = result.data.results[0]
                if(profile.user === userUid){
                    if(type === 'email') {
                        setEmail(params.email)
                    }
                    if(type === 'instagram') {
                        setInstagram(params.instagram)
                    }
                } else {
                    if(type === 'email') {
                        setEmail(params.email)
                    }
                    if(type === 'instagram') {
                        setInstagram(params.instagram)
                    }
                    setError(type, { type: 'exist', message: `This ${type} already exists with another user` });
                }
            }
        } else {
            if(type === 'email') {
                setEmail(params.email)
                setError(type, { type: 'exist', message: `Could not check the ${type}. Try again later` });
            }
            if(type === 'instagram') {
                setInstagram(params.instagram)
                setError(type, { type: 'exist', message: `Could not check the ${type}. Try again later` });
            }
        }

    }

    useEffect(()=>{
        setLoading((loadingUserInfo || updateProfileLoading) && isSaveButton)
    }, [loadingUserInfo, updateProfileLoading])

    return (
        <React.Fragment>
            <div className={"mx-5 space-y-4"}>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text text-neutral prose">Email</span>
                        {!email && <span className="label-text-alt text-neutral prose">to send your receipt to</span>}
                    </label>
                    <input
                        type="email"
                        defaultValue={email}
                        className={`input input-bordered rounded-none text-neutral prose`}
                        {...register("email", {
                            required: 'Enter your email',
                            onChange: async (e) => {
                                const value = e.target.value
                                if(validator.isEmail(value)) {
                                    await searchByParams({email: value}, 'email')
                                }
                            }
                        })}
                    />
                    {errors.email &&
                        <label className="label">
                            {<span className="label-text-alt text-brandRed">{errors.email.message}</span>}
                        </label>
                    }
                </div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text text-neutral prose">Instagram</span>
                    </label>
                    <input
                        type="text"
                        defaultValue={instagram}
                        className={`input input-bordered rounded-none ${errors.instagram && 'input-error text-primary prose'}`}
                        {...register("instagram", {
                            required: 'Enter your instagram',
                            maxLength: {
                                value: 30,
                                message: "Too many characters. Max. 30"
                            },
                            onChange: async (e) => {
                                const value = e.target.value
                                if(value.length > 0) {
                                    await searchByParams({instagram: value}, 'instagram')
                                }
                            }
                        })}
                    />
                    {errors.instagram &&
                        <label className="label">
                            {<span className="label-text-alt text-neutral">{errors.instagram.message}</span>}
                        </label>
                    }
                </div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text text-neutral prose">Currency</span>
                    </label>
                    <select
                        value={currency || ''}
                        className={`select select-bordered rounded-none text-neutral prose`}
                        disabled={hasSubscription}
                        {...register("currency", {
                            required: 'Choose a currency',
                            onChange: (e) => setCurrency(e.target.value)
                        })}
                    >
                        <option disabled value={''}>Select currency</option>
                        <option className={"text-neutral prose"} value={'aud'}>Australian dollar</option>
                        <option className={"text-neutral prose"} value={'rub'}>Russian rouble</option>
                        <option className={"text-neutral prose"} value={'usd'}>US dollar</option>
                        <option className={"text-neutral prose"} value={'eur'}>Euro</option>
                    </select>


                    {errors.currency &&
                        <label className="label">
                            {<span className="label-text-alt text-secondary">{errors.currency.message}</span>}
                        </label>
                    }
                </div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text text-neutral prose">Country</span>
                    </label>
                    <CountryDropdown
                        classes={"select select-bordered rounded-none text-neutral prose"}
                        value={country || ''}
                        onChange={(val) => selectCountry(val)}
                    />
                </div>
            </div>
            <div className="flex flex-row justify-center mt-5 pb-5">
                <button
                    className={`btn btn-md btn-wide py-2 px-4 btn-primary rounded-full shadow-brand-button`}
                    onClick={handleSubmit(submitAccountInfo)}
                >
                    {loading && <span className="loading loading-spinner loading-xs"></span>}
                    <span className="font-medium">SAVE</span>
                </button>
            </div>
        </React.Fragment>
    )
}

Profile.propTypes = {
    userUid: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    currency: PropTypes.string,
    instagram: PropTypes.string,
    setCountry: PropTypes.func,
    setEmail: PropTypes.func,
    setCurrency: PropTypes.func,
    setInstagram: PropTypes.func,
    isSaveButton: PropTypes.bool,
    setIsSaveButton: PropTypes.func,
    updateProfileLoading: PropTypes.bool,
    updateStudentProfile: PropTypes.func,
    hasSubscription: PropTypes.bool,
    loadingUserInfo: PropTypes.bool
}

const mapStateToProps = (state) => ({
    updateProfileLoading: state.user.updateProfileLoading,
    loadingUserInfo: state.user.loadingUserInfo,
    hasSubscription: state.billing.hasSubscription,
})

const mapDispatchToProps = (dispatch) => ({
    updateStudentProfile: (profile) => dispatch({ type: UPDATE_STUDENT_PROFILE, payload: profile }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(Profile))
