export const GET_NOTIFICATION_SETTINGS = "GET_NOTIFICATION_SETTINGS"
export const GET_NOTIFICATION_SETTINGS_REQUEST = "GET_NOTIFICATION_SETTINGS_REQUEST"
export const GET_NOTIFICATION_SETTINGS_SUCCESS = "GET_NOTIFICATION_SETTINGS_SUCCESS"
export const GET_NOTIFICATION_SETTINGS_ERROR = "GET_NOTIFICATION_SETTINGS_ERROR"
export const SET_ENABLING_NOTIFICATION_SUBSCRIPTION = "SET_ENABLING_NOTIFICATION_SUBSCRIPTION"
export const CREATE_FCM_REGISTRATION = "CREATE_FCM_REGISTRATION"
export const CREATE_FCM_REGISTRATION_REQUEST = "CREATE_FCM_REGISTRATION_REQUEST"
export const CREATE_FCM_REGISTRATION_SUCCESS = "CREATE_FCM_REGISTRATION_SUCCESS"
export const CREATE_FCM_REGISTRATION_ERROR = "CREATE_FCM_REGISTRATION_ERROR"
export const DELETE_FCM_REGISTRATION = "DELETE_FCM_REGISTRATION"
export const DELETE_FCM_REGISTRATION_REQUEST = "DELETE_FCM_REGISTRATION_REQUEST"
export const DELETE_FCM_REGISTRATION_SUCCESS = "DELETE_FCM_REGISTRATION_SUCCESS"
export const DELETE_FCM_REGISTRATION_ERROR = "DELETE_FCM_REGISTRATION_ERROR"
export const CHANGE_NOTIFICATION_SUBSCRIPTION = "CHANGE_NOTIFICATION_SUBSCRIPTION"
export const CHANGE_NOTIFICATION_SUBSCRIPTION_REQUEST = "CHANGE_NOTIFICATION_SUBSCRIPTION_REQUEST"
export const CHANGE_NOTIFICATION_SUBSCRIPTION_SUCCESS = "CHANGE_NOTIFICATION_SUBSCRIPTION_SUCCESS"
export const CHANGE_NOTIFICATION_SUBSCRIPTION_ERROR = "CHANGE_NOTIFICATION_SUBSCRIPTION_ERROR"