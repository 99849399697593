import React, {useRef, useState} from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Spinner from "../../components/Spinner";
import UAParser from "ua-parser-js";

const QuizletTask = ({ quizletType, quizletId, quizletLink }) => {

    const parser = new UAParser()
    const browser = parser.getBrowser().name.toLowerCase()

    const params = new URLSearchParams(quizletLink)
    const quizletUserId = params.get('i')

    const [quizletLoading, setQuizletLoading] = useState(true)

    const quizletSrc = `https://quizlet.com/${quizletId}/${quizletType}/embed?i=${quizletUserId}`

    const iframeRef = useRef(null);

    const handleIframeLoad = () => {
        setQuizletLoading(false);
    };

    return (
        <React.Fragment>
            <>
                <div className={`h-full overflow-hidden`}>

                    <p className={`${browser !== 'instagram' ? 'pt-16' : 'pt-2'} mb-1 px-4 flex-1`}>Is the task not working? Open the <a
                        className="text-warning after:content-['_↗']"
                        href={quizletLink}
                        target="_blank"
                        rel="noreferrer"
                    >link</a> in your browser</p>

                    {quizletLoading ? (
                        <div className={"flex flex-row h-full justify-center items-center"}>
                            <Spinner />
                        </div>
                    ) : null}

                    <div className={"h-full flex-grow overflow-y-scroll overscroll-contain"}>
                        <iframe
                            ref={iframeRef}
                            onLoad={handleIframeLoad}
                            className={"pb-20"}
                            title={"quizlet"}
                            src={quizletSrc}
                            height="100%"
                            width="100%"
                            style={{border:0}}
                        ></iframe>
                    </div>
                </div>
            </>
        </React.Fragment>
    );
}

QuizletTask.propTypes = {
    isQuizlet: PropTypes.bool,
    quizletId: PropTypes.string,
    quizletType: PropTypes.string,
    quizletLink: PropTypes.string,
    browser: PropTypes.string
}

const mapStateToProps = (state) => ({
    isQuizlet: state.config.isQuizlet,
    quizletId: state.config.quizletId,
    quizletType: state.config.quizletType,
    quizletLink: state.config.quizletLink
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(QuizletTask)