import React, {useEffect, useRef, useState} from "react";
import { motion } from "framer-motion";
import move from "lodash-move";
import {connect} from "react-redux";
import ChallengeCard from "../cards/ChallengeCard";
import StatsCard from "../cards/StatsCard";

const CardStack = () => {

    const [isTapOnAction, setTapOnAction] = useState(false)

    const [cards, setCards] = React.useState([
        // eslint-disable-next-line react/jsx-key
        <ChallengeCard />, <StatsCard />,
    ]);

    const stackRef = useRef(null);

    const moveToEnd = from => {
        setCards(move(cards, from, cards.length - 1));
    };

    const moveToStart = from => {
        setCards(move(cards, from, 0));
    };

    useEffect(()=>{
        return () => {
            setTapOnAction(false)
        }
    }, [])

    return (
        <div className={"flex flex-col justify-center items-center"}>
            <ul ref={stackRef} className={"w-full"}>
                {cards.map((card, index) => {
                    return (
                        <motion.div
                            className={`w-full ${index===0 ? '' : 'mt-[-80px]'}`}
                            key={index}
                            animate={{
                                scale: 1,
                                zIndex: cards.length - index
                            }}
                            whileTap={{ scale: isTapOnAction ? 1 : 0.9 }}
                            onClick={() => {
                                if (index === 0) {
                                    moveToEnd(index)
                                } else {
                                    moveToStart(index)
                                }
                            }}
                        >
                            {card}
                        </motion.div>
                    );
                })}
            </ul>
        </div>
    );
};



const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CardStack)
