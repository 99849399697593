import React from 'react'
import {connect} from "react-redux";

const Skeleton = () => {

    return (
        <div className="w-full card z-40 min-h-28 bg-info">
            <div className={"animate-pulse flex flex-col justify-between space-y-2 min-h-28"}>

                {/* HEADER */}
                <div className={"flex flex-row justify-between items-center px-3 pt-3 pb-1"}>
                    <span className="text-base font-normal">
                        Your balance
                    </span>
                    <div className={"w-8 h-4 bg-slate-200 rounded"} />
                </div>

                {/* BODY */}
                <div className={"flex flex-row justify-between items-end pl-3 w-full space-x-2"}>
                    <div className={"h-12 w-48 pb-3"}>
                        <div className={"h-full w-36 ms:w-48 bg-slate-200 rounded pb-3"} />
                    </div>
                    <div
                        className={"p-0 bg-slate-200 items-center justify-center flex flex-row rounded-none " +
                            `rounded-br-[2.5rem] rounded-tl-[100px] w-24 h-10`}/>
                </div>
            </div>
        </div>
    )
}

Skeleton.propTypes = {}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Skeleton)