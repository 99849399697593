import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {withUseFormHook} from "../../helpers/with-form-hook";

import Stats from "./tabs/Stats";
import Sprints from "./tabs/Sprints";

const StatsScreen = ({
                     userInfo,
                     displayMode
                 }) => {

    const [tab, setTab] = useState('stats')

    useEffect(()=>{

        const query = new URLSearchParams(window.location.search);
        const choice = query.get("tab")

        if(choice){
            setTab(choice)
        } else if (tab === ''){
            setTab('stats')
        }

    },[])

    return (
        <React.Fragment>
            {/* TODO: Как вариант установить на ширину Header*/}
            <div  className={`h-24`} />
            <div className={`${displayMode === 'standalone' ? 'pb-20' : 'pb-10 sm:pb-20'} flex flex-col flex-1`}>
                <div role="tablist" className="tabs tabs-bordered pb-4 px-4">
                    <button onClick={() => setTab('stats')}
                            className={`tab tab-bordered ${tab === 'stats' ? 'tab-active' : ''}`}>
                        Stats
                    </button>
                    <button onClick={() => setTab('sprints')}
                            className={`tab tab-bordered ${tab === 'sprints' ? 'tab-active' : ''}`}>
                        Sprints
                    </button>
                </div>
                {
                    {
                        'stats': (
                            <Stats
                                userUid={userInfo.uid}
                            />
                        ),
                        'sprints': (
                            <Sprints
                                userUid={userInfo.uid}
                            />
                        )
                    }[tab]
                }
            </div>
        </React.Fragment>
    )
}

StatsScreen.propTypes = {
    userInfo: PropTypes.object,
    displayMode: PropTypes.string
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withUseFormHook(StatsScreen))
