export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD"
export const GET_PAYMENT_METHOD_REQUEST = "GET_PAYMENT_METHOD_REQUEST"
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS"
export const GET_PAYMENT_METHOD_ERROR = "GET_PAYMENT_METHOD_ERROR"

export const GET_ALL_PAYMENT_METHODS = "GET_ALL_PAYMENT_METHODS"
export const GET_ALL_PAYMENT_METHODS_REQUEST = "GET_ALL_PAYMENT_METHODS_REQUEST"
export const GET_ALL_PAYMENT_METHODS_SUCCESS = "GET_ALL_PAYMENT_METHODS_SUCCESS"
export const GET_ALL_PAYMENT_METHODS_ERROR = "GET_ALL_PAYMENT_METHODS_ERROR"

export const ATTACH_PAYMENT_METHOD = "ATTACH_PAYMENT_METHOD"
export const ATTACH_PAYMENT_METHOD_REQUEST = "ATTACH_PAYMENT_METHOD_REQUEST"
export const ATTACH_PAYMENT_METHOD_SUCCESS = "ATTACH_PAYMENT_METHOD_SUCCESS"
export const ATTACH_PAYMENT_METHOD_ERROR = "ATTACH_PAYMENT_METHOD_ERROR"

export const DETACH_PAYMENT_METHOD = "DETACH_PAYMENT_METHOD"
export const DETACH_PAYMENT_METHOD_REQUEST = "DETACH_PAYMENT_METHOD_REQUEST"
export const DETACH_PAYMENT_METHOD_SUCCESS = "DETACH_PAYMENT_METHOD_SUCCESS"
export const DETACH_PAYMENT_METHOD_ERROR = "DETACH_PAYMENT_METHOD_ERROR"

export const UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD = "UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD"
export const UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_REQUEST = "UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_REQUEST"
export const UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_SUCCESS = "UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_SUCCESS"
export const UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_ERROR = "UPDATE_SUBSCRIPTION_DEFAULT_PAYMENT_METHOD_ERROR"
