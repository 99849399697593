import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import AchievementTemplate from "./AchievementTemplate";
import {PiCameraLight, PiDownloadSimple} from 'react-icons/pi';
import {SET_CURRENT_ACHIEVEMENT} from "../../../../redux/actions/achievement-actions";
import { v4 as uuidv4 } from 'uuid';
import {posterHeight, posterWidth} from "../helper";
import {Canvg} from "canvg";

const ShareAchievementModal = ({achievement, modalIsOpen, setCurrentAchievement, footerHeight}) => {

    const [maxHeight, setMaxHeight] = useState(0)
    const [currentTemplate,setCurrentTemplate] = useState({index: 0, isFilter: false})
    const [svg, setSvg] = useState(null)

    function download(href, name)
    {
        let a = document.createElement('a');

        a.download = name;
        a.href = href;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const handleCaptureScreenshot = async () => {
        if (svg !== null) {
            svg.size(posterWidth, posterHeight);

            const canvas = new OffscreenCanvas(posterWidth, posterHeight)
            const context = canvas.getContext("2d");

            canvas.width = posterWidth;
            canvas.height = posterHeight;

            const svgData = svg.node.outerHTML;

            // Use canvg to convert SVG to canvas
            const v = await Canvg.fromString(context, svgData, {
                ignoreMouse: true,
                ignoreAnimation: true,
                ignoreDimensions: true,
            });

            await v.render()

            // Convert canvas to PNG
            const blob = await canvas.convertToBlob()
            const pngUrl = URL.createObjectURL(blob)

            download(pngUrl, 'eis-badge.png');

        }
    };

    useEffect(()=>{
        return () => {
            setCurrentAchievement({achievement: null, modalIsOpen: false})
        }
    }, [])

    useEffect(()=> {
        if(achievement !== null && achievement !== undefined){
            setCurrentTemplate({
                index: 0,
                isFilter: achievement.achievement.posters[0].isFilter,
                result: achievement.result
            })
        }
    }, [achievement])

    useEffect(() => {
        const calculateHeight = () => {
            const calculatedHeight = window.innerHeight; // - footerHeight;
            setMaxHeight(calculatedHeight);
        };

        calculateHeight();

        // Обработчик изменения размеров окна
        const handleResize = () => {
            calculateHeight();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [footerHeight]);

    return (
        <React.Fragment>
            <dialog
                className={`flex flex-row justify-center modal modal-top h-screen ${modalIsOpen ? 'modal-open' : null}`}
            >
                <form method={"dialog"}
                      style={{ maxHeight: maxHeight, height: maxHeight }}
                      className={`flex flex-col justify-between h-full p-5 modal-box rounded-none relative bg-white w-screen xs:max-w-laptop overflow-hidden`}
                >
                    <div>
                        <button
                            onClick={()=>setCurrentAchievement({achievement: null, modalIsOpen: false})}
                            className="btn btn-xs btn-circle btn-primary absolute right-4 top-4"
                        >
                            ✕
                        </button>
                        <div className={"flex flex-row justify-start"}>
                            <div className={"text-xl font-bold pb-4"}>Choose a template</div>
                        </div>
                    </div>
                    <div className={"flex flex-1 pt-2"}>
                        <div className="carousel carousel-center w-full h-full max-w-md space-x-4 bg-secondary rounded-box overflow-x-hidden touch-pan-x">
                            {achievement !== null && achievement.achievement.posters.map((poster, index) => {
                                if(poster.isFilter === true){
                                    return null
                                } else {
                                    return <AchievementTemplate
                                        key={poster.id}
                                        poster={poster}
                                        index={index}
                                        currentIndex={currentTemplate.index}
                                        achievement={achievement}
                                        setSvg={setSvg}
                                    />
                                }

                            })}
                        </div>
                    </div>
                    <div className="pt-2 flex flex-row justify-around items-center pb-4 mt-4">
                        <div className="flex justify-center py-2 gap-2">
                            {achievement !== null && achievement.achievement.posters.map((poster, index)=>{
                                return <a
                                    key={uuidv4()}
                                    href={`#item${index}`}
                                    className={`btn btn-sm ${currentTemplate.index === index ? 'btn-primary' : null}`}
                                    onClick={()=>{
                                        setCurrentTemplate({index: index, isFilter: poster.isFilter})
                                    }}
                                >
                                    {index + 1}
                                </a>
                            })}
                        </div>
                        <div className={"flex justify-center items-center"}>
                            {currentTemplate.isFilter ? (
                                <button
                                    className= "ring-4 ring-offset-4 ring-primary btn btn-circle"
                                    onClick={handleCaptureScreenshot}
                                ><PiCameraLight className={"w-8 h-8"}/></button>
                            ) : (
                                <button
                                    className= "ring-4 ring-offset-4 ring-primary btn btn-circle"
                                    onClick={handleCaptureScreenshot}
                                ><PiDownloadSimple className={"w-8 h-8"}/></button>
                            )}
                        </div>
                    </div>
                </form>
            </dialog>
        </React.Fragment>
    );
};

ShareAchievementModal.propTypes = {
    achievement: PropTypes.object,
    modalIsOpen: PropTypes.bool,
    footerHeight: PropTypes.number,
    setCurrentAchievement: PropTypes.func
}

const mapStateToProps = (state) => ({
    footerHeight: state.common.footerHeight,
    achievement: state.achievement.currentAchievement,
    modalIsOpen: state.achievement.shareAchievementModalIsOpen
})

const mapDispatchToProps = (dispatch) => ({
    setCurrentAchievement: ({modalIsOpen, achievement}) => dispatch({
        type: SET_CURRENT_ACHIEVEMENT,
        payload: {modalIsOpen, achievement}
    })
})

export default connect(mapStateToProps, mapDispatchToProps)(ShareAchievementModal)