import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import Emoji from "../../../components/Emoji";
import { v4 as uuidv4 } from 'uuid';
import {Link, useNavigate} from "react-router-dom";
import Skeleton from "./Skeleton";

const ChallengeCard = ({ userInfo, options }) => {

    const navigate = useNavigate()

    const [stats, setStats] = useState([])
    const [statWidgetFields, setStatWidgetFields] = useState([])
    const [canPaid, setCanPaid] = useState(false)

    const [calculationsComplete, setCalculationsComplete] = useState(false);

    useEffect(() => {
        if(userInfo && options && options.length > 0){
            const statPreference = options.find(option => option.code === 'user_stats_config')
            const statObject = JSON.parse(statPreference.value)
            setStatWidgetFields(statObject['welcome_challenge_stat_widget_config'])
        }

    }, [options, userInfo]);

    useEffect(()=>{

        if(statWidgetFields.length > 0){

            const statElements = []

            if(userInfo.challengesStats.stats.length > 0) {
                userInfo.challengesStats.stats.forEach(stat => {
                    statElements.push(
                        <div key={uuidv4()} className={"flex flex-col justify-center"}>
                            {statWidgetFields.includes('title') &&
                                <span className={"text-xl ms:text-2xl font-bold line-clamp-1"}>{stat.title}</span>}
                            {statWidgetFields.includes('value') &&
                                <span className={"text-3xl ms:text-4xl font-bold line-clamp-1"}>{stat.value}</span>}
                            {statWidgetFields.includes('description') &&
                                <span className={"text-xs ms:text-sm font-normal line-clamp-1"}>{stat.description}</span>}
                        </div>
                    )
                })
            } else {
                statElements.push(
                    <span key={uuidv4()} className={"text-sm pt-3"}>
                        {`You haven’t joined any challenges yet. `}
                        {userInfo.isAccountSynced ? (
                            <Link to={'/challenges'} className={"text-warning after:content-['_↗']"}>
                                Start here.
                            </Link>
                        ) : (
                            <span>
                                To get started, complete your profile
                                <Link to={'/account'} className={"text-warning after:content-['_↗']"}> here</Link>.
                            </span>
                        )}
                    </span>
                )
            }

            setStats(statElements)
            setCanPaid(userInfo.isAccountSynced)
        }

    },[statWidgetFields])

    useEffect(() => {
        if(stats.length > 0){
            setCalculationsComplete(true);
        }
    }, [stats]);

    return (
        <React.Fragment>
            {calculationsComplete ? (
                <div
                    className={"shadow w-full card bg-info cursor-pointer text-black min-h-32"}
                >
                    <div
                        className={`flex flex-col ${stats.length === 0 ? '' : 'justify-between'} px-3 py-3 text-black space-y-2 min-h-32`}>

                        {/* HEADER */}
                        <div className={"flex flex-row justify-between items-end pb-1"}>
                            <span className="text-base font-normal">Challenges</span>
                            {canPaid && (
                                <div
                                    className={"flex flex-row items-center space-x-2"}
                                    onClick={() => {navigate('/challenges')}}
                                >
                                    <span className="text-xs font-normal font-inter">
                                        <div className="badge badge-primary badge-outline badge-sm opacity-60">See more</div>
                                    </span>
                                    <Emoji symbol={'🏆'}/>
                                </div>
                            )}
                        </div>
                        {/* BODY */}
                        <div className={"flex flex-row space-x-4 items-center text-black"}>
                            {stats}
                        </div>
                    </div>
                </div>
            ) : (
                <Skeleton />
            )}
        </React.Fragment>

    )
}

ChallengeCard.propTypes = {
    userInfo: PropTypes.object,
    options: PropTypes.array,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    options: state.preference.options
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeCard)