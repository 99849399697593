import React, {useEffect, useState} from 'react'
import { EditText } from 'react-edit-text';

import PropTypes from "prop-types";
import {connect} from "react-redux";

import 'react-edit-text/dist/index.css';
import {toast} from "react-toastify";

import HmIcon from '../../../assets/hm.svg'
import NotBadIcon from '../../../assets/not-bad.png'
import GoodWorkIcon from '../../../assets/good-work.png'

import Player from "../Player";
import Image from "../Image";
import FillResultHeader from "./FillResultHeader";

import AchievementNotificationMessage from "../../components/AchievementNotificationMessage";

import {SET_PAGE_BACKGROUND_REQUESTED} from "../../../redux/actions/common-actions";
import UAParser from "ua-parser-js";

const FillResult = ({ setPageBackground, studentAnswers, type, isVideo, isImage, taskTitle }) => {

    const parser = new UAParser()
    const browser = parser.getBrowser().name.toLowerCase()

    const [resultState, setResultState] = useState(null)

    const [studentResultString, setStudentResultString] = useState(null)

    useEffect(()=>{

        if(studentAnswers){
            const studentResult = studentAnswers.correctAnswer / studentAnswers.allAssignments
            setStudentResultString(`${studentAnswers.correctAnswer} of ${studentAnswers.allAssignments}`)

            if(!studentResult) {
                setPageBackground({background: '#ffffff'})
            }

            if (studentResult === 1){
                setResultState('good')
                setPageBackground({background: 'linear-gradient(167.22deg, #E6FCEF 0%, #AEF9C3 100%)'})
            }
            if(studentResult >= 0.5 && studentResult < 1){
                setResultState('goodTry')
                setPageBackground({background: 'linear-gradient(167.22deg, #FFF9EF 0%, #FFDBB8 100%)'})
            }
            if (studentResult < 0.5) {
                setResultState('bad')
                setPageBackground({background: 'linear-gradient(167.22deg, #FFEFEF 0%, #FFB8B8 100%)'})
            }

            if(studentAnswers.achievement !== null && studentAnswers.achievement !== undefined) {
                toast.error(<AchievementNotificationMessage text={"Wow! You have a new badge"} />, {
                    autoClose: false,
                    icon: '🚀'
                })
            }
        }

        return () => {
            setPageBackground({background: '#ffffff'})
            setResultState(null)
        }
    }, [studentAnswers])

    return (
        <React.Fragment>
            <header className="justify-center items-center pt-10 px-4 flex flex-col w-full">
                {
                    {
                        bad: <FillResultHeader
                            headerTitle="Room for improvement!"
                            headerAvatar={HmIcon}
                            studentResultString={studentResultString}/>,
                        goodTry: <FillResultHeader
                            headerTitle="Good try!"
                            headerAvatar={NotBadIcon}
                            studentResultString={studentResultString}/>,
                        good: <FillResultHeader
                            headerTitle="Good work!"
                            headerAvatar={GoodWorkIcon}
                            studentResultString={studentResultString}/>,
                    }[resultState]
                }
            </header>
            <div className="divider w-full" />

            {type === 'fill' ? (
                <div className="flex flex-col justify-center items-center my-5 mx-4">
                    { isVideo ? (
                        <Player />
                    ) : null }

                    { isImage ? (
                        <Image />
                    ) : null }
                </div>
            ) : null }

            <div className="rounded-md card shadow-brand bg-base-100 mb-5 mx-4">
                <div className="pt-4 px-5">
                    <h2 className="text-xl font-bold card-title text-left mb-2">Correct answer</h2>
                </div>
                <div className="card-divider my-0" />
                <div className="pt-2 pb-2 px-5">
                    <div className={"flex flex-col text-justify"}>
                        {taskTitle && (
                            <div className={"font-bold pb-4"}>{taskTitle}</div>
                        )}
                        {studentAnswers.answer.content.map((row, rowIndex) => {
                            if(row.length > 0) {
                                return (
                                    <div key={`row-${rowIndex}`} className={"break-words inline-block"}>
                                        {row.map((element, elementIndex) => {
                                            if (element.isAnswer) {
                                                return <EditText
                                                    key={`element-${rowIndex}-${elementIndex}`}
                                                    name={`element-${rowIndex}-${elementIndex}`}
                                                    type="text"
                                                    style={{
                                                        width: '90px',
                                                        color: element.result !== '' ? element.color : 'teal',
                                                        opacity: 0.8,
                                                        border: "none"
                                                    }}
                                                    value={element.text}
                                                    readonly={true}
                                                    inline
                                                />
                                            } else {
                                                return element.text
                                            }
                                        })}
                                    </div>
                                )
                            } else {
                                return  <div key={`row-${rowIndex}`} className={"whitespace-pre-line py-2"} />
                            }

                        })}
                    </div>
                </div>
            </div>

            { resultState !== 'good' && (
                <div className="rounded-md card shadow-brand bg-base-100 mb-5 mx-4">
                    <div className="pt-4 px-5">
                        <h2 className="text-xl font-bold card-title text-left mb-2">Your answer</h2>
                    </div>
                    <div className="card-divider my-0" />
                    <div className="pt-2 pb-2 px-5">
                        <div className={"flex flex-col text-justify"}>
                            {taskTitle && (
                                <div className={"font-bold pb-4"}>{taskTitle}</div>
                            )}
                            {studentAnswers.answer.content.map((row, rowIndex) => {
                                if(row.length > 0) {
                                    return (
                                        <div key={`row-${rowIndex}`} className={"break-words inline-block"}>
                                            {row.map((element, elementIndex) => {
                                                if (element.isAnswer) {
                                                    return <EditText
                                                        key={`element-${rowIndex}-${elementIndex}`}
                                                        name={`element-${rowIndex}-${elementIndex}`}
                                                        type="text"
                                                        style={{
                                                            width: '90px',
                                                            color: element.isCorrect ? 'teal' : 'red',
                                                            opacity: 0.8,
                                                            border: "none"
                                                        }}
                                                        value={element.studentAnswer}
                                                        readonly={true}
                                                        inline
                                                    />
                                                } else {
                                                    return element.text
                                                }
                                            })}
                                        </div>
                                    )
                                } else {
                                    return  <div key={`row-${rowIndex}`} className={"whitespace-pre-line py-2"} />
                                }
                            })}
                        </div>
                    </div>
                </div>
            )}
            {browser === 'instagram' &&
                <p className="text-xs text-center opacity-60 mt-2 pb-20">Tap the cross in the top left corner to go back to EIS</p>
            }
        </React.Fragment>
    );

}

FillResult.propTypes = {
    type: PropTypes.string,
    browser: PropTypes.string,
    isVideo: PropTypes.bool,
    isImage: PropTypes.bool,
    taskTitle: PropTypes.string,
    studentAnswers: PropTypes.object,
    setPageBackground: PropTypes.func,
}

const mapStateToProps = (state) => ({
    type: state.config.type,
    isVideo: state.config.isVideo,
    isImage: state.config.isImage,
    taskTitle: state.config.taskTitle,
    studentAnswers: state.config.studentAnswers
})

const mapDispatchToProps = (dispatch) => ({
    setPageBackground: (background) => dispatch({ type: SET_PAGE_BACKGROUND_REQUESTED, payload: background }),
})

export default connect(mapStateToProps, mapDispatchToProps)(FillResult)