import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Emoji from "../../components/Emoji";

const Stats = ({ title, value, description, symbol }) => {

    return (
        <React.Fragment>
            <div className="stat">
                <div className="stat-figure text-secondary">
                    <div className="text-3xl">
                        <Emoji symbol={symbol} />
                    </div>
                </div>
                <div className="stat-title">{title}</div>
                <div className="stat-value text-primary">{value}</div>
                <div className="stat-desc text-primary">{description}</div>
            </div>
        </React.Fragment>
    )
}

Stats.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
    symbol: PropTypes.string,
    description: PropTypes.string
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Stats)