import {
    GET_OPTIONS_ERROR,
    GET_OPTIONS_REQUEST,
    GET_OPTIONS_SUCCESS, SET_OPTIONS,
} from "../actions/preference-actions";

import {toast} from "react-toastify";
import ToastMessage from "../../screens/components/toast";
import React from "react";

const initialState = {
    options: [],
    priceItems: null,
    loadingOptions: false,
    loadingUpdateOption: false
}

export default function preferenceReducer(state = initialState, {type, payload}) {
    switch(type) {
        case GET_OPTIONS_REQUEST: {
            return {
                ...state,
                loadingOptions: true,
            }
        }

        case GET_OPTIONS_SUCCESS:

            // eslint-disable-next-line no-case-declarations
            const pricePreference = payload?.find(option => option.code === 'price_items')
            // eslint-disable-next-line no-case-declarations
            let priceItems = null

            if(pricePreference !== undefined) {
                priceItems = JSON.parse(pricePreference.value);
            }

            return {
                ...state,
                options: payload,
                priceItems:  priceItems,
                loadingOptions: false,
            }

        case GET_OPTIONS_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Cant get preference options"} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                options: null,
                loadingOptions: false,
            }

        case SET_OPTIONS:
            return {
                ...state,
                options: payload
            }

        default:
            return state
    }
}
