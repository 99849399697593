import React, {createContext, useContext} from "react";
import {connect} from "react-redux";
import {toast, ToastContainer, Slide} from "react-toastify";

const ToastContext = createContext();

export const useToast = () => {
    return useContext(ToastContext);
};

// eslint-disable-next-line react/prop-types
const ToastProvider = ({ children }) => {

    // eslint-disable-next-line react/prop-types
    let InfoToast = ({ text }) => {
        return <div>{text}</div>;
    };

    // eslint-disable-next-line react/prop-types
    let ErrorToast = ({ text }) => {
        return <div>{text}</div>;
    };

    let displayInfoToast = (text) => {
        toast.success(<InfoToast text={text} />);
    };
    let displayErrorToast = (text) => {
        toast.error(<ErrorToast text={text} />);
    };

    const value = {
        displayInfoToast,
        displayErrorToast
    };

    return (
        <ToastContext.Provider value={value}>
            {children}
            <ToastContainer position="top-center"
                            transition={Slide}
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={true}
                            closeOnClick
                            rtl={false}
                            limit={2}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
            />
        </ToastContext.Provider>
    );
};

ToastProvider.propTypes = {

}

const mapStateToProps = () => ({

})

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ToastProvider)
