import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects'

import {
    CREATE_FCM_REGISTRATION,
    CREATE_FCM_REGISTRATION_ERROR,
    CREATE_FCM_REGISTRATION_REQUEST,
    CREATE_FCM_REGISTRATION_SUCCESS,
    DELETE_FCM_REGISTRATION,
    DELETE_FCM_REGISTRATION_ERROR,
    DELETE_FCM_REGISTRATION_REQUEST,
    DELETE_FCM_REGISTRATION_SUCCESS,
    CHANGE_NOTIFICATION_SUBSCRIPTION,
    CHANGE_NOTIFICATION_SUBSCRIPTION_REQUEST,
    CHANGE_NOTIFICATION_SUBSCRIPTION_SUCCESS,
    CHANGE_NOTIFICATION_SUBSCRIPTION_ERROR,
    GET_NOTIFICATION_SETTINGS_REQUEST,
    GET_NOTIFICATION_SETTINGS_SUCCESS,
    GET_NOTIFICATION_SETTINGS_ERROR,
    GET_NOTIFICATION_SETTINGS,
} from "../actions/notification-actions";

import notificationApi from "../api/notification-api";
function* createFMCRegistration({ payload }) {
    yield put({ type: CREATE_FCM_REGISTRATION_REQUEST })

    const response = yield call(notificationApi.createFMCRegistration, payload.registration)

    if(response && response.status === 200 || response && response.status === 201) {
        yield put({type: CREATE_FCM_REGISTRATION_SUCCESS })

        if(payload.subscription){
            yield put({type: CHANGE_NOTIFICATION_SUBSCRIPTION, payload: payload.subscription})
        }
        // } else {
        //     yield put({type: GET_NOTIFICATION_SUBSCRIPTION, payload: {
        //             deviceId: payload.registration.deviceId,
        //             userId: payload.registration.userId,
        //             channel: "push"
        //         }})
        // }

    } else {
        yield put({ type: CREATE_FCM_REGISTRATION_ERROR, payload: response.status })
    }
}

function* deleteFMCRegistration({ payload }) {
    yield put({ type: DELETE_FCM_REGISTRATION_REQUEST })

    const response = yield call(notificationApi.deleteFMCRegistration, payload.deletedToken)

    if(response && response.status === 204) {
        yield put({type: DELETE_FCM_REGISTRATION_SUCCESS, payload })
        yield put({type: CHANGE_NOTIFICATION_SUBSCRIPTION, payload: payload.subscription})
    } else {
        yield put({ type: DELETE_FCM_REGISTRATION_ERROR })
    }
}

function* changeNotificationSubscription({ payload }) {
    yield put({ type: CHANGE_NOTIFICATION_SUBSCRIPTION_REQUEST })

    const response = yield call(notificationApi.changeNotificationSubscription, payload)

    if(response && response.status === 201) {
        yield put({type: CHANGE_NOTIFICATION_SUBSCRIPTION_SUCCESS, payload: response.data })
    } else {
        yield put({ type: CHANGE_NOTIFICATION_SUBSCRIPTION_ERROR })
    }
}

function* getUserNotificationSettings({ payload }) {
    yield put({ type: GET_NOTIFICATION_SETTINGS_REQUEST })

    const response = yield call(notificationApi.getUserNotificationSettings, payload)

    if(response && response.status === 200) {
        yield put({type: GET_NOTIFICATION_SETTINGS_SUCCESS, payload: response.data })
    } else {
        yield put({ type: GET_NOTIFICATION_SETTINGS_ERROR })
    }
}

export default function* notificationSaga() {
    yield takeLatest(CREATE_FCM_REGISTRATION, createFMCRegistration)
    yield takeLatest(DELETE_FCM_REGISTRATION, deleteFMCRegistration)
    yield takeLatest(CHANGE_NOTIFICATION_SUBSCRIPTION, changeNotificationSubscription)
    yield takeLatest(GET_NOTIFICATION_SETTINGS, getUserNotificationSettings)
}
