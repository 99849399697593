import {toast} from "react-toastify";
import ToastMessage from "../../screens/components/toast";
import React from "react";
import {
    GET_GIFTS_BY_USER_ERROR,
    GET_GIFTS_BY_USER_REQUEST,
    GET_GIFTS_BY_USER_SUCCESS,
    REDEEM_GIFT_ERROR,
    REDEEM_GIFT_REQUEST,
    REDEEM_GIFT_SUCCESS,
    NOT_FOUND_GIFT_FOR_REDEEM,
} from "../actions/gift-actions";

const initialState = {
    giftCards: null,
    loadingGifts: false,
    loadingRedeemGift: false
}

export default function giftReducer(state = initialState, {type, payload}) {
    switch(type) {
        case GET_GIFTS_BY_USER_REQUEST: {
            return {
                ...state,
                loadingGifts: true,
            }
        }

        case GET_GIFTS_BY_USER_SUCCESS:
            return {
                ...state,
                giftCards: payload,
                loadingGifts: false,
            }

        case GET_GIFTS_BY_USER_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Can`t get gifts."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingGifts: false,
            }

        case REDEEM_GIFT_REQUEST:
            return {
                ...state,
                loadingRedeemGift: true
            }

        case REDEEM_GIFT_SUCCESS:
            return {
                ...state,
                loadingRedeemGift: false
            }

        case REDEEM_GIFT_ERROR:
            payload.code !== 403 && toast.error(<ToastMessage text={`${payload.message}.`} withTryAgain={true} withSupportButton={true} code={payload.code} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingRedeemGift: false
            }

        case NOT_FOUND_GIFT_FOR_REDEEM:
            payload !== 403 && toast.error(<ToastMessage text={"Can`t redeem the gifts, because gift code not found."} withTryAgain={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                loadingRedeemGift: false
            }

        default:
            return state
    }
}
