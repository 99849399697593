import {
    SHOW_THANKS_MODAL,
    HIDE_THANKS_MODAL,
    SET_PAGE_BACKGROUND,
    RESET_PAGE_BACKGROUND,
    SET_FOOTER_HEIGHT,
    SET_PLAY_TAB,
    SET_GTM_DATA,
    CAN_INJECT_GTM_SCRIPT, SET_NEAREST_ENROLLMENT_SPRINT_DATE, SET_HEADER_ELEMENT_RECT,
} from "../actions/common-actions";
import moment from "moment-timezone";

const initialState = {
    thanksModalIsOpen: false,
    thanksModalMessage: null,
    pageBackground: {background: '#ffffff'}, // {background: '#fff7ef'},
    today: moment().tz('Australia/Sydney'),
    currentMonday: moment().tz('Australia/Sydney').isoWeekday(1),
    currentTuesday: moment().tz('Australia/Sydney').isoWeekday(2).set('hour', 10).set('minute', 30),
    currentSunday: moment().tz('Australia/Sydney').add(1, 'weeks').isoWeekday(0),
    nextMonday: moment().tz('Australia/Sydney').isoWeekday(1).add(1, 'weeks'),
    nextTuesday: moment().tz('Australia/Sydney').isoWeekday(2).add(1, 'weeks').set('hour', 10).set('minute', 30),
    isWeekEnd: moment().tz('Australia/Sydney').isoWeekday() === 6 || moment().tz('Australia/Sydney').isoWeekday() === 7,
    weekNumber: moment().tz('Australia/Sydney').format('YYYY-[W]WW'),
    nearestEnrollmentSprintDate: null,
    footerHeight: 0,
    headerElementRect: 0,
    playTab: 'not_owned',
    gtmData: {
        id: process.env.REACT_APP_GTM_MEASUREMENT_ID,
        injectScript: false,
        environment: {
            gtm_auth: process.env.REACT_APP_GTM_AUTH,
            gtm_preview: "env-1",
        },
        dataLayer: {}
    },
    canInjectGtmScript: false
}

export default function CommonReducer(state = initialState, {type, payload}) {

    switch(type) {
        case SHOW_THANKS_MODAL:
            return {
                ...state,
                thanksModalIsOpen: true,
                thanksModalMessage: payload
            };
        case HIDE_THANKS_MODAL:
            return {
                ...state,
                thanksModalIsOpen: false,
                thanksModalMessage: null
            };

        case SET_PAGE_BACKGROUND:
            return {
                ...state,
                pageBackground: payload,
            };

        case RESET_PAGE_BACKGROUND:
            return {
                ...state,
                pageBackground: initialState.pageBackground
            }

        case SET_FOOTER_HEIGHT:
            return {
                ...state,
                footerHeight: payload
            }

        case SET_HEADER_ELEMENT_RECT:
            return {
                ...state,
                headerElementRect: payload
            }

        case SET_PLAY_TAB:
            return {
                ...state,
                playTab: payload
            }

        case SET_GTM_DATA:

            // eslint-disable-next-line
            const data = {...state.gtmData.dataLayer}
            Object.assign(data, payload);

            return {
                ...state,
                gtmData: {...state.gtmData, dataLayer: data}
            }

        case CAN_INJECT_GTM_SCRIPT:
            return {
                ...state,
                gtmData: {...state.gtmData, injectScript: payload}
            }

        case SET_NEAREST_ENROLLMENT_SPRINT_DATE:
            return {
                ...state,
                nearestEnrollmentSprintDate: payload
            }

        default:
            return state
    }
}
