import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../components/Spinner";
import OwnedChallengeCard from "./OwnedChallengeCard";
import NotOwnedChallengeCard from "./NotOwnedChallengeCard";
import ChallengeDetailsModal from "./ChallengeDetailsModal";

import {GET_CHALLENGES} from "../../redux/actions/challenge-actions";
import Achievements from "./achievements";
import {SET_PLAY_TAB} from "../../redux/actions/common-actions";
import {useLocation} from "react-router-dom";


const Challenge = ({
                       ownedChallenges,
                       notOwnedChallenges,
                       newUserAchievementsCount,
                       loadingChallenges,
                       getChallenges,
                       challengesNeedUpdate,
                       setTab,
                       tab
}) => {

    const [openModal, setOpenModalState] = useState(false);

    const location = useLocation();

    useEffect(()=>{
        getChallenges();
    }, [])

    useEffect(()=>{
        if(ownedChallenges) {
            if(location.state?.from !== '/homework'){
                setTab(ownedChallenges.length > 0 ? 'owned' : 'not_owned')
            } else if (location.state?.from === '/homework' && location.state?.code === 'newAchievement') {
                setTab('achievements')
            }
        }
    }, [location.state?.code, location.state?.from, ownedChallenges, setTab])

    useEffect(()=>{
        if(challengesNeedUpdate){
            getChallenges();
        }
    }, [challengesNeedUpdate, getChallenges])

    return (
        <React.Fragment>
            <div className={"flex flex-col px-5 pt-24 overflow-hidden"}>
                {loadingChallenges ? (
                    <div className={"flex w-full h-screen justify-center items-center"}>
                        <Spinner size="lg" />
                    </div>

                ) : (
                    <>
                        <div role="tablist" className="tabs tabs-bordered tabs-xs ms:tabs-sm">
                            <button onClick={() => setTab('owned')} className={`tab ${ tab === 'owned' ? 'tab-active' : '' }`}>Playing</button>
                            <button  onClick={() => setTab('not_owned')} className={`tab ${ tab === 'not_owned' ? 'tab-active' : '' }`}>
                                Not played
                                {notOwnedChallenges && notOwnedChallenges.length > 0 && <div className="ml-1 badge badge-success text-white">{notOwnedChallenges.length}</div>}
                            </button>
                            <button  onClick={() => setTab('achievements')} className={`tab ${ tab === 'achievements' ? 'tab-active' : '' }`}>
                                Badges
                                {newUserAchievementsCount > 0 && <div className="ml-1 badge badge-success badge-xs ms:badge-sm text-white">{newUserAchievementsCount}</div>}
                            </button>
                        </div>
                        {tab === 'owned' && ownedChallenges && (
                            <>
                                <div className={"pt-4 flex flex-col"} >
                                    {ownedChallenges.map((challenge) => {
                                        return (
                                            <div key={challenge.id} className={"py-2"}>
                                                <OwnedChallengeCard
                                                    userChallenge={challenge}
                                                    openModal={openModal}
                                                    setOpenModalState={setOpenModalState}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>

                            </>

                        )}
                        {tab === 'not_owned' && notOwnedChallenges && (
                            <div className={"pt-4 flex flex-col"}>
                                {notOwnedChallenges.map((challenge) => {
                                    return (
                                        <div key={challenge.id} className={"py-2"}>
                                            <NotOwnedChallengeCard challenge={challenge} />
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        {tab === 'achievements' && (
                            <Achievements />
                        )}

                    </>
                )}
                <ChallengeDetailsModal />
            </div>
        </React.Fragment>
    )
}

Challenge.propTypes = {
    ownedChallenges: PropTypes.array,
    notOwnedChallenges: PropTypes.array,
    newUserAchievementsCount: PropTypes.number,
    loadingChallenges: PropTypes.bool,
    challengesNeedUpdate: PropTypes.bool,
    getChallenges: PropTypes.func,
    setTab: PropTypes.func,
    tab: PropTypes.string
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    ownedChallenges: state.challenge.ownedChallenges,
    notOwnedChallenges: state.challenge.notOwnedChallenges,
    newUserAchievementsCount: state.achievement.newUserAchievementsCount,
    loadingChallenges: state.challenge.loadingChallenges,
    challengesNeedUpdate: state.challenge.challengesNeedUpdate,
    tab: state.common.playTab
})

const mapDispatchToProps = (dispatch) => ({
    getChallenges: () => dispatch({ type: GET_CHALLENGES }),
    setTab: (tab) => dispatch({ type: SET_PLAY_TAB, payload: tab } )
})

export default connect(mapStateToProps, mapDispatchToProps)(Challenge)
