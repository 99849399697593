import React, {useEffect, useState} from "react";
import Smile from '../assets/emoji/grinning-face_1f600.png'
import moment from 'moment'
import Loading from "./components/Loading";
import PropTypes from "prop-types";
import {connect} from "react-redux";

const Holiday = ({ options }) => {

    const [holidayData, setHolidayData] = useState(null)

    useEffect(()=>{
        if(options){
            const holidayPreference = options.find(option => option.code === 'holiday')
            setHolidayData(JSON.parse(holidayPreference.value))
        }
    }, [options])

    return (
        <div className="flex flex-col items-center min-h-screen bg-brandYellow justify-center ">
            {holidayData != null ? (

                <div className={"w-full xs:max-w-laptop"}>
                    <div className="flex flex-col items-center text-center px-3">
                        <h1 className="mb-5 text-3xl font-bold">
                            English in Stories will be on a Christmas break until {moment(holidayData.endDate).format('MMMM DD')}
                        </h1>
                        <p className="text-xl">
                            Merry Christmas and Happy New Year!
                        </p>
                        <p className="text-xl">
                            See you soon.
                        </p>
                        <img src={Smile} className={'h-16 w-16 mt-10'}/>
                    </div>
                </div>
            ) : (
                <Loading isFullScreen={true}/>
            )}
        </div>


    )
}

Holiday.propTypes = {
    options: PropTypes.array,
}

const mapStateToProps = (state) => ({
    options: state.preference.options,
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Holiday)
