import {
    GET_BALANCE_HISTORY_REQUEST,
    GET_BALANCE_HISTORY_SUCCESS,
    GET_BALANCE_HISTORY_ERROR,
    GET_SUBSCRIPTION_REQUEST,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_ERROR,
    RESET_BILLING_DATA,
    UPDATE_SUBSCRIPTION_REQUEST,
    UPDATE_SUBSCRIPTION_SUCCESS,
    UPDATE_SUBSCRIPTION_ERROR,
    DELETE_SUBSCRIPTION_REQUEST,
    DELETE_SUBSCRIPTION_SUCCESS,
    DELETE_SUBSCRIPTION_ERROR,
    SET_SUBSCRIPTION,
    DELETE_SUBSCRIPTION_SCHEDULE_REQUEST,
    DELETE_SUBSCRIPTION_SCHEDULE_SUCCESS,
    DELETE_SUBSCRIPTION_SCHEDULE_ERROR,
    SET_NEED_UPDATE_BALANCE_HISTORY
} from "../actions/billing-actions";

import { toast } from "react-toastify";
import React from "react";

import ToastMessage from "../../screens/components/toast";

const initialState = {
    billingTotalRecords: 0,
    pageSize: 0,
    getBalanceHistoryLoading: false,
    getSubscriptionLoading: false,
    updateSubscriptionLoading: false,
    deleteSubscriptionLoading: false,
    userSubscription: null,
    balanceHistory: null,
    needUpdateBalanceHistory: false,
};

export default function billingReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        /* GET USER BALANCE HISTORY */
        case GET_BALANCE_HISTORY_REQUEST:
            return {
                ...state,
                getBalanceHistoryLoading: true,
            };

        case GET_BALANCE_HISTORY_SUCCESS:
            return {
                ...state,
                balanceHistory: payload,
                getBalanceHistoryLoading: false,
                needUpdateBalanceHistory: false
            };

        case GET_BALANCE_HISTORY_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Something went wrong."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                getBalanceHistoryLoading: false,
                needUpdateBalanceHistory: false
            };

        case SET_NEED_UPDATE_BALANCE_HISTORY:
            return {
                ...state,
                needUpdateBalanceHistory: payload,
            };

        /* GET USER SUBSCRIPTION */
        case GET_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                getSubscriptionLoading: true,
            };

        case GET_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                userSubscription: payload,
                getSubscriptionLoading: false,
            };

        case GET_SUBSCRIPTION_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Something went wrong."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                getSubscriptionLoading: false,
            };

        /* UPDATE USER SUBSCRIPTION */
        case UPDATE_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                updateSubscriptionLoading: true,
            };

        case UPDATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                userSubscription: { ...state.userSubscription, subscription: payload },
                updateSubscriptionLoading: false,
            };

        case UPDATE_SUBSCRIPTION_ERROR:
            payload !== 403 &&toast.error(<ToastMessage text={"Can't update subscription."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                updateSubscriptionLoading: false,
            };

        case SET_SUBSCRIPTION:
            return {
                ...state,
                userSubscription: { ...state.userSubscription, subscription: payload },
                needUpdateBalanceHistory: true
            };

        /* DELETE USER SUBSCRIPTION */
        case DELETE_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                deleteSubscriptionLoading: true,
            };

        case DELETE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                userSubscription: { ...state.userSubscription, subscription: null },
                deleteSubscriptionLoading: false,
            };

        case DELETE_SUBSCRIPTION_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Can't delete subscription."} withTryAgain={true} withSupportButton={true} code={payload}/>, {
                autoClose: false
            })
            return {
                ...state,
                deleteSubscriptionLoading: false,
            };

        /* DELETE USER SUBSCRIPTION SCHEDULE */
        case DELETE_SUBSCRIPTION_SCHEDULE_REQUEST:
            return {
                ...state,
                deleteSubscriptionLoading: true,
            };

        case DELETE_SUBSCRIPTION_SCHEDULE_SUCCESS:
            return {
                ...state,
                userSubscription: { ...state.userSubscription, subscription: null },
                deleteSubscriptionLoading: false,
            };

        case DELETE_SUBSCRIPTION_SCHEDULE_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Can't delete subscription."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                deleteSubscriptionLoading: false,
            };

        case RESET_BILLING_DATA:
            return {
                ...state,
                billingTotalRecords: initialState.billingTotalRecords,
                pageSize: initialState.pageSize,
                getBalanceHistoryLoading: initialState.getBalanceHistoryLoading,
                getSubscriptionLoading: initialState.getSubscriptionLoading,
                updateSubscriptionLoading: initialState.updateSubscriptionLoading,
                deleteSubscriptionLoading: initialState.deleteSubscriptionLoading,
                userSubscription: initialState.userSubscription,
                balanceHistory: initialState.balanceHistory,
                needUpdateBalanceHistory: initialState.needUpdateBalanceHistory,
            }

        default:
            return state;
    }
}
