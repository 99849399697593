import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import ArrowUpRight from "../../../../assets/icons/Vector.svg"
import { v4 as uuidv4 } from 'uuid';
import {useNavigate} from "react-router-dom";
import Skeleton from "./Skeleton";

const StatsCard = ({ userInfo, options }) => {

    const navigate = useNavigate()

    const [stats, setStats] = useState([])
    const [statWidgetFields, setStatWidgetFields] = useState([])
    const [calculationsComplete, setCalculationsComplete] = useState(false);

    useEffect(() => {
        if(userInfo && options?.length > 0) {
            setStats([]);
            const statPreference = options.find(option => option.code === 'user_stats_config')
            const statObject = JSON.parse(statPreference.value)
            const statCodesConfig = statObject['welcome_stat_codes']

            setStatWidgetFields(statObject['welcome_stat_widget_config'])

            const keys = statCodesConfig.map(item => Object.keys(item)[0]);
            const userStats = userInfo['userStats']

            keys.forEach(key => {
                const userStat = userStats.stats.find(stat => stat.type === key)

                if(userStat){
                    const configItem = statCodesConfig.find(item => Object.keys(item)[0] === key)
                    const widgetConfig = configItem ? configItem[key] : null;

                    if (widgetConfig) {
                        const configValueField = widgetConfig.value;
                        const configTitleField = widgetConfig.title
                        const configDescriptionField = widgetConfig.description

                        const stat = {
                            value: configValueField ? userStat[configValueField] : userStat.value,
                            title: configTitleField ? userStat[configTitleField] : userStat.title,
                            description: configDescriptionField ? userStat[configDescriptionField] : userStat.description,
                        }

                        setStats(prevStats => [...prevStats, stat]);
                    }
                }
            })

            setCalculationsComplete(true);
        }
    }, [userInfo, options]);

    return (
        <React.Fragment>
            {calculationsComplete ? (
                <div
                    className={"shadow w-full card bg-dark cursor-pointer min-h-32"}
                >
                    <div className={"flex flex-col justify-between px-3 py-3 text-white space-y-2 min-h-32"}>
                        {/* HEADER */}
                        <div className={"flex flex-row justify-between items-end pb-1"}>
                            <span className="text-base font-normal">
                                Stats
                            </span>
                            <div
                                className={"flex flex-row items-center space-x-2"}
                                onClick={()=> {
                                    navigate('/stats')
                                }}
                            >
                        <span className="text-xs font-normal opacity-60">
                            <div className="badge badge-accent badge-outline badge-sm">All stats</div>
                        </span>
                                <img src={ArrowUpRight}></img>
                            </div>
                        </div>

                        {/* BODY */}
                        <div className={"flex flex-row space-x-4 items-center text-white"}>
                            {stats.map(stat => {
                                return (
                                    <div key={uuidv4()} className={"flex flex-col justify-center"}>
                                        {statWidgetFields.length > 0 && statWidgetFields.includes('title') &&
                                            <span className={"text-xl ms:text-2xl font-bold line-clamp-1"}>{stat.title}</span>}
                                        {statWidgetFields.length > 0 && statWidgetFields.includes('value') &&
                                            <span className={"text-3xl ms:text-4xl font-bold line-clamp-1"}>{stat.value}</span>}
                                        {statWidgetFields.length > 0 && statWidgetFields.includes('description') &&
                                            <span className={"text-xs ms:text-sm font-normal line-clamp-1"}>{stat.description}</span>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            ) : (
                <Skeleton />
            )}
        </React.Fragment>
    )
}

StatsCard.propTypes = {
    userInfo: PropTypes.object,
    options: PropTypes.array,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    options: state.preference.options
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(StatsCard)