import React, {useEffect, useMemo, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import SprintStat from "./SprintStatCard";
import Skeleton from "./Skeleton";
import HelpDropDownIcon from "./HelpDropDownIcon";
import {usePrevious} from "../../../../hooks/usePrevious";


// eslint-disable-next-line no-unused-vars
const SprintCard = ({ userInfo, currentSprint, currentSprintLoading, series }) => {

    const navigate = useNavigate()
    const [accessSprint, setAccessSprint] = useState({})
    const [reasons, setReasons] = useState({})
    const [sprintNotFound, setSprintNotFound] = useState(false)
    const [canPaid, setCanPaid] = useState(false)

    const [sprintSummary, setSprintSummary] = useState(null)
    const [dailySummary, setDailySummary] = useState(null)
    const prevSprintLoading = usePrevious(currentSprintLoading)

    const [currentSprintLoaded, setCurrentSprintLoaded] = useState(currentSprint !== null)

    useEffect(() => {
        if((prevSprintLoading === true && currentSprintLoading === false) || sprintNotFound){
            setCurrentSprintLoaded(true)
        }
    }, [prevSprintLoading, currentSprintLoading, sprintNotFound]);

    useEffect(() => {
        if(userInfo){
            setAccessSprint(userInfo['canAccessSprint'])
            setCanPaid(userInfo.isAccountSynced)
            if(userInfo.canAccessSprint?.granted === false) {
                setReasons(userInfo.canAccessSprint?.reasons)
            }
        }
    }, [userInfo]);

    useEffect(()=>{
        if(accessSprint && accessSprint.granted === false) {
            setReasons(accessSprint.reasons)
            const status = accessSprint.reasons.sprintNotFound

            if(status !== undefined) {
                setSprintNotFound(status.value)
            }
        }

    }, [accessSprint])

    useEffect(() => {
        if(!sprintNotFound && currentSprint){

            const currentUtcOffset = moment().utcOffset()
            const today = moment().utc(currentUtcOffset)

            // eslint-disable-next-line no-unused-vars
            const day = today.isoWeekday() === 6 || today.isoWeekday() === 7 ? 5 : today.isoWeekday()

            setSprintSummary(currentSprint.summary.sprint)
            setDailySummary({
                dayQuestions: currentSprint.summary.days[day].questionsNumber,
                dayAllAssignments:  currentSprint.summary.days[day].allAssignments,
                dayCorrectAnswers:  currentSprint.summary.days[day].correctAnswer
            })
        }
    }, [sprintNotFound, currentSprint]);

    const helpDropDownIcon = useMemo(() => (
        <HelpDropDownIcon
            reasons={reasons}
            canPaid={canPaid}
            title={'Access conditions'}
        />
    ), [reasons, canPaid]);

    const sprintCardBody = useMemo(() => (
        <>
            <div className={"flex flex-col px-3 pb-1"}>
                {sprintNotFound ? (
                    <>
                        <div className={"flex flex-row justify-between items-end pb-1"}>
                            <span className="text-base font-medium">Topic not found</span>
                        </div>
                        <span className={"pb-3 text-sm opacity-60"}>Please try again later</span>
                    </>
                ) : (
                    <div
                        className={`flex flex-col ${accessSprint?.granted ? 'cursor-pointer' : ''}`}
                        onClick={() => accessSprint?.granted && navigate('/sprint')}
                    >
                        <div className={`flex flex-row justify-between items-end border-b border-black pb-1`}>
                            <span className="text-base font-medium">Current topic</span>
                            <span className="text-xs font-medium text-dark opacity-60">
                                {moment(currentSprint?.startDate).format('MMM DD')} - {moment(currentSprint?.endDate).format('MMM DD')}
                            </span>
                        </div>
                        <div className={"text-lg font-bold pt-2"}>
                            <div className={"line-clamp-2"}>
                                {currentSprint?.title}
                            </div>

                        </div>
                        {!accessSprint?.granted && (
                            <div className={"pb-3 w-full"}>
                                {helpDropDownIcon}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {accessSprint?.granted && sprintSummary && dailySummary && (
                <SprintStat
                    sprintSummary={sprintSummary}
                    dailySummary={dailySummary}
                />
            )}
        </>
    ), [sprintNotFound, currentSprint, accessSprint, sprintSummary, dailySummary])

    if(userInfo && currentSprintLoaded){
        return (
            <div className={`card shadow bg-info text-dark ${series.length > 0 ? 'pt-8' : 'pt-2'} px-0 ${sprintNotFound ? '' : !accessSprint?.granted ? 'min-h-26' : 'min-h-36'}`}>
                {sprintCardBody}
            </div>
        )
    } else {
        return <Skeleton />
    }
}

SprintCard.propTypes = {
    userInfo: PropTypes.object,
    currentSprint: PropTypes.object,
    currentSprintLoading: PropTypes.bool,
    series: PropTypes.array
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    currentSprint: state.config.currentSprint,
    currentSprintLoading: state.config.currentSprintLoading,
    series: state.stories.series,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(SprintCard)