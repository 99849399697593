import React from "react";
import  "./style.css"

// eslint-disable-next-line no-unused-vars
import gif from "../../../assets/test.gif"
// eslint-disable-next-line no-unused-vars
import install from "../../../assets/install.gif"
// eslint-disable-next-line no-unused-vars
import enable from "../../../assets/enable.gif"

const Test = () => {

    return (
        <React.Fragment>
            <div className="h-screen w-screen flex flex-row items-center justify-center">
                <div className="flex max-h-screen flex-col items-center justify-center text-center pt-10 px-4">
                    <div className={"text-xl text-center pb-4"}
                    >
                        Our app just leveled up with an awesome new feature: Push and Email Notifications! 📲✉️
                    </div>
                    <div className={"text-center pb-4"}
                    >
                        Now, you can receive instant updates and important reminders straight to your device or inbox!
                        🔔💬
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Test
