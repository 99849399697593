import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import StartChallengeButton from "./StartChallengeButton";
import {OPEN_CHALLENGE_DETAILS_DIALOG} from "../../redux/actions/challenge-actions";

const NotOwnedChallengeCard = ({
                                   challenge,
                                   openDetailsDialog
}) => {

    function openDetails(){
        openDetailsDialog({
            isOwned: false,
            challengeId: challenge.id
        })
    }

    return (
        <React.Fragment>
            <div className="card w-full px-4 bg-white shadow-brand-button rounded-none">
                <div className="card-body px-2 pt-4 pb-0">
                    <h2 className="card-title">{challenge.title}</h2>
                    <p>{challenge.description}</p>
                    <div className="card-actions flex flex-row flex-wrap items-center justify-between pt-2">
                        <button
                            onClick={openDetails}
                            className="px-0 btn btn-active btn-link text-xs"
                        >
                            See details
                        </button>
                        <StartChallengeButton
                            challengeId={challenge.id}
                            hasReferral={challenge.hasReferral}
                            isLink={true}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

NotOwnedChallengeCard.propTypes = {
    challenge: PropTypes.object,
    openDetailsDialog: PropTypes.func
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = (dispatch) => ({
    openDetailsDialog: (params) => dispatch({type: OPEN_CHALLENGE_DETAILS_DIALOG, payload: params})
})

export default connect(mapStateToProps, mapDispatchToProps)(NotOwnedChallengeCard)
