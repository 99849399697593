import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {connect, useSelector} from 'react-redux'
import moment from "moment/moment";


import {
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table'

const HistoryTable = ({ balanceHistory }) => {

    const columns = [
        {
            id: 'date',
            accessorFn: row => {
                if(row.createAt){
                    return `${moment(row.createAt).format("DD-MM-YYYY HH:mm")}`
                }
                return ''
            },
        },
        {
            id: 'balance',
            accessorFn: row => row.weeks,
        },
        {
            id: 'description',
            accessorFn: row => row.source,
        }
    ]

    const [data, setData] = useState([])
    useSelector((state) => {
        return state.data;
    });
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    })

    useEffect(()=>{
        if(balanceHistory){

            balanceHistory.map((row) => {
                const top_up_sources = ['stripe', 'yookassa', 'cloudpayments', 'init']

                if(top_up_sources.includes(row.source) && row.metadata.refund) {
                    row.source = `Refund ${row.metadata.weeks} ${row.metadata.weeks === -1 ? 'week': 'weeks'}`
                } else if (top_up_sources.includes(row.source)) {
                    row.source = 'Top up'
                } else if(row.source === 'stripe_subscription') {
                    row.source = 'Renew subscription'
                } else if(row.source === 'write_off'){
                    row.source = 'Write off'
                } else if(row.source === 'staff') {
                    row.source = 'Staff has made changes'
                } else if(row.source === 'freeze_off'){
                    row.source = 'Do future sprints'
                } else if(row.source === 'freeze_on'){
                    row.source = 'Skip next sprints'
                } else if(row.source === 'gift') {
                    row.source = 'Top up (gift)'
                } else if (row.source === 'challenge') {
                    row.source = `Top up (challenge)\n${row.metadata.userChallenge.title}`
                } else {
                    row.source = ''
                }

                return row
                // switch (row.source) {
                //     case 'stripe':
                //     case 'yookassa':
                //     case 'init':
                //     case 'cloudpayments':
                //         row.source = 'Top up'
                //         return row;
                //     case 'stripe_subscription':
                //         row.source = 'Renew subscription'
                //         return row;
                //     case 'write_off':
                //         row.source = 'Write off'
                //         return row;
                //     case 'staff':
                //         row.source = 'Staff has made changes'
                //         return row;
                //     case 'freeze_off':
                //         row.source = 'Do future sprints'
                //         return row;
                //     case 'freeze_on':
                //         row.source = 'Skip next sprints'
                //         return row;
                //     case 'gift':
                //         row.source = 'Top up (gift)'
                //         return row;
                //     default:
                //         row.source = ''
                //         return row;
                // }
            })

            setData(() => [...balanceHistory])
        }
    }, [balanceHistory])


    return (
        <div className={"overflow-hidden px-4"}>
            <table className="table table-fixed w-full text-sm">
                <thead className={"bg-white border-b border-slate-100"}>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr className={"whitespace-normal"} key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <div className={`text-primary ${header.id === 'balance' ? 'text-center' : ''}`}>
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody className={"bg-white"}>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id} className={`text-primary break-words whitespace-normal ${cell.id.split('_')[1] === 'balance' ? 'text-center' : ''}`}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>

    )
}

HistoryTable.propTypes = {
    balanceHistory: PropTypes.array,
}

const mapStateToProps = (state) => ({
    balanceHistory: state.billing.balanceHistory
})


const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTable)
