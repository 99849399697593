import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {stripeCurrencies} from "../../helpers/constants";

import StripeProcessing from "./StripeProcessing";
import Spinner from "../components/Spinner";
import CloudPaymentsPayment from "./CloudPaymentsPayment";

import {GET_BALANCE_HISTORY, GET_SUBSCRIPTION, RESET_BILLING_DATA} from "../../redux/actions/billing-actions";
import SpecialOfferCard from "../components/SpecialOfferCard";
import {SHOW_THANKS_MODAL} from "../../redux/actions/common-actions";


const Billing = ({
                     userInfo,
                     getSubscription,
                     userSubscription,
                     resetBillingData,
                     showThanksModal,
                     getSubscriptionLoading
}) => {

    const [processingType, setProcessingType] = useState('')
    const [userOffers, setUserOffers] = useState([])

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success") && (!getSubscriptionLoading && userInfo)) {
            showThanksModal();
        }

    }, []);

    useEffect(() => {
        if(userInfo){
            setUserOffers(userInfo.userOffers)
            setProcessingType(stripeCurrencies.includes(userInfo.profile.currency) ? 'stripe' : 'cloudPayments')
        }
    }, [userInfo]);

    useEffect(()=>{

        if(processingType === 'stripe') {

            const query = {
                currency: userInfo.profile.currency
            }

            getSubscription({
                uid: userInfo.uid,
                query: query
            })
        }

        return () => {
            resetBillingData()
        }
    },[processingType])

    return (
        <React.Fragment>
            { !getSubscriptionLoading && userInfo ? (
                <div className={"w-full mb-4 pt-24 space-y-4"}>
                    <>
                        {
                            {
                                stripe: (
                                    <>
                                        {userSubscription !== null ? (
                                            <StripeProcessing/>
                                        ) : null}
                                    </>
                                ),
                                cloudPayments: <CloudPaymentsPayment/>
                            }[processingType]
                        }
                        <div className="divider">Special offers</div>
                        {userOffers.map(offer => (
                            <div
                                key={offer.id}
                                className={"px-4"}
                            >
                                <SpecialOfferCard
                                    offer={offer}
                                />
                            </div>
                        ))}
                    </>
                </div>
            ) : (
                    <div className={"flex flex-row justify-center items-center h-screen"}>
                        <Spinner size="lg"/>
                    </div>
                )
            }
        </React.Fragment>
    )
}

Billing.propTypes = {
    userInfo: PropTypes.object,
    lock: PropTypes.bool,
    getSubscription: PropTypes.func,
    resetBillingData: PropTypes.func,
    userSubscription: PropTypes.object,
    getSubscriptionLoading: PropTypes.bool,
    getBalanceHistoryLoading: PropTypes.bool,
    getBalanceHistory: PropTypes.func,
    showThanksModal: PropTypes.func
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    userSubscription: state.billing.userSubscription,
    getSubscriptionLoading: state.billing.getSubscriptionLoading,
    getBalanceHistoryLoading: state.billing.getBalanceHistoryLoading
})

const mapDispatchToProps = (dispatch) => ({
    getSubscription: (query) => dispatch({ type: GET_SUBSCRIPTION, payload: query }),
    resetBillingData: () => dispatch({ type: RESET_BILLING_DATA }),
    getBalanceHistory: (params) => dispatch({ type: GET_BALANCE_HISTORY, payload: params }),
    showThanksModal: (message) => dispatch({ type: SHOW_THANKS_MODAL, payload: message })
})

export default connect(mapStateToProps, mapDispatchToProps)(Billing)
