import React from "react";
import * as PropTypes from "prop-types";
import {connect} from "react-redux";
import {SET_PLAY_TAB} from "../../redux/actions/common-actions";
import {useNavigate} from "react-router-dom";

function AchievementNotificationMessage({ text }) {
    const navigate = useNavigate();
    const handleShowBadgeButton = () => {
        navigate('/challenges', { state: {from: '/homework', code: 'newAchievement'} })
    }

    return (
        <div className="flex flex-row justify-between items-center w-full xs:max-w-laptop">
            <div className={"flex flex-row flex-1 flex-wrap items-center"}>
                <span className={"leading-tight text-sm"}>{text}</span>
            </div>
            <button
                onClick={handleShowBadgeButton}
                className="btn btn-sm btn-primary ml-2"
            >
                Show
            </button>
        </div>
    )
}

AchievementNotificationMessage.propTypes = {
    text: PropTypes.string,
    setPlayTab: PropTypes.func
};

const mapStateToProps = () => ({

})

const mapDispatchToProps = (dispatch) => ({
    setPlayTab: (state) => dispatch({ type: SET_PLAY_TAB, payload: state })
})

export default connect(mapStateToProps, mapDispatchToProps)(AchievementNotificationMessage)