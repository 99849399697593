import sha256 from 'crypto-js/sha256';

export function getSummaryDays(days) {
    return [...Object.keys(days)]
        .map(i => {
            return {day: i, ...days[i]}
        })
}

export function getSHA256string(message){
    return sha256(message).toString()
}