import {accountApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import client from "./client";

const accountClient = client(`${accountApiUrl}/api/v1/stories`)

const getStoriesByCohorts = async (payload) => {
    try {
        const response = await accountClient.get(`/series/?cohort_codes=${payload}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getStoriesByCode = async (payload) => {
    try {
        const response = await accountClient.get(`/series/?code=${payload}`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const storiesApi = {
    getStoriesByCohorts,
    getStoriesByCode
}

export default storiesApi
