import React, {useEffect, useState} from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {
    ATTACH_PAYMENT_METHOD,
} from "../../../redux/actions/payment-method-actions";
import StripeCardElement from "../StripeCardElement";
import {usePrevious} from "../../../hooks/usePrevious";

const AddPaymentMethodForm = ({
                                  userInfo,
                                  userSubscription,
                                  setupIntentSecret,
                                  setShowDialog,
                                  showDialog,
                                  attachPaymentMethod,
                                  attachPaymentMethodLoading
}) => {

    const stripe = useStripe();
    const elements = useElements();

    const [setupIntent, setSetupIntent] = useState(null);
    const [loading, setLoading] = useState(false)
    const [paymentCardError, setPaymentCardError] = useState(null)
    const [isCompletePaymentCard, setIsCompletePaymentCard] = useState(false)
    const [useAsDefault, setUseAsDefault] = useState(true)
    const prevAttachPaymentMethodLoading = usePrevious(attachPaymentMethodLoading)

    const handleAttachePaymentMethod = async (e) => {
        e.preventDefault();

        setLoading(true)

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);


        // Use card Element to tokenize payment details
        let { error, setupIntent } = await stripe.confirmCardSetup(setupIntentSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    email: userInfo.profile.email
                }
            }
        });

        if(error) {
            setLoading(false)
            return;
        }
        setSetupIntent(setupIntent);
    }

    useEffect(()=>{
        if(prevAttachPaymentMethodLoading === true &&  attachPaymentMethodLoading === false){
            elements.getElement(CardElement).clear()
            setLoading(false)
            setShowDialog(false)
        }
    }, [loading, elements, setShowDialog, prevAttachPaymentMethodLoading, attachPaymentMethodLoading])

    useEffect(()=>{

        if(setupIntent && setupIntent.status === 'succeeded') {

            let params = {
                paymentMethodId: setupIntent.payment_method,
                customerId: userSubscription.customer,
                subscriptionId: userSubscription.subscription.id,
                useAsDefault: useAsDefault,
                subscriptionType: userSubscription.subscription.object
            }

            attachPaymentMethod(params)

        }
    }, [setupIntent])

    if (!stripe || !elements) {
        return '';
    }

    return (
        <>
            <dialog className={`flex flex-row justify-center modal modal-bottom ${showDialog ? 'modal-open' : ''}`}>
                <form method={"dialog"} className="modal-box w-screen h-screen xs:max-w-laptop pt-4 px-4">
                    {/* CLOSE MODAL BUTTON */}
                    <button
                        onClick={() => setShowDialog(false)}
                        className="btn btn-sm btn-circle btn-primary absolute right-4 top-4"
                    >✕</button>

                    {/* MODAL BODY */}
                    <h3 className="prose font-bold text-lg">Add payment method</h3>
                    <div className={"h-16 mt-10"}>
                        <StripeCardElement
                            setPaymentCardError={setPaymentCardError}
                            setIsCompletePaymentCard={setIsCompletePaymentCard}
                            paymentCardError={paymentCardError}
                        />
                    </div>

                    <div className="form-control mb-5">
                        <label className="label cursor-pointer justify-start space-x-4">
                            <input
                                type="checkbox"
                                checked={useAsDefault}
                                className="checkbox"
                                onChange={()=>setUseAsDefault(!useAsDefault)}
                            />
                            <span className="label-text">Use as default payment method</span>
                        </label>
                    </div>

                    {/* MODAL ACTION */}
                    <div className="modal-action mt-2">
                        <button
                            className={`btn rounded-full shadow-brand-button ${!isCompletePaymentCard ? 'btn-disabled btn-secondary' : 'btn-primary'}`}
                            onClick={handleAttachePaymentMethod}
                        >
                            {loading && <span className="loading loading-spinner"></span>}
                            Add payment method
                        </button>
                    </div>
                </form>
            </dialog>
        </>
    )
}

AddPaymentMethodForm.propTypes = {
    userInfo: PropTypes.object,
    userSubscription: PropTypes.object,
    attachPaymentMethod: PropTypes.func,
    setupIntentSecret: PropTypes.string,
    setShowDialog: PropTypes.func,
    showDialog: PropTypes.bool,
    paymentMethods: PropTypes.array,
    attachPaymentMethodLoading: PropTypes.bool
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
    userSubscription: state.billing.userSubscription,
    paymentMethods: state.paymentMethod.paymentMethods,
    attachPaymentMethodLoading: state.paymentMethod.attachPaymentMethodLoading
})

const mapDispatchToProps = (dispatch) => ({
    attachPaymentMethod: (params) => dispatch({ type: ATTACH_PAYMENT_METHOD, payload: params })
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethodForm)
