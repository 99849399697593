import React from 'react'
import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from 'uuid';

import {connect} from "react-redux";
import {AiFillStar} from "react-icons/ai";

const Rating = ({ status, correct, all }) => {

    let progress = []
    if(status === 'completed'){
        const result = correct / all

        if(correct === 0) {
            for (var i = 0; i < 5; i++) {
                progress.push(
                    <svg key={uuidv4()} width="1em" height="1em">
                        <linearGradient id="outliner-gradient">
                            <stop stopColor="transparent" offset="0%"/>
                            <stop stopColor="transparent" offset="100%" />
                        </linearGradient>
                        <AiFillStar key={"drop"} style={{ fill: "url(#outliner-gradient)", stroke: "black", strokeWidth: 40 }}/>
                    </svg>
                );
            }
        } else {

            const fill = 5 * result

            if(fill === 5){
                for (i = 0; i < 5; i++) {
                    progress.push(
                        <svg key={uuidv4()} width="1em" height="1em">
                            <linearGradient id="full-gradient">
                                <stop stopColor="black" offset="0%" />
                                <stop stopColor="black" offset="100%" />
                            </linearGradient>
                            <AiFillStar key={"drop"} style={{ fill: "url(#full-gradient)", stroke: "black", strokeWidth: 40 }}/>
                        </svg>
                    );
                }
            } else {
                const full = Math.floor(fill)
                let drop = (fill - full) * 100

                if(isNaN(drop)){
                    drop = 0
                }

                for (i = 0; i < full; i++) {
                    progress.push(
                        <svg key={uuidv4()} width="1em" height="1em">
                            <linearGradient id="full-gradient">
                                <stop stopColor="black" offset="0%" />
                                <stop stopColor="black" offset="100%" />
                            </linearGradient>
                            <AiFillStar key={"drop"} style={{ fill: "url(#full-gradient)", stroke: "black", strokeWidth: 40 }}/>
                        </svg>
                    );
                }
                progress.push(
                    <>
                        <svg key={uuidv4()} width="1em" height="1em">
                            <linearGradient id="gradient">
                                <stop stopColor="black" offset="0%" />
                                <stop stopColor="black" offset={`${drop}%`} />
                                <stop stopColor="transparent" offset={`${drop+1}%`} />
                                <stop stopColor="transparent" offset="100%" />
                            </linearGradient>
                            <AiFillStar key={"drop"} style={{ fill: "url(#gradient)", stroke: "black", strokeWidth: 40 }}/>
                        </svg>
                    </>
                )

                let f = 4-full

                if(f > 0){
                    for (i = 0; i < f; i++) {
                        progress.push(
                            <svg key={uuidv4()} width="1em" height="1em">
                                <linearGradient id="outliner-gradient">
                                    <stop stopColor="transparent" offset="0%"/>
                                    <stop stopColor="transparent" offset="100%" />
                                </linearGradient>
                                <AiFillStar key={"drop"} style={{ fill: "url(#outliner-gradient)", stroke: "black", strokeWidth: 40 }}/>
                            </svg>
                        );
                    }
                }
            }
        }
        return <div className={"flex items-center justify-start"}>
            {progress}
        </div>
    } else {
        return <div className="badge badge-neutral badge-sm"><span className={"text-xs text-whit text-opacity-70"}>Not played</span></div>
        // return <p className="text-xs text-white text-opacity-70 rounded-full bg-neutral px-2">Not played</p>
    }
}

Rating.propTypes = {
    status: PropTypes.string,
    number: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
    link: PropTypes.string,
    all: PropTypes.number,
    correct: PropTypes.number,
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

// To make those two function works register it using connect
export default connect(mapStateToProps, mapDispatchToProps)(Rating)
