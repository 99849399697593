import client from './client'
import camelcaseKeys from "camelcase-keys";
import {accountApiUrl} from "../../helpers/constants";
import snakecaseKeys from "snakecase-keys";

const accountClient = client(`${accountApiUrl}/api/v1`)

export const getAchievements = async (userId) => {
  try {
    const response = await accountClient.get(`/achievement/achievement/users/${userId}/`)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}

export const bulkUpdateUserAchievementsWasShown = async (updatesList) => {
  try {
    const data = snakecaseKeys(updatesList)
    const response = await accountClient.put(`/achievement/user_achievement/bulk_was_shown_update/`, data)
    return camelcaseKeys(response, {deep: true})
  } catch({ response }) {
    return camelcaseKeys(response, {deep: true})
  }
}


const achievementApi = {
  getAchievements,
  bulkUpdateUserAchievementsWasShown
};

export default achievementApi;
