import React from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
import Emoji from "../../../components/Emoji";

const SprintStatCardBody = ({
                                title,
                                correct,
                                donePercentage,
                                correctPercentage

}) => {

    return (
        <React.Fragment>
            <span className={"text-white pt-2"}>{title}</span>
            <div className={"relative w-full flex flex-col text-white"}>
                <div className={"flex flex-row"}>
                    <div className={"w-full text-sm flex flex-row justify-between"}>
                        <span className={`text-right pb-1`}>
                            {correct}
                            <Emoji symbol={"👍"}/>
                        </span>
                        <span className={"text-right pb-1"}>
                            {donePercentage}%
                            <Emoji symbol={"✅"}/>
                        </span>
                    </div>
                </div>
                <div className={"relative w-full flex flex-col"}>
                    <progress
                        className="w-full progress progress-warning bg-white h-3"
                        value={donePercentage}
                        max="100">
                    </progress>
                    <progress
                        className="bg-transparent absolute w-full progress progress-accent h-3"
                        value={correctPercentage}
                        max="100"
                    ></progress>
                </div>
            </div>
        </React.Fragment>
    )
}

SprintStatCardBody.propTypes = {
    title: PropTypes.string,
    correct: PropTypes.number,
    donePercentage: PropTypes.number,
    correctPercentage: PropTypes.number
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(SprintStatCardBody)