import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Pluralize from "react-pluralize";
import pluralize from "pluralize";

const ChallengeReward = ({
                             challengeScore,
                             reward,
                             rewardType,
                             isRepeatable
}) => {

    if (isRepeatable) {
        return (
            <React.Fragment>
                <div>
                    For each&nbsp;
                    {`${challengeScore} ${pluralize('point', challengeScore)}`}&nbsp;
                    {/*<Pluralize*/}
                    {/*    singular={'point'}*/}
                    {/*    plural={'points'}*/}
                    {/*    count={challengeScore}*/}
                    {/*    showCount={true}*/}
                    {/*/>*/}
                    reward&nbsp;
                    {/*<Pluralize*/}
                    {/*    singular={rewardType}*/}
                    {/*    count={reward}*/}
                    {/*    showCount={true}*/}
                    {/*/>*/}
                </div>
            </React.Fragment>
        )

    } else {
        return (
            <React.Fragment>
                When you reach {challengeScore} points, you get {reward} {rewardType}
            </React.Fragment>
        )
    }
}

ChallengeReward.propTypes = {
    challengeScore: PropTypes.number,
    reward: PropTypes.number,
    rewardType: PropTypes.string,
    isRepeatable: PropTypes.bool
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeReward)