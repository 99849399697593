import React, {createContext, useContext, useEffect, useState} from "react";
import {auth} from "../firebase";
import Loading from "../screens/components/Loading";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

import ToastMessage from "../screens/components/toast";

import {stringToBoolean} from "../helpers/validation";

import {GET_SPRINT} from "../redux/actions/configurator-actions";
import {SEND_CHALLENGE_ACTION} from "../redux/actions/challenge-actions";
import {GET_OPTIONS, SET_OPTIONS} from "../redux/actions/preference-actions";
import {GET_USER_INFO, RESET_USER_DATA} from "../redux/actions/user-actions";
import {LOGOUT_REQUESTED, NEED_REFRESH_TOKEN, REFRESH_TOKEN} from "../redux/actions/auth-actions";

import {
    onAuthStateChanged,
    sendEmailVerification,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithRedirect,
    signOut,
    getRedirectResult,
} from "firebase/auth";

import {useGTMDispatch} from "@elgorditosalsero/react-gtm-hook";
import {isProduction} from "../helpers/constants";
import {CAN_INJECT_GTM_SCRIPT, SET_GTM_DATA} from "../redux/actions/common-actions";
import {getSHA256string} from "../helpers/utils";

// create context
const AuthContext = createContext();

// use AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};

// AuthContext Provider with values
const AuthProvider = ({
                          // eslint-disable-next-line react/prop-types
                          children,
                          setToken,
                          getOptions,
                          getUserInfo,
                          resetUserData,
                          sendChallengeAction,
                          userInfo,
                          needRefreshToken,
                          setNeedRefreshToken,
                          setGtmData
}) => {

    const sendDataToGTM = useGTMDispatch()
    const navigate = useNavigate()

    const [timeActive, setTimeActive] = useState(false)
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [needChallengeAction, setNeedChallengeAction] = useState(false)

    useEffect(()=>{
        if(needRefreshToken === true && currentUser !== null) {
            currentUser.getIdToken(/* forceRefresh */ true)
                .then(function(idToken) {
                    setToken(idToken);
                    setNeedRefreshToken(false)
                    getOptions()
                })
                .catch(function() {
                    setNeedChallengeAction(false)
                    navigate('/logout')
                });
        }
    }, [currentUser, getOptions, navigate, needRefreshToken, setNeedRefreshToken, setToken])

    useEffect(() => {
        if (currentUser) {
            setToken(currentUser.accessToken);
        }

    }, [currentUser, setToken]);

    useEffect(()=>{
        if(userInfo && needChallengeAction){
            sendChallengeAction({
                actionCode: 'CREATE_USER',
                actionCodeType: 'LINK_REFERRAL'
            })
            setNeedChallengeAction(false)
        }
    }, [userInfo, needChallengeAction, setNeedChallengeAction, sendChallengeAction])

    useEffect(()=>{
        if(currentUser != null){
            getUserInfo(currentUser.uid)
        }
    }, [currentUser])

    useEffect(() => {

        // onAuthStateChanged will executed in auth and logout
        // unsubscribe when unmounting the component
        const unlisten = onAuthStateChanged(auth, async (user) => {

            getRedirectResult(auth).then(results => {
                if(results != null) {

                    const gtmData = {
                        user_id: results.user.uid,
                        event: 'ga4event',
                        login_type: results.providerId,
                        login: true,
                        email: getSHA256string(results.user.email)
                    }

                    if(results.user.metadata.creationTime === results.user.metadata.lastSignInTime){

                        gtmData['event_category'] = 'sign_up'
                        // Added event to init GTM data
                        isProduction && setGtmData(gtmData)

                        setNeedChallengeAction(true)

                    } else {
                        gtmData['event_category'] = 'login'
                        // Send event to GTM
                        isProduction && sendDataToGTM(gtmData)
                    }
                }
            }).catch((error) => {
                switch (error.code) {
                    case 'auth/account-exists-with-different-credential':
                        toast.error(<ToastMessage text={`Account exists with different credential.`} withTryAgain={true} withSupportButton={true}/>,
                            {autoClose: false}
                        )
                        break;
                    default:
                        toast.error(
                            <ToastMessage text={`An error occurred ${error.code}.`} withSupportButton={true} />,
                            {autoClose: false}
                        )
                        break;
                }
            });

            setCurrentUser(user);
            setLoading(false);
        });

        return () => {
            unlisten();
        }
    }, []);

    // google sign up with popup
    let doSocialSignIn = (provider) => {
        setLoading(true)
        return signInWithRedirect(auth, provider)
    };

    // this will logout a user
    let doLogout = () => {

        resetUserData()
        setToken(null);

        signOut(auth).then(()=>{
            isProduction && sendDataToGTM({
                event: 'ga4event',
                event_category: 'logout',
                login: false
            })

            isProduction && sendDataToGTM(function() {
                this.reset();
            })

            navigate('/')
        });
    };

    let doSignupWithEmailPass = async (email, password) => {
        try {
            toast.dismiss()
            const result = await createUserWithEmailAndPassword(auth, email, password)
            if(result){

                try {
                    // Send data to GTM
                    isProduction && sendDataToGTM({
                        event: 'ga4event',
                        event_category: 'sign_up',
                        login_type: "default",
                        login: true,
                        email: getSHA256string(email),
                        user_id: result.user.uid,
                    })

                    stringToBoolean(process.env.REACT_APP_TEST) === false && await sendEmailVerification(auth.currentUser)
                    setNeedChallengeAction(true)
                    setTimeActive(true)

                    navigate('/verify-email')

                } catch (error) {
                    toast.error(<ToastMessage text={error.message} withSupportButton={true} />)
                    return false
                }
            }
        } catch (error) {
            switch (error.code) {
                case 'auth/invalid-email':
                    toast.error(<ToastMessage text={"Invalid email."} withTryAgain={true} />,
                        {autoClose: false}
                    )
                    break;
                default:
                    console.error(error)
                    toast.error(
                        <ToastMessage text={`An error occurred ${error.code}.`} withSupportButton={true} />,
                        {autoClose: false}
                    )
                    break;
            }
            return false
        }
        return true
    };

    let doSendEmailVerification = () => {
        sendEmailVerification(auth.currentUser)
            .then(() => {
                setTimeActive(true)
            }).catch((error) => {
                toast.error(<ToastMessage text={error.message} withSupportButton={true} />,
                    {autoClose: false}
                )
            })
    }

    // this will auth with email and pasword
    let doSigninWithEmailPass = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
            .then((credential)=> {

                // Send data to GTM
                isProduction && sendDataToGTM({
                    user_id: credential.user.uid,
                    event: 'ga4event',
                    event_category: 'login',
                    login_type: "default",
                    login: true,
                    email: getSHA256string(email)
                })
            });
    };

    // this will reset user password
    let doResetPassword = (email) => {
        return sendPasswordResetEmail(auth, email)
    };

    // this will remove current user
    let doRemoveUser = () => {
        return currentUser.delete();
    };

    // context value object
    const value = {
        currentUser,
        loading,
        setLoading,
        timeActive,
        setTimeActive,
        doSocialSignIn,
        doLogout,
        doSignupWithEmailPass,
        doSendEmailVerification,
        doSigninWithEmailPass,
        doResetPassword,
        doRemoveUser,
        setNeedChallengeAction
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading ? children : <Loading isFullScreen={true}/>}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    checking: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    loadingLogin: PropTypes.bool,
    needRefreshToken: PropTypes.bool,
    userInfo: PropTypes.object,
    loginError: PropTypes.object,
    gtmData: PropTypes.object,
    getOptions: PropTypes.func,
    getUserInfo: PropTypes.func,
    setGtmData: PropTypes.func,
    cleanLoginData: PropTypes.func,
    setToken: PropTypes.func,
    resetUserData: PropTypes.func,
    sendChallengeAction: PropTypes.func,
    setNeedRefreshToken: PropTypes.func,
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    checking: state.user.checking,
    loadingLogin: state.auth.loadingLogin,
    loginError: state.auth.loginError,
    userInfo: state.user.userInfo,
    weekNumber: state.common.weekNumber,
    needRefreshToken: state.auth.needRefreshToken,
    gtmData: state.common.gtmData,
})

const mapDispatchToProps = (dispatch) => ({
    setToken: (token) => dispatch({type: REFRESH_TOKEN, payload: token}),
    logOut: () => dispatch({ type: LOGOUT_REQUESTED }),
    getOptions: () => dispatch({type: GET_OPTIONS}),
    setOptions: (options) => dispatch({type: SET_OPTIONS, payload: options}),
    getUserInfo: (uid) => dispatch({ type: GET_USER_INFO, payload: uid }),
    resetUserData: () => dispatch({ type: RESET_USER_DATA }),
    sendChallengeAction: (params) => dispatch({type: SEND_CHALLENGE_ACTION, payload: params}),
    getSprint: (weekNumber) => dispatch({ type: GET_SPRINT, payload: weekNumber }),
    setNeedRefreshToken: (state) => dispatch({ type: NEED_REFRESH_TOKEN, payload: state }),
    setGtmData: (gtmData) => dispatch({ type: SET_GTM_DATA, payload: gtmData }),
    canInjectGtmScript: (status) => dispatch({ type: CAN_INJECT_GTM_SCRIPT, payload: status }),

})

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider)
