import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

const Loading = ({
                     size="lg",
                     isFullScreen=false,
                     pageBackground
}) => {

    return (
        <>
            {
                isFullScreen ? (
                    <div
                        style={{ background: pageBackground.background }}
                        className={`flex flex-col justify-center items-center min-h-screen`}
                    >
                        <span className={`loading loading-spinner loading-${size}`} />
                    </div>
                ) : (
                    <span className={`loading loading-spinner loading-${size}`} />
                )
            }
        </>
    )
};

Loading.propTypes = {
    size: PropTypes.string,
    isFullScreen: PropTypes.bool,
    pageBackground: PropTypes.object
}

const mapStateToProps = (state) => ({
    pageBackground: state.common.pageBackground
})

const mapDispatchToProps = () => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(Loading)