import React from 'react'

import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {MdArrowBackIosNew} from "react-icons/md";

const BackButton = () => {

    const navigate = useNavigate()

    return (
        <MdArrowBackIosNew
            className={"h-7 w-7 cursor-pointer"}
            onClick={()=>navigate('/sprint')}
        />
    )
}

BackButton.propTypes = {
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(BackButton)