import React, {useEffect} from 'react'

import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";
import {
    CLEAN_HOMEWORK_DATA,
} from "../../../redux/actions/configurator-actions";
import {connect} from "react-redux";

import {withRouter} from '../../../helpers/with-router'

import FillResult from "./FillResult";
import FillTask from "./FillTask";
import Error from "../../Error";
import {RESET_PAGE_BACKGROUND} from "../../../redux/actions/common-actions";
import UAParser from "ua-parser-js";

const FillHomework = ({
                          getHomeworkError,
                          hasAnswers,
                          cleanHomeworkData,
                          resetPageBackground,
}) => {

    const parser = new UAParser()

    const browser = parser.getBrowser().name.toLowerCase()

    useEffect(()=>{

        return () => {
            cleanHomeworkData()
            resetPageBackground()
        };
    }, [])

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }, [hasAnswers])

    return (
        <React.Fragment>
            <>
                {getHomeworkError ? (
                    <Error error={getHomeworkError} />
                ) : (
                    <>
                        <div className={`relative flex flex-col items-center w-full min-h-screen ${hasAnswers && browser !== 'instagram' ? 'pt-8' : 'pt-5'}`}>
                            <div className={"w-full px-4"}>
                                { hasAnswers ? (
                                    <FillResult />
                                ) : (
                                    <FillTask />
                                )}
                            </div>

                        </div>
                    </>
                )}
            </>
        </React.Fragment>
    );
}

FillHomework.propTypes = {
    browser: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    userInfo: PropTypes.object,
    lock: PropTypes.object,
    hasAnswers: PropTypes.bool,
    loadingGetHomework: PropTypes.bool,
    getHomeworkError: PropTypes.object,
    cleanHomeworkData: PropTypes.func,
    resetPageBackground: PropTypes.func
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    userInfo: state.user.userInfo,
    lock: state.user.lock,
    hasAnswers: state.config.hasAnswers,
    getHomeworkError: state.config.getHomeworkError,
})

const mapDispatchToProps = (dispatch) => ({
    cleanHomeworkData: () => dispatch({ type: CLEAN_HOMEWORK_DATA }),
    resetPageBackground: () => dispatch({ type: RESET_PAGE_BACKGROUND })
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FillHomework))