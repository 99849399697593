import React from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";

const OneColumnTile = ({ children, className='' }) => {
    return (
        <div className={`w-full rounded-lg relative ${className}`}>
            {children}
        </div>
    )
}

OneColumnTile.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(OneColumnTile)


