export const SET_LOADING = "SET_LOADING"
export const UPDATE_STUDENT_PROFILE = "UPDATE_STUDENT_PROFILE"
export const UPDATE_STUDENT_PROFILE_REQUEST = "UPDATE_STUDENT_PROFILE_REQUEST"
export const UPDATE_STUDENT_PROFILE_SUCCESS = "UPDATE_STUDENT_PROFILE_SUCCESS"
export const UPDATE_STUDENT_PROFILE_ERROR = "UPDATE_STUDENT_PROFILE_ERROR"
export const GET_USER_INFO = "GET_USER_INFO"
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST"
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS"
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR"
export const SET_USER_INFO = "SET_USER_INFO"
export const NEED_UPDATE_USER_INFO = "NEED_UPDATE_USER_INFO"
export const CHECK_PERMISSIONS_REQUEST = "CHECK_PERMISSIONS_REQUEST"
export const CHECK_PERMISSIONS_SUCCESS = "CHECK_PERMISSIONS_SUCCESS"
export const CHECK_PERMISSIONS_ERROR = "CHECK_PERMISSIONS_ERROR"
export const UNLOCK_SPRINT = "UNLOCK_SPRINT"
export const LOCK_SPRINT = "LOCK_SPRINT"
export const SET_LOCK_SPRINT = "SET_LOCK_SPRINT"
export const GET_USER_STATS = "GET_USER_STATS"
export const GET_USER_STATS_REQUEST = "GET_USER_STATS_REQUEST"
export const GET_USER_STATS_SUCCESS = "GET_USER_STATS_SUCCESS"
export const GET_USER_STATS_ERROR = "GET_USER_STATS_ERROR"
export const RESET_USER_STATS = "RESET_USER_STATS"
export const RESET_USER_DATA = "RESET_USER_DATA"
