import React, {memo, useState} from "react";
import {connect} from "react-redux";

import Badge from "./Badge";
import coverImage from "../../../../assets/achievement/сover.svg"
import PropTypes from "prop-types";

// eslint-disable-next-line react/display-name
const Achievement = memo(({ achievement, isClose }) => {

    const [loadingBadge, setLoadingBadge] = useState(true)

    return (
        <React.Fragment>
            <div className={`w-[5rem] ms:w-[6rem] card bg-white shadow-brand-button h-[5rem] ms:h-full p-2`}>
                {loadingBadge && (
                    <img
                        src={coverImage}
                        className={"object-cover rounded-xl animate-pulse"}
                        alt={"cover"}
                    />
                )}
                {achievement && <Badge
                    badge={achievement.achievement.badge}
                    number={achievement.result}
                    isClose={isClose}
                    setLoading={setLoadingBadge}
                />}
            </div>
        </React.Fragment>
    );
});

Achievement.propTypes = {
    achievement: PropTypes.object,
    isClose: PropTypes.bool
}

const mapStateToProps = () => ({

})

const mapDispatchToProps = () => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Achievement)