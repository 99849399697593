import React, {useState} from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";

const FillResultHeader = ({ headerTitle, headerAvatar, studentResultString }) => {

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };

    return (
        <React.Fragment>
            <h2 className="my-4 text-2xl pt-2 font-bold card-title text-center">{headerTitle}</h2>
            <div className="avatar">
                <div
                    className="w-28 h-28 p-5 bg-white bg-opacity-50 rounded-full">
                    {!isImageLoaded && <div className={"w-full h-full bg-transparent rounded-full"} />}
                    <img
                        className={"bg-transparent"}
                        alt="result"
                        src={headerAvatar}
                        onLoad={handleImageLoad}
                    />
                </div>
            </div>
            <div className="rounded-full mt-5 bg-white shadow-brand">
                <div className="px-5 py-3">
                    <p className="font-semibold text-lg inline-block">Your result:</p>
                    <p className="font-semibold text-lg text-brandRed inline-block ml-1">{studentResultString}</p>
                </div>
            </div>
        </React.Fragment>
    );
}

FillResultHeader.propTypes = {
    headerTitle: PropTypes.string,
    headerAvatar: PropTypes.string,
    studentResultString: PropTypes.string,
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FillResultHeader)