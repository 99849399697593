import React from 'react'
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../../components/Spinner";
import {Link} from "react-router-dom";

const HasSubscriptionAlertModal = ({ setModalIsOpen, isOpen, handleRedeem, loadingRedeemGift, disableButton }) => {
    return (
        <dialog className={`modal ${isOpen ? 'modal-open' : null}`}>
            <div className="modal-box flex flex-col items-center space-y-4">
                <form method="dialog">
                    <button
                        disabled={disableButton}
                        className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                        onClick={() => disableButton ? null : setModalIsOpen(false)}
                    >
                        ✕
                    </button>
                </form>
                <div className={"flex flex-row items-start space-x-2"}>
                    <span>🚀</span>
                    <div className={"flex flex-col space-y-2"}>
                        <span className={"text-sm font-semibold"}>You have an active subscription.</span>
                        <span className={"text-sm"}>We recommend that you <Link className={"text-warning after:content-['_↗']"} to={'/billing'}>cancel your subscription</Link> before redeeming
                            the gift certificate. Otherwise your card will continue to be charged.</span>
                    </div>
                </div>
                <div className="modal-action space-x-3">
                    <button
                        disabled={disableButton}
                        className="btn"
                        onClick={() => disableButton ? null : setModalIsOpen(false)}
                    >
                        Cancel
                    </button>
                    <button
                        disabled={disableButton}
                        className="btn"
                        onClick={disableButton ? null : handleRedeem}
                    >
                        {loadingRedeemGift && <Spinner/>}
                        Redeem
                    </button>
                </div>
            </div>
        </dialog>
    )
}

HasSubscriptionAlertModal.propTypes = {
    setModalIsOpen: PropTypes.func,
    handleRedeem: PropTypes.func,
    isOpen: PropTypes.bool,
    loadingRedeemGift: PropTypes.bool,
    disableButton: PropTypes.bool
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(HasSubscriptionAlertModal)