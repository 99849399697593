import React, {useEffect} from "react";
import { connect } from "react-redux";

import {useAuth} from "../../context/AuthContext";

import Loading from "../components/Loading";

const Logout = () => {

    const {
        doLogout,
    } = useAuth();

    useEffect( () => {
        doLogout();
    }, [])

    return <Loading isFullScreen={true}/>
}

Logout.propTypes = {
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
