import React, {useEffect} from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from '../../../helpers/with-router'

import Error from "../../Error";
import QuizletTask from "./QuizletTask";

import {
    CLEAN_HOMEWORK_DATA,
    GET_HOMEWORK,
} from "../../../redux/actions/configurator-actions";
import {
    RESET_PAGE_BACKGROUND,
    SET_PAGE_BACKGROUND_REQUESTED
} from "../../../redux/actions/common-actions";

const QuizletHomework = ({
                             getHomeworkError,
                             cleanHomeworkData,
                             resetPageBackground,
                             setPageBackground,
                             background,
}) => {

    useEffect(()=>{

        return () => {
            cleanHomeworkData()
            resetPageBackground()
        };
    }, [])

    useEffect(()=>{
        setPageBackground({
            background: background.source
        })
    }, [background, setPageBackground])

    return (
        <React.Fragment>
            <>
                {getHomeworkError ? (
                    <Error error={getHomeworkError} />
                ) : (
                    <>
                        <div className="relative flex flex-col items-center w-full h-screen pt-8">
                            <div className="w-full overflow-y-scroll flex flex-col h-screen">
                                <QuizletTask />
                            </div>
                        </div>
                    </>
                )}
            </>
        </React.Fragment>
    );
}

QuizletHomework.propTypes = {
    browser: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    userInfo: PropTypes.object,
    lock: PropTypes.object,
    background: PropTypes.object,
    hasAnswers: PropTypes.bool,
    loadingGetHomework: PropTypes.bool,
    getHomeworkError: PropTypes.object,
    getHomework: PropTypes.func,
    cleanHomeworkData: PropTypes.func,
    setPageBackground: PropTypes.func,
    resetPageBackground: PropTypes.func
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    userInfo: state.user.userInfo,
    lock: state.user.lock,
    background: state.config.background,
    hasAnswers: state.config.hasAnswers,
    getHomeworkError: state.config.getHomeworkError,
})

const mapDispatchToProps = (dispatch) => ({
    getHomework: (params) => dispatch({ type: GET_HOMEWORK, payload: params }),
    cleanHomeworkData: () => dispatch({ type: CLEAN_HOMEWORK_DATA }),
    setPageBackground: (background) => dispatch({ type: SET_PAGE_BACKGROUND_REQUESTED, payload: background }),
    resetPageBackground: () => dispatch({ type: RESET_PAGE_BACKGROUND })
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuizletHomework))