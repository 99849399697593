import {
    put,
    call,
    takeLatest,
} from 'redux-saga/effects'

import {
    GET_STORIES_BY_COHORTS,
    GET_STORIES_BY_COHORTS_ERROR,
    GET_STORIES_BY_COHORTS_REQUEST,
    GET_STORIES_BY_COHORTS_SUCCESS
} from "../actions/stories-actions";

import storiesApi from "../api/stories-api";

function* getStoriesByCohorts({ payload }) {

    yield put({ type: GET_STORIES_BY_COHORTS_REQUEST })

    const response = yield call(storiesApi.getStoriesByCohorts, payload)

    if(response && response.status === 200) {
        yield put({type: GET_STORIES_BY_COHORTS_SUCCESS, payload: response.data })
    } else if(response.status === 404) {
        yield put({type: GET_STORIES_BY_COHORTS_SUCCESS, payload: [] })
    } else {
        yield put({ type: GET_STORIES_BY_COHORTS_ERROR, payload: response.status })
    }
}

export default function* preferenceSaga() {
    yield takeLatest(GET_STORIES_BY_COHORTS, getStoriesByCohorts)
}
