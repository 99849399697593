import React from 'react'

import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
// import {FaHome} from "react-icons/fa";
import {LuLayoutDashboard} from "react-icons/lu";

const HomeButton = () => {

    const navigate = useNavigate()

    return (
        <LuLayoutDashboard
            className={"h-8 w-8 cursor-pointer"}
            onClick={()=>navigate('/')}
        />
    )
}

HomeButton.propTypes = {
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(HomeButton)