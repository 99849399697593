import React, {useEffect, useRef} from 'react'
import {connect} from "react-redux";
import { FaInstagram } from "react-icons/fa";
import {SET_FOOTER_HEIGHT} from "../../../redux/actions/common-actions";
import PropTypes from "prop-types";
import UAParser from "ua-parser-js";

const Footer = ({ setFooterElementHeight }) => {

    const footerElementRef = useRef(null)

    const parser = new UAParser()
    const browser = parser.getBrowser().name.toLowerCase()
    const isNotInstagram = browser !== 'instagram'

    useEffect(() => {

        if(footerElementRef.current != null) {
            const calculateHeight = () => {
                const footerHeight = footerElementRef.current.getBoundingClientRect().height;
                setFooterElementHeight(footerHeight)
            };

            calculateHeight();
        }

        return () => {
        };
    }, [footerElementRef]);

    useEffect(()=>{
        if(footerElementRef.current){
            setFooterElementHeight(footerElementRef.current.clientHeight)
        }
    }, [footerElementRef])


    return isNotInstagram ? (
        <div
            ref={footerElementRef}
            className="z-50 fixed inset-x-0 bottom-0 flex flex-row justify-center items-center w-full"
        >
            <div className={"h-16 flex flex-row justify-center items-center space-x-2 text-white rounded-t-3xl border-2 border-primary bg-primary w-full xs:max-w-laptop"}>
                <FaInstagram />
                <a href={"https://instagram.com/english_in_stories"} target={"_blank"} rel="noreferrer">english_in_stories</a>
            </div>
        </div>
    ) : null
}

Footer.propTypes = {
    setFooterElementHeight: PropTypes.func
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = (dispatch) => ({
    setFooterElementHeight: (footerHeight) => dispatch({ type: SET_FOOTER_HEIGHT, payload: footerHeight })
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)


