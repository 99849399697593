import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Loading from "../../components/Loading";
import {useAuth} from "../../../context/AuthContext";

import Collection from "./Collection";
import ShareAchievementModal from "./ShareAchievementModal";
import DetailAchievementsCollection from "./DetailAchievementsCollectionModal";
import UserAchievementItem from "./UserAchievementItem";

import {GET_ACHIEVEMENTS, RESET_ACHIEVEMENTS_DATA} from "../../../redux/actions/achievement-actions";

const Achievements = ({ userAchievements, loadingAchievements, getAchievements, resetAchievementsData }) => {

    const { currentUser } = useAuth()
    const [elements, setElements] = useState([])

    useEffect(()=>{

        if(userAchievements !== null) {

            const elementsList = []
            userAchievements.forEach(achievement=>{
                if(achievement.isStack){
                    elementsList.push(achievement)
                } else {
                    achievement.userAchievement.forEach(userAchievement=>{
                        userAchievement.isStack = false
                        userAchievement.achievement = achievement
                        elementsList.push(userAchievement)
                    })
                }
            })

            setElements(elementsList)
        }

    }, [userAchievements])

    useEffect(()=>{
        getAchievements(currentUser.uid)

        return () => {
            resetAchievementsData()
        }
    },[])

    return (
        <React.Fragment>
            {loadingAchievements ? <Loading isFullScreen={true}/> : (
                <div className={"flex flex-row flex-wrap gap-4 pt-8 pb-4"}>
                    {elements &&
                        elements.map((userAchievement, index) => {

                            return (
                                userAchievement.isStack ? (
                                    <Collection
                                        key={`collection-${userAchievement.id}`}
                                        achievement={userAchievement}
                                        isClose={userAchievement.isStack}
                                    />
                                ) : (
                                    <UserAchievementItem
                                        key={`user-achievement-item-${userAchievement.achievement.id}-${index}`}
                                        achievement={userAchievement.achievement}
                                        element={userAchievement}
                                        innerIndex={index}
                                    />
                                )
                            )
                        })}
                </div>
            )}

            <DetailAchievementsCollection />
            <ShareAchievementModal />
        </React.Fragment>
    );
};

Achievements.propTypes = {
    userAchievements: PropTypes.any,
    loadingAchievements: PropTypes.bool,
    getAchievements: PropTypes.func,
    resetAchievementsData: PropTypes.func
}

const mapStateToProps = (state) => ({
    userAchievements: state.achievement.userAchievements,
    loadingAchievements: state.achievement.loadingAchievements,
})

const mapDispatchToProps = (dispatch) => ({
    getAchievements: (userId) => dispatch({ type: GET_ACHIEVEMENTS, payload: userId }),
    resetAchievementsData: () => dispatch({ type: RESET_ACHIEVEMENTS_DATA })
})

export default connect(mapStateToProps, mapDispatchToProps)(Achievements)