import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import DetailAchievementTile from "./achievement/DetailAchievementTile";
import {BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN, SET_ACHIEVEMENT_DETAIL} from "../../../redux/actions/achievement-actions";
import {SET_PLAY_TAB} from "../../../redux/actions/common-actions";

const DetailAchievementsCollectionModal = ({
                                               achievementDetail,
                                               setAchievementDetail,
                                               modalIsOpen,
                                               bulkUpdateUserAchievementsWasShown,
                                               setPlayTab,
                                               notStartedChallenges
}) => {

    const [collectionTitle, setCollectionTitle] = useState('')
    const [collection, setCollection] = useState([])
    const [challengeTitle, setChallengeTitle] = useState('')
    const [showJoinChallengeLink, setShowJoinChallengeLink] = useState(false)

    const closeDialog = () => {

        if(achievementDetail.collection[0].status !== 'close') {
            const wasShownUpdatesList = [...collection].filter((achievement) => !achievement.wasShown)
                .map((achievement) => {
                    return {
                        id: achievement.id,
                        wasShown: true,
                    }
                });

            if(wasShownUpdatesList.length > 0)  {
                bulkUpdateUserAchievementsWasShown({
                    updateList: wasShownUpdatesList,
                    achievementId: achievementDetail.isCollection ? achievementDetail.achievement.id : achievementDetail.collection[0].id
                })
            }
        }

        setCollectionTitle('')
        setCollection([])
        setChallengeTitle('')
        setAchievementDetail({
            modalIsOpen: false,
            detail: null
        })
        setShowJoinChallengeLink(false)
    }

    useEffect(()=>{

        if(achievementDetail !== null){
            setCollectionTitle(achievementDetail.achievement.displayTitle)
            setCollection([...achievementDetail.collection])

            if(achievementDetail.achievement.type === 'challenge') {
                setChallengeTitle(`${achievementDetail.achievement.target.title} challenge`)
            } else if (achievementDetail.achievement.type === 'trigger') {
                setChallengeTitle(`${achievementDetail.achievement.target.title}`)
            }

            if(achievementDetail.collection[0].status === 'close' && achievementDetail.achievement.type === 'challenge') {

                const isNotStartedChallenge = notStartedChallenges.find((challenge) => {
                    return challenge.id === achievementDetail.achievement.targetObjectId;
                }) !== undefined

                if(isNotStartedChallenge) {
                    setShowJoinChallengeLink(true)
                }
            }

        }
    }, [achievementDetail])

    useEffect(()=>{
        return () => {
            setAchievementDetail({
                modalIsOpen: false,
                detail: null
            })
        }
    }, [])

    return (
        <React.Fragment>
            {modalIsOpen ? (
                <dialog
                    className={`bg-white flex flex-row justify-center modal modal-bottom ${modalIsOpen ? 'modal-open' : null}`}
                >
                    <form method={"dialog"}
                          className={`bg-white pt-0 px-5 pb-8 modal-box relative max-h-[65vh] w-screen xs:max-w-laptop`}
                    >
                        <header className={"sticky top-0 pb-6 pt-5 flex flex-col bg-white z-50"}>
                            {collectionTitle && <span className={"text-lg text-primary font-semibold"}>{collectionTitle} Collection</span>}
                            {challengeTitle && <span className={"text-sm text-primary opacity-50"}>{challengeTitle}</span>}
                            <button
                                className="btn btn-xs btn-circle btn-primary absolute right-0 top-5"
                                onClick={closeDialog}
                            >
                                ✕
                            </button>
                        </header>
                        <div className={"flex flex-col w-full space-y-4 z-10"}>
                            {achievementDetail.collection[0].status === 'close' ? (
                                <div className={"text-sm text-primary"}>
                                    {achievementDetail.achievement.description + ' '}
                                    {showJoinChallengeLink && (
                                        <span
                                            onClick={()=>setPlayTab('not_owned')}
                                            className={"text-warning font-semibold inline cursor-pointer after:content-['_↗']"}
                                        >
                                        Join the challenge
                                    </span>
                                    )}
                                </div>
                            ) : (
                                <ul className="w-full space-y-4 overflow-y-scroll">
                                    {collection && collection.map((userAchievement) => {
                                        return (
                                            <li
                                                key={userAchievement.id}
                                                className={"w-full flex flex-row justify-between items-center"}
                                            >
                                                <DetailAchievementTile
                                                    achievement={userAchievement}
                                                />
                                            </li>
                                        )
                                    })}
                                </ul>
                            )}

                        </div>
                    </form>
                </dialog>
            ) : null}
        </React.Fragment>
    )
}


DetailAchievementsCollectionModal.propTypes = {
    notStartedChallenges: PropTypes.array,
    achievementDetail: PropTypes.object,
    modalIsOpen: PropTypes.bool,
    userChallenges: PropTypes.array,
    setAchievementDetail: PropTypes.func,
    bulkUpdateUserAchievementsWasShown: PropTypes.func,
    setPlayTab: PropTypes.func
}

const mapStateToProps = (state) => ({
    modalIsOpen: state.achievement.achievementDetailModalIsOpen,
    achievementDetail: state.achievement.achievementDetail,
    notStartedChallenges: state.challenge.notOwnedChallenges
})

const mapDispatchToProps = (dispatch) => ({
    setAchievementDetail: ({modalIsOpen, detail}) => dispatch({ type: SET_ACHIEVEMENT_DETAIL, payload: {modalIsOpen, detail} }),
    bulkUpdateUserAchievementsWasShown: ({updateList, achievementId}) => dispatch({
        type: BULK_UPDATE_USER_ACHIEVEMENTS_WAS_SHOWN,
        payload: {updateList, achievementId}
    }),
    setPlayTab: (tab) => dispatch({ type: SET_PLAY_TAB, payload: tab } )
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailAchievementsCollectionModal)