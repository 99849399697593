import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const ChallengePreconditions = ({
                                    targetAction,
                                    challengeActionsText
}) => {

    return (
        <React.Fragment>
            <p className={"pt-1"}>{challengeActionsText} {targetAction.description}</p>
        </React.Fragment>
    )
}

ChallengePreconditions.propTypes = {
    targetAction: PropTypes.object,
    challengeActionsText: PropTypes.string
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengePreconditions)