import React, {useState} from 'react'

import 'react-edit-text/dist/index.css';
import PropTypes from "prop-types";

import {connect} from "react-redux";
import Spinner from "../components/Spinner";


const Image = ({ image }) => {

    const [imageLoading, setImageLoading] = useState(true)

    const handleImageLoad = () => {
        setImageLoading(false);
        // Дополнительный код, который нужно выполнить после загрузки iframe
    };

    return (
        <React.Fragment>
            {imageLoading ? (
                <div className={"flex flex-row w-full h-full justify-center items-center"}>
                    <Spinner />
                </div>

            ) : null}
            <div className="w-full aspect-video self-center">
                <img
                    alt="task"
                    src={image}
                    onLoad={handleImageLoad}
                />
            </div>
        </React.Fragment>
    );
}

Image.propTypes = {
    loading: PropTypes.bool,
    image: PropTypes.string
}

const mapStateToProps = (state) => ({
    loading: state.config.loading,
    image: state.config.image
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Image)
