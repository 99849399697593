import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Header = ({ children }) => {
    return <h3 className={"text-xl font-bold text-primary"}>{children}</h3>
}

Header.propTypes = {
    children: PropTypes.any
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)