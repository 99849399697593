import React, { useEffect, useRef } from "react";
import * as PropTypes from "prop-types";
import Handlebars from "handlebars";

function ToastMessage({ text, withSupportButton, withTryAgain, code }) {
    const contentRef = useRef();

    useEffect(() => {
        if (contentRef.current) {
            let content;

            const supportText = 'get in touch with <a class="underline underline-offset-2" href="https://instagram.com/eis_support">technical support</a>';
            const errorCodeText = code !== undefined ? `Error code ${code}` : '';
            const tryAgainText = 'try again';

            if (withSupportButton && withTryAgain) {
                content = `${text} Please, ${supportText} or ${tryAgainText}. ${errorCodeText}`;
            } else if (withSupportButton && !withTryAgain) {
                content = `${text} Please, ${supportText}. ${errorCodeText}`;
            } else if (withTryAgain && !withSupportButton) {
                content = `${text} Please, ${tryAgainText}. ${errorCodeText}`;
            } else {
                content = `${text} ${errorCodeText}`;
            }

            const template = Handlebars.compile(content);
            const templateVariables = {
                text,
                supportText,
                tryAgainText,
                errorCodeText,
            };

            contentRef.current.innerHTML = template(templateVariables);
        }
    }, [contentRef, text, withSupportButton, withTryAgain, code]);

    return (
        <div className="flex flex-col justify-start items-start">
            <div ref={contentRef} className="leading-tight text-sm"></div>
        </div>
    );
}

ToastMessage.propTypes = {
    withTryAgain: PropTypes.bool,
    text: PropTypes.string,
    withSupportButton: PropTypes.bool,
    code: PropTypes.number,
};

export default ToastMessage;