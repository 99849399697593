const zero = 'M7.74841 39.9347C7.75122 108.19 52.2352 82.2428 52.2352 41.5778C52.2352 3.18564 7.7464 -9.09357 7.74841 39.9347Z'
const one = 'M11.2026 36.6392L34.2063 8.67035V81.4251'
const two = 'M51.7595 78.3085L9.38745 81.3774L36.388 44.5443C62.0549 12.1835 30.669 -11.2076 10.2797 32.913'
const three = 'M10.0215 22.1439C42.1084 -8.09381 52.2474 21.5376 32.8179 43.3831C63.2522 36.7216 56.1021 94.3315 15.4586 79.798'
const four = 'M14.3466 8.88911C-0.588491 66.4711 51.0725 45.7904 51.0725 45.7904M51.0725 45.7904L50.9679 81.5338M51.0725 45.7904L50.6546 8.79289'
const five = 'M10.5966 78.4337C53.7759 96.1091 60.5372 28.058 10.5966 37.8022V8.56964H52.1156'
const six = 'M11.2824 60.3389C17.6699 98.1192 52.7449 77.6089 51.4536 56.3239C50.3137 37.5329 7.95318 40.6475 11.2824 60.3389ZM11.2824 60.3389C5.5382 26.3633 23.2355 -8.51394 51.4536 17.8107'
const seven = 'M17.7508 80.9241L32.4495 44.5193M9.38744 8.11438L47.1482 8.11438L32.4495 44.5193M32.4495 44.5193L14.2923 38.5512M32.4495 44.5193L51.7595 51.084'
const eight = 'M29.1648 41.6449C32.2235 38.3362 39.1876 33.3837 42.1749 29.0136C62.1394 -0.19186 -13.8299 2.50161 17.2487 33.2073C21.1067 37.019 25.8633 38.5096 29.1648 41.6449ZM29.1648 41.6449C-40.7643 95.6961 106.34 93.5413 29.1648 41.6449Z'
const nine = 'M51.3583 35.0769C48.9422 -5.84019 5.68404 6.86105 9.45966 31.3231C12.5461 51.3197 52.6176 56.4032 51.3583 35.0769ZM51.3583 35.0769C53.531 71.8733 37.2596 88.8558 9.80935 78.2769'

const numbers = {
    "0": zero,
    "1": one,
    "2": two,
    "3": three,
    "4": four,
    "5": five,
    "6": six,
    "7": seven,
    "8": eight,
    "9": nine
}

export default numbers;