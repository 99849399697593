import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

// import Pluralize from "react-pluralize";

import Emoji from "../../components/Emoji";
import ActionTile from "../NotOwnedChallengeDetailsModal/ActionTile";
import Preconditions from "../NotOwnedChallengeDetailsModal/ChallengePreconditions";
import pluralize from "pluralize";



const ChallengeDetails = ({
                   challenge,
               }) => {

    const [showPrecondition, setPreconditionState] = useState(false)
    const [challengeActionsText, setChallengeActionsText] = useState('')


    useEffect(()=>{
        if(challenge){
            if(challenge.targetAction) {
                setPreconditionState(true)
            }
        }

        if(challenge.participantType){
            // eslint-disable-next-line default-case
            switch (challenge.participantType){
                case 'owner_only':
                    setChallengeActionsText('You have to')
                    break;
                case 'participants_only':
                    setChallengeActionsText('Each of your friends has to')
                    break;
                case 'participants_and_owner':
                    setChallengeActionsText('Your friends and you have to')
                    break;
            }
        }

    }, [challenge])

    return (
        <React.Fragment>
            <div className="flex flex-col">

                <main className="grow overflow-y-scroll overscroll-contain pt-2 pb-10">
                    {showPrecondition && (
                        <div className={"pt-5"}>
                            <p className={"font-semibold"}>What needs to be done?</p>
                            <Preconditions targetAction={challenge.targetAction} challengeActionsText={challengeActionsText} />
                        </div>
                    )}
                    <div className={"pt-5 flex flex-col"}>
                        <p className={"font-semibold"}>Challenge</p>
                        <span>{challengeActionsText}:</span>
                        <div className={"flex flex-col space-y-4"}>
                            <div className={"divide-y"}>
                                {challenge.actions.map((action)=> {
                                    return (
                                        <div key={action.id} className={"pl-0 pt-4"}>
                                            <ActionTile action={action} participantType={challenge.participantType} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <p className={"font-semibold pt-3"}>Reward</p>
                        <div className={"flex flex-row flex-wrap"}>
                            You will get&nbsp;
                            <span className={"font-bold"}>
                                        <span>{challenge.reward.rewards}&nbsp;</span>
                                {challenge.reward.type === 'week' ? (
                                    <>
                                        free&nbsp;
                                        <span className={"inline"}>
                                            {`${pluralize(challenge.reward.type, challenge.reward.rewards)}`}
                                        </span>&nbsp;
                                        {/*<Pluralize*/}
                                        {/*    className={"inline"}*/}
                                        {/*    singular={challenge.reward.type}*/}
                                        {/*    count={challenge.reward.rewards}*/}
                                        {/*    showCount={false}*/}
                                        {/*/>&nbsp;*/}

                                    </>
                                ) : (
                                    <span>
                                                {challenge.reward.type}&nbsp;
                                            </span>
                                )}
                                    </span>
                            <span>for {challenge.isRepeatable ? 'every': ''} {challenge.score}</span><Emoji symbol={'🏆'} />&nbsp;
                        </div>
                    </div>
                </main>
            </div>
        </React.Fragment>
    );
};

ChallengeDetails.propTypes = {
    challenge: PropTypes.object,
    closeDialog: PropTypes.func,
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDetails)