import React, {useEffect, useState} from 'react'

import {connect} from "react-redux";
import PropTypes from "prop-types";
// import Pluralize from "react-pluralize";

import { IconContext } from "react-icons/lib";
import {FaBatteryEmpty, FaBatteryFull, FaBatteryHalf, FaBatteryQuarter} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import pluralize from "pluralize";
import Skeleton from "./Skeleton";


const BalanceCard = ({ userInfo }) => {

    const [backgroundClass, setBackgroundClass] = useState('bg-primary')
    const [batteryVariant, setBatteryVariant] = useState(null)
    const [canPaid, setCanPaind] = useState(false)

    const [calculationsComplete, setCalculationsComplete] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        if(userInfo) {
            setCanPaind(userInfo.isAccountSynced)

            switch (true) {
                case userInfo.balance.weeks === 0:
                    setBatteryVariant(<FaBatteryEmpty />)
                    setBackgroundClass('bg-pink')
                    break
                case  userInfo.balance.weeks === 1 || userInfo.balance.weeks === 2:
                    setBatteryVariant(<FaBatteryQuarter />)
                    setBackgroundClass('bg-orange')
                    break
                case  userInfo.balance.weeks === 3 || userInfo.balance.weeks === 4:
                    setBatteryVariant(<FaBatteryHalf />)
                    setBackgroundClass('bg-yellow')
                    break
                case  userInfo.balance.weeks >= 5:
                    setBatteryVariant(<FaBatteryFull />)
                    setBackgroundClass('bg-green')
                    break
                default:
                    setBatteryVariant(<FaBatteryEmpty />)
                    setBackgroundClass('bg-pink')
                    break
            }

            setCalculationsComplete(true);
        }
    }, [userInfo]);

    useEffect(() => {
        return ()=>{
            setCanPaind(false)
        }
    }, []);

    return (
        <React.Fragment>
            {calculationsComplete ? (
                <div
                    onClick={() => canPaid ? navigate('/billing') : null}
                    className={`shadow card z-40 min-h-28 ${backgroundClass} ${canPaid ? 'cursor-pointer' : 'opacity-30'}`}
                >
                    <div className={"flex flex-col justify-between space-y-2 min-h-28"}>

                        {/* HEADER */}
                        <div className={"flex flex-row justify-between items-center px-3 pt-3 pb-1"}>
                    <span className="text-base font-normal">
                        Your balance
                    </span>
                            <IconContext.Provider value={{className: "fill-dark w-6 h-6"}}>
                                {batteryVariant}
                            </IconContext.Provider>
                        </div>

                        {/* BODY */}
                        <div className={"flex flex-row justify-between items-end pl-3 w-full space-x-2"}>
                            <div className={"flex-1 h-full pb-3"}>
                                <p className={"text-3xl ms:text-4xl text-white font-bold line-clamp-2 ms:line-clamp-1"}>
                                    {`${userInfo?.balance?.weeks} ${pluralize('week', userInfo?.balance?.weeks)}`}
                                </p>
                            </div>

                            <div
                                onClick={() => canPaid ? navigate('/billing') : null}
                                className={"p-0 bg-dark items-center justify-center flex flex-row rounded-none " +
                                    `rounded-br-[2.5rem] rounded-tl-[100px] w-28 ${canPaid ? 'cursor-pointer' : ''} `}
                            >
                        <span
                            className={"p-2.5 pr-0.5 ms:pr-2.5 text-white"}
                        >
                            {`TOP UP`}
                        </span>
                            </div>

                        </div>


                    </div>
                </div>
            ) : (
                <Skeleton />
            )}
        </React.Fragment>

    )
}

BalanceCard.propTypes = {
    userInfo: PropTypes.object,
}

const mapStateToProps = (state) => ({
    userInfo: state.user.userInfo,
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(BalanceCard)