import Handlebars from "handlebars";

Handlebars.registerHelper("math", function(lvalue, operator, rvalue) {
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);

    switch (operator) {
        case "+": return lvalue + rvalue;
        case "-": return lvalue - rvalue;
        case "*": return lvalue * rvalue;
        case "/": return lvalue / rvalue;
        case "%": return lvalue % rvalue;
        default: return ""; // Handle an invalid operator
    }
});

export default Handlebars;