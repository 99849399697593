import {accountApiUrl} from "../../helpers/constants";
import camelcaseKeys from "camelcase-keys";
import client from "./client";
import snakecaseKeys from "snakecase-keys";

const accountClient = client(`${accountApiUrl}/api/v1/notification`)

const createFMCRegistration = async (payload) => {
    try {
        const data = snakecaseKeys(payload)
        const response = await accountClient.post(`/registration/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const deleteFMCRegistration = async (payload) => {
    try {
        const response = await accountClient.delete(`/registration/${payload}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const changeNotificationSubscription = async (payload) => {

    try {
        const data = snakecaseKeys(payload)
        const response = await accountClient.post(`/subscription/status/`, data)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const getUserNotificationSettings = async (payload) => {
    try {
        const response = await accountClient.get(`/subscription/${payload.userId}/${payload.deviceId}/`)
        return camelcaseKeys(response, {deep: true})
    } catch({response}) {
        return camelcaseKeys(response, {deep: true})
    }
}

const notificationApi = {
    createFMCRegistration: createFMCRegistration,
    deleteFMCRegistration: deleteFMCRegistration,
    changeNotificationSubscription: changeNotificationSubscription,
    getUserNotificationSettings: getUserNotificationSettings
}

export default notificationApi
