import {
    LOCK_SPRINT,
    UNLOCK_SPRINT,
    CHECK_PERMISSIONS_REQUEST,
    CHECK_PERMISSIONS_SUCCESS,
    CHECK_PERMISSIONS_ERROR,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_ERROR,
    SET_USER_INFO,
    UPDATE_STUDENT_PROFILE_REQUEST,
    UPDATE_STUDENT_PROFILE_SUCCESS,
    UPDATE_STUDENT_PROFILE_ERROR,
    RESET_USER_DATA,
    SET_LOCK_SPRINT,
    GET_USER_STATS_REQUEST,
    GET_USER_STATS_SUCCESS,
    GET_USER_STATS_ERROR,
    RESET_USER_STATS,
    NEED_UPDATE_USER_INFO,
    GET_USER_INFO_REQUEST
} from "../actions/user-actions";
import { toast } from "react-toastify";
import React from "react";

import ToastMessage from "../../screens/components/toast";

const initialState = {
    lock: {status: true, description: 'init'},
    userStats: null,
    checking: false,
    checked: false,
    userInfo: null,
    loadingUserInfo: false,
    userInfoError: null,
    updateProfileError: null,
    updateProfileLoading: false,
    userStatsLoading: false,
    needUpdateUserInfo: false
};

export default function userReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case CHECK_PERMISSIONS_REQUEST:
            return {
                ...state,
                checking: true
            }

        case CHECK_PERMISSIONS_SUCCESS:
            return {
                ...state,
                checking: false,
            }

        case CHECK_PERMISSIONS_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get your permissions."} withSupportButton={true} withTryAgain={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
            }

        case UNLOCK_SPRINT:
            return {
                ...state,
                lock: false
            }

        case LOCK_SPRINT:
            return {
                ...state,
                lock: true
            }

        case SET_LOCK_SPRINT:
            return {
                ...state,
                lock: payload,
                checked: true
            }

        case GET_USER_INFO_REQUEST:
            return {
                ...state,
                loadingUserInfo: true,
            }

        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: payload,
                loadingUserInfo: false,
                needUpdateUserInfo: false
            }

        case GET_USER_INFO_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get your profile info."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                userInfo: null,
                userInfoError: true,
                loadingUserInfo: false,
                needUpdateUserInfo: false
            }

        case SET_USER_INFO:
            return {
                ...state,
                userInfo: payload
            }

        case NEED_UPDATE_USER_INFO:
            return {
                ...state,
                needUpdateUserInfo: payload
            }

        /* SET USER PROFILE */
        case UPDATE_STUDENT_PROFILE_REQUEST:
            return {
                ...state,
                updateProfileLoading: true,
            };

        case UPDATE_STUDENT_PROFILE_SUCCESS:
            return {
                ...state,
                userInfo: { ...state.userInfo, profile: payload},
                updateProfileLoading: false,
                updateProfileError: null,
                needUpdateUserInfo: true
            };

        case UPDATE_STUDENT_PROFILE_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Something went wrong."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                updateProfileLoading: false,
                updateProfileError: true,
                needUpdateUserInfo: true
            };

        case RESET_USER_DATA:
            return {
                ...state,
                lock: initialState.lock,
                checking: false,
                checked: false,
                userInfo: null,
                userInfoError: null,
                updateProfileError: null,
                updateProfileLoading: false
            }

        case GET_USER_STATS_REQUEST:
            return {
                ...state,
                userStatsLoading: true
            }

        case GET_USER_STATS_SUCCESS:
            return {
                ...state,
                userStatsLoading: false,
                userStats: payload
            }

        case GET_USER_STATS_ERROR:
            payload !== 403 && toast.error(<ToastMessage text={"Couldn't get the stats."} withTryAgain={true} withSupportButton={true} code={payload} />, {
                autoClose: false
            })
            return {
                ...state,
                userStatsLoading: false,
            }

        case RESET_USER_STATS:
            return {
                ...state,
                userStats: initialState.userStats,
                userStatsLoading: initialState.userStatsLoading
            }

        default:
            return state;
    }
}
